import { functions, httpsCallable } from '@/firebase';
/**
 * Purchase Package From Web App.
 */
 function buyPackageFromWebApp(studioId, packageName, subscriptionId, paymentMethodId) {
  const inputs = { studioId, packageName, paymentMethodId };
  if (subscriptionId) {
      inputs.subscriptionId = subscriptionId;
  }
  const purchasePackageFromWebAppFn = httpsCallable(functions, 'buyPackageFromWebApp');
  return purchasePackageFromWebAppFn(inputs).then((result) => result.data).catch((error) => ({ success: false, error }));
}

async function createCheckoutSessionForPackagePurchase(studioId, packageName, subscriptionId, promoCode, baseUrl, classId, spotNumber) {
    const inputs = { studioId, packageName, subscriptionId, promoCode, source: 'website', baseUrl, classId, spotNumber };
    const createCheckoutSessionForPackagePurchaseFn = httpsCallable(functions, 'createCheckoutSessionForPackagePurchase');
    return createCheckoutSessionForPackagePurchaseFn(inputs).then((result) => result.data).catch((error) => ({ success: false, error }));
}

async function retrieveCheckoutSessionStatus(sessionId, stripeAccountId) {
    const inputs = { sessionId, stripeAccountId };
    const retrieveCheckoutSessionStatusFn = httpsCallable(functions, 'retrieveCheckoutSessionStatus');
    return retrieveCheckoutSessionStatusFn(inputs).then((result) => result.data).catch((error) => ({ success: false, error }));
}

async function createCheckoutSessionForCoursePurchase(courseOfferingId, promoCode, baseUrl) {
  const inputs = { courseOfferingId, baseUrl, promoCode, source: 'website' };
  const createCheckoutSessionForCoursePurchaseFn = httpsCallable(functions, 'createCheckoutSessionForCoursePurchase');
  return createCheckoutSessionForCoursePurchaseFn(inputs).then((result) => result.data).catch(error => ({ success: false, error }));
}

async function createCheckoutSessionForProductsPurchase(studioId, baseUrl) {
  const inputs = { studioId, baseUrl, source: 'website' };
  const createCheckoutSessionForProductsPurchaseFn = httpsCallable(functions, 'createCheckoutSessionForProductsPurchase');
  return createCheckoutSessionForProductsPurchaseFn(inputs).then((result) => result.data).catch(error => ({ success: false, error }));
}

export { 
  buyPackageFromWebApp, 
  createCheckoutSessionForCoursePurchase, 
  createCheckoutSessionForPackagePurchase, 
  retrieveCheckoutSessionStatus,
  createCheckoutSessionForProductsPurchase,
};
