<template>
  <v-card flat :style="{ background: $vuetify.theme.themes.light.background }">
    <v-card
      flat
      :style="{ background: $vuetify.theme.themes.light.background }"
    >
      <v-flex v-for="(item, index) in courses" :key="index">
        <div class="pl-3 mt-5 text--secondary d-flex date-text">
          {{ index }}
        </div>
        <br />
        <v-flex v-for="(subItem, index) in item" :key="index">
          <CourseCard 
            :subItem="subItem" 
            :isClickable="isClickable" 
            :bookBtn="bookBtn" 
            :isMobileView="isMobileView"
          />
        </v-flex>
      </v-flex>
     
    </v-card>
  </v-card>
</template>

<script>
import CourseCard from "./CourseCard.vue"
export default {
  props: {
    studio: Object, //take from map getter
    courses: Object,
    bookBtn: {
      type: Boolean,
      default: true,
    },
    isClickable: {
      type: Boolean,
      default: false,
    },
    isMobileView: {
      type: Boolean,
      default: false,
    }
  },
  components: {
    CourseCard
  }
};
</script>

<style scoped>
.date-text {
  color: rgba(0, 0, 0, 0.5);
  font-weight: bold;
  font-family: Roboto;
  font-size: 20px;
}
</style>
