<template>
  <div>
    <v-sheet elevation="2" class="px-1">
      <v-col>
        <v-row align="center" no-gutters>
          <v-img src="@/assets/logo_transparent.png" max-width="150px" @click="goToHome" class="hover"></v-img>
          <v-spacer />
          <div class="userAuth">
            <UserAuth :hideRedirect="true" />
          </div>
        </v-row>
      </v-col>
    </v-sheet>

    <v-row class="d-none d-md-flex my-5">
      <v-col cols="3">
        <v-card class="py-10" height="80vh">
          <v-tabs vertical>
            <v-tab class="text-capitalize" @click="handleUpcomingClasses">
              Upcoming Classes
            </v-tab>
            <v-tab class="text-capitalize" @click="handlePastClasses">
              Past Classes
            </v-tab>
          </v-tabs>
        </v-card>
      </v-col>
      <v-col cols="9">
        <v-card
          v-if="isUpcoming"
          flat
          :style="{ background: $vuetify.theme.themes.light.background }"
        >
          <p v-if="noUpcomingData" class="py-5 px-5">No upcoming classes</p>
          <v-layout class="white pb-4 pt-2 pl-2 pr-4" v-else>
            <v-flex xs9>
              <v-card-text class="py-2 primary--text font-weight-bold">
                Time
              </v-card-text>
            </v-flex>
            <v-flex xs12>
              <v-card-text class="py-2 primary--text font-weight-bold">
                Class
              </v-card-text>
            </v-flex>
            <v-flex xs9>
              <v-card-text class="py-2 primary--text font-weight-bold">
                Studio
              </v-card-text>
            </v-flex>
            <v-flex xs9>
              <v-card-text class="py-2 primary--text font-weight-bold">
                Location
              </v-card-text>
            </v-flex>
            <v-flex xs6>
              <div class="py-2"></div>
            </v-flex>
          </v-layout>
          <class-view
            :studio="studio"
            :classes="upcomingClassesAndCourseSessions"
            :bookBtn="bookBtn"
            :cancelUpcomingBtn="true"
            :isClickable="true"
            @success="handleUpcomingSuccess"
            @refresh="refresh"
          />
          <v-skeleton-loader
            class="mt-5"
            v-if="upcomingDataLoading"
            type="card"
          />
        </v-card>
        
        <v-card
          v-if="isPast"
          flat
          :style="{ background: $vuetify.theme.themes.light.background }"
        >
          <p
            v-if="noPastData && pastClassesAndCoursesRaw.length === 0"
            class="py-5 px-5"
          >
            No classes in the past
          </p>
          <v-layout
            class="white pb-2 pt-2 pl-1"
            align-content-center="true"
            v-else
          >
            <v-flex xs9>
              <v-card-text class="py-2 primary--text font-weight-bold">
                Time
              </v-card-text>
            </v-flex>
            <v-flex xs12>
              <v-card-text class="py-2 primary--text font-weight-bold">
                Class
              </v-card-text>
            </v-flex>
            <v-flex xs9>
              <v-card-text class="py-2 primary--text font-weight-bold">
                Studio
              </v-card-text>
            </v-flex>
            <v-flex xs9>
              <v-card-text class="py-2 primary--text font-weight-bold">
                Location
              </v-card-text>
            </v-flex>
          </v-layout>
          <class-view
            :studio="studio"
            :classes="pastClassesAndCourseSessions"
            :bookBtn="bookBtn"
            @refresh="refresh"
          />
          <v-skeleton-loader class="mt-5" v-if="pastDataLoading" type="card" />
          <div class="d-flex justify-center">
            <v-btn
              v-if="!noPastData"
              text
              class="primary text-capitalize mt-2"
              @click="getPastClassesAndCourseSessions"
              >View more classes</v-btn
            >
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-card class="d-none d-sm-flex d-md-none" flat>
      <v-tabs centered>
        <v-tab class="text-capitalize"> Upcoming Classes </v-tab>
        <v-tab class="text-capitalize"> Past Classes </v-tab>

        <v-tab-item>
          <v-card flat>
            <p v-if="noUpcomingData" class="py-5 px-5">No upcoming classes</p>
            <class-view
              :isRoot="true"
              :studio="studio"
              :classes="upcomingClassesAndCourseSessions"
              :bookBtn="bookBtn"
              :cancelUpcomingBtn="true"
              :myClassMd="true"
              :isClickable="true"
              @success="handleUpcomingSuccess"
              @refresh="refresh"
            />
            <v-skeleton-loader
              class="mt-5"
              v-if="upcomingDataLoading"
              type="card"
            />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <p v-if="noPastData" class="py-5 px-5">No classes in the past</p>
            <class-view
              :studio="studio"
              :classes="pastClassesAndCourseSessions"
              :bookBtn="bookBtn"
              :myClassMd="true"
              @refresh="refresh"
            />
            <v-skeleton-loader
              class="mt-5"
              v-if="pastDataLoading"
              type="card"
            />
            <div class="d-flex justify-center">
              <v-btn
                text
                class="primary text-capitalize"
                @click="getPastClassesAndCourseSessions"
                v-if="!noPastData"
                >View more classes</v-btn
              >
            </div>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <v-card class="d-flex d-sm-none my-5" flat>
      <v-tabs centered>
        <v-tab class="text-capitalize"> Upcoming Classes </v-tab>
        <v-tab class="text-capitalize"> Waitlist </v-tab>
        <v-tab class="text-capitalize"> Past Classes </v-tab>

        <v-tab-item>
          <v-card flat>
            <p v-if="noUpcomingData" class="py-5 px-5">No upcoming classes</p>
            <class-view
              :studio="studio"
              :classes="upcomingClassesAndCourseSessions"
              :bookBtn="bookBtn"
              :cancelUpcomingBtn="true"
              @success="handleUpcomingSuccess"
              :isClickable="true"
              :isMobileView="true"
              @refresh="refresh"
            />
            <v-skeleton-loader
              class="mt-5"
              v-if="upcomingDataLoading"
              type="card"
            />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card flat>
            <p v-if="noWaitlistData" class="py-5 px-5">No waitlisted classes</p>
            <class-view
              :studio="studio"
              :classes="waitlistedClasses"
              :bookBtn="bookBtn"
              :cancelWaitlistBtn="true"
              @success="handleWaitlistSuccess"
              :isClickable="true"
              :isMobileView="true"
              @refresh="refresh"
            />
            <v-skeleton-loader
              class="mt-5"
              v-if="waitlistDataLoading"
              type="card"
            />
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card
            flat
            :style="{ background: $vuetify.theme.themes.light.background }"
          >
            <p v-if="noPastData" class="py-5 px-5">No classes in the past</p>
            <class-view
              :studio="studio"
              :classes="pastClassesAndCourseSessions"
              :bookBtn="bookBtn"
              :isMobileView="true"
              @refresh="refresh"
            />
            <v-skeleton-loader
              class="mt-5"
              v-if="pastDataLoading"
              type="card"
            />
            <div class="d-flex justify-center">
              <v-btn
                text
                class="primary text-capitalize"
                @click="getPastClassesAndCourseSessions"
                v-if="!noPastData"
                >View more classes</v-btn
              >
            </div>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {
  getUserUpcomingClasses,
  getUserPastClasses,
  getUserWaitlist,
} from "@/util/cloudFunctions/classesFunctions";
import {
  getUpcomingUserCourseSessions,
  getPastUserCourseSessions,
} from "@/util/cloudFunctions/coursesFunctions";
import ClassView from "@/components/browseclasses/ClassView.vue";
import { AUTHENTICATION_MODULE_NAME } from "@/store/moduleNames";
import { STUDIO_MODULE_NAME } from "@/store/moduleNames";
import { ALERT_MODULE_NAME } from "@/store/moduleNames";
import {
  dateStringFromTimestamp,
  timeStringFromTimestamp,
} from "@/util/dateformat.js";
import UserAuth from "@/components/auth/UserAuth";
// Analytics
import {
  CLICK_UPCOMING_CLASSES,
  CLICK_WAITLIST,
  CLICK_PAST_CLASSES,
} from "@/analytics/events";

export default {
  components: {
    ClassView,
    UserAuth,
  },
  created() {
    const methods = [
      this.getUpcomingClassesAndCourseSessions(),
      this.getWaitlistClasses(),
      this.getPastClassesAndCourseSessions(),
    ];
    Promise.all(methods);
  },
  data() {
    return {
      upcomingClassesAndCourseSessions: [],
      waitlistedClasses: [],
      pastClassesAndCourseSessions: [],
      pastClassesAndCoursesRaw: [],
      showUpcomingClasses: true,
      showWaitlistClasses: false,
      showPastClasses: false,
      noUpcomingData: false,
      noPastData: false,
      noWaitlistData: false,
      upcomingDataLoading: false,
      waitlistDataLoading: false,
      pastDataLoading: false,
      bookBtn: false,
      lastPastClassId: "",
      lastPastCourseId: "",
    };
  },
  computed: {
    isUpcoming() {
      return (
        this.showUpcomingClasses &&
        !this.showWaitlistClasses &&
        !this.showPastClasses
      );
    },
    isWaitlist() {
      return (
        !this.showUpcomingClasses &&
        this.showWaitlistClasses &&
        !this.showPastClasses
      );
    },
    isPast() {
      return (
        !this.showUpcomingClasses &&
        !this.showWaitlistClasses &&
        this.showPastClasses
      );
    },
    ...mapGetters({
      user: `${AUTHENTICATION_MODULE_NAME}/getUser`,
      isUserAuth: `${AUTHENTICATION_MODULE_NAME}/isUserAuth`,
      studio: `${STUDIO_MODULE_NAME}/getStudio`,
    }),
  },
  methods: {
    goToHome() {
      this.$router.push('/');
    },
    refresh() {
      this.upcomingClassesAndCourseSessions = [];
      this.waitlistedClasses = [];
      this.pastClassesAndCourseSessions = [];
      this.pastClassesAndCoursesRaw = [];
      this.getUpcomingClassesAndCourseSessions();
      this.getWaitlistClasses();
      this.getPastClassesAndCourseSessions();
    },
    ...mapActions({
      setAlertStateAction: `${ALERT_MODULE_NAME}/setAlertStateAction`,
    }),
    formatArrayToObject(classes, formattedObject) {
      for (let item of classes) {
        const date = new Date(item.classDate._seconds * 1000);
        date.setHours(0, 0, 0, 0);
        const dateKey = date.toISOString();
        if (!formattedObject.has(dateKey)) {
          formattedObject.set(dateKey, []);
        }
        item.timeStart = timeStringFromTimestamp(item.classDate);
        item.timeEnd = timeStringFromTimestamp(item.endTimestamp);
        formattedObject.get(dateKey).push(item);
      }
      return formattedObject;
    },
    async getClassesAndCourseSessions(isPast) {
      const promises = [];
      if (isPast) {
        promises.push(getUserPastClasses(this.lastPastClassId));
        promises.push(getPastUserCourseSessions(this.lastPastCourseId));
      } else {
        promises.push(getUserUpcomingClasses());
        promises.push(getUpcomingUserCourseSessions());
      }
      return Promise.all(promises).then((values) => {
        const formattedObject = new Map();
        const classesRes = values[0];
        const coursesRes = values[1];
        this.formatClasses(classesRes, formattedObject);
        this.formatCourses(coursesRes, formattedObject);
        if (isPast) {
          if (classesRes.docs.length > 0) {
            this.lastPastClassId =
              classesRes.docs[classesRes.docs.length - 1].id;
          }
          if (coursesRes.docs.length > 0) {
            this.lastPastCourseId =
              coursesRes.docs[coursesRes.docs.length - 1].id;
          }
          this.pastClassesAndCoursesRaw = [
            ...classesRes.docs,
            ...coursesRes.docs,
          ];
          this.pastClassesAndCourseSessions = [];
          const sortedDates = Array.from(formattedObject.keys()).sort(
            (a, b) => new Date(b).getTime() - new Date(a).getTime()
          ); // descending
          sortedDates.forEach((date) => {
            this.pastClassesAndCourseSessions.push({
              date: dateStringFromTimestamp(new Date(date)),
              item: formattedObject.get(date),
            });
          });
        } else {
          const sortedDates = Array.from(formattedObject.keys()).sort(
            (a, b) => new Date(a).getTime() - new Date(b).getTime()
          ); // ascending
          this.upcomingClassesAndCourseSessions = [];
          sortedDates.forEach((date) => {
            this.upcomingClassesAndCourseSessions.push({
              date: dateStringFromTimestamp(new Date(date)),
              item: formattedObject.get(date),
            });
          });
        }
      });
    },
    formatClasses(res, formattedObject) {
      if (res.success) {
        return this.formatArrayToObject(res.docs, formattedObject);
      } else {
        return [];
      }
    },
    formatCourses(res, formattedObject) {
      if (res.success) {
        return this.formatCourseRegistrationsToObject(
          res.docs,
          formattedObject
        );
      } else {
        return [];
      }
    },
    formatCourseRegistrationsToObject(courses, formattedObject) {
      for (const course of courses) {
        this.formatCourseRegistrationToObject(course, formattedObject);
      }
      return formattedObject;
    },
    formatCourseRegistrationToObject(course, formattedObject) {
      const {
        courseName,
        courseOfferingId,
        courseOfferingImage,
        studioId,
        location,
        instructor,
      } = course;
      const item = {
        courseName,
        courseOfferingId,
        courseOfferingImage,
        studioId,
        location,
        instructor,
      };
      const itemWithTiming = { ...item, name: courseName, classId: course.id };
      const date = new Date(course.startTimestamp._seconds * 1000);
      date.setHours(0, 0, 0, 0);
      const dateKey = date.toISOString();
      if (!formattedObject.has(dateKey)) {
        formattedObject.set(dateKey, []);
      }
      itemWithTiming.timeStart = timeStringFromTimestamp(course.startTimestamp);
      itemWithTiming.timeEnd = timeStringFromTimestamp(course.endTimestamp);
      formattedObject.get(dateKey).push(itemWithTiming);
      return formattedObject;
    },
    async getUpcomingClassesAndCourseSessions() {
      this.upcomingDataLoading = true;
      await this.getClassesAndCourseSessions(false);
      const len = Object.keys(this.upcomingClassesAndCourseSessions).length;
      if (len === 0) {
        this.noUpcomingData = true;
      }
      this.upcomingDataLoading = false;
    },
    async getPastClassesAndCourseSessions() {
      this.pastDataLoading = true;
      await this.getClassesAndCourseSessions(true);
      const len = Object.keys(this.pastClassesAndCourseSessions).length;
      if (len === 0) {
        this.noPastData = true;
      }
      this.pastDataLoading = false;
    },
    async getWaitlistClasses() {
      this.waitlistDataLoading = true;
      await getUserWaitlist().then((res) => {
        if (res.success) {
          if (res.docs.length === 0) {
            this.noWaitlistData = true;
          }
          const formattedObject = new Map();
          this.formatArrayToObject(res.docs, formattedObject);
          this.waitlistedClasses = [];
          const sortedDates = Array.from(formattedObject.keys()).sort(
            (a, b) => new Date(a).getTime() - new Date(b).getTime()
          ); // ascending
          sortedDates.forEach((date) => {
            this.waitlistedClasses.push({
              date: dateStringFromTimestamp(new Date(date)),
              item: formattedObject.get(date),
            });
          });
        }
        this.waitlistDataLoading = false;
      });
    },
    handleUpcomingClasses() {
      this.$gtag.event(CLICK_UPCOMING_CLASSES);
      this.showUpcomingClasses = true;
      this.showWaitlistClasses = false;
      this.showPastClasses = false;
    },
    handleWaitlistedClasses() {
      this.$gtag.event(CLICK_WAITLIST);
      this.showUpcomingClasses = false;
      this.showWaitlistClasses = true;
      this.showPastClasses = false;
    },
    handlePastClasses() {
      this.$gtag.event(CLICK_PAST_CLASSES);
      this.showUpcomingClasses = false;
      this.showWaitlistClasses = false;
      this.showPastClasses = true;
    },
    handleUpcomingSuccess() {
      this.upcomingClasses = {};
      this.getUpcomingClasses();
      const payload = {
        type: "success",
        message: "An email has been sent to you to confirm your cancellation.",
      };
      this.setAlertStateAction(payload);
    },
    handleWaitlistSuccess() {
      this.waitlistedClasses = {};
      this.getWaitlistClasses();
      const payload = {
        type: "success",
        message:
          "An email has been sent to you to confirm your class removal from waitlist.",
      };
      this.setAlertStateAction(payload);
    },
  },
};
</script>
<style scoped>
.userAuth {
  max-width: 300px;
}
.hover {
  cursor: pointer;
}
</style>
