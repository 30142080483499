<template>
  <v-app :style="{ background: isWidget ? '#fff' : $vuetify.theme.themes.light.background }">
    <CollectUserDetails />
    <ProgressLoader class="pt-3" v-if="isLoading || isStudioFetching" />
    <router-view v-else></router-view>
    <v-spacer></v-spacer>
    <Footer v-if="!isMobile && !isWidget && !isSuccessOrFailedRoute" />
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { AUTHENTICATION_MODULE_NAME, GLOBAL_MODULE_NAME } from "@/store/moduleNames";
import ProgressLoader from "@/components/shared/ProgressLoader";
import CollectUserDetails from "@/components/shared/CollectUserDetails.vue";
import Footer from "@/components/footer/Footer";
import { WIDGET_ROUTE } from "@/constants";

export default {
  name: "App",
  components: {
    ProgressLoader,
    Footer,
    CollectUserDetails,
  },
  mounted() {
    this.authAction();
    this.setStudios();
  },
  watch: {
    isUserAuth: {
      handler() {
        if (this.user) {
          this.$gtag.config({ user_id: this.user.id });
        } else {
          this.$gtag.config({ user_id: null });
        }
      },
      immediate: true,
    },
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },
    ...mapGetters({
      isUserAuth: `${AUTHENTICATION_MODULE_NAME}/isUserAuth`,
      user: `${AUTHENTICATION_MODULE_NAME}/getUser`,
      isLoading: `${AUTHENTICATION_MODULE_NAME}/isLoading`,
      isStudioFetching: `${GLOBAL_MODULE_NAME}/isLoading`
    }),
    isWidget() {
      return this.$route.hash === WIDGET_ROUTE;
    },
    isSuccessOrFailedRoute() {
      return this.$route.path.includes("success") || this.$route.path.includes("canceled");
    },
  },
  methods: {
    ...mapActions({
      authAction: `${AUTHENTICATION_MODULE_NAME}/authAction`,
       setStudios: `${GLOBAL_MODULE_NAME}/getStudios`,
    }),
  },
};
</script>
