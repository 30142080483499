<template>

    <v-footer padless>

        <v-card
            class="d-flex primary flex-row"
            width="100%"
            flat
            tile
        >
            <v-card-title class="global-container">

                <v-row no-gutters justify="start" align="center">
                    <span class="white--text mx-5">{{footerBrandText}}</span>
                    <v-btn
                        v-for="socialLink in socialLinks"
                        :key="socialLink.link"
                        :href="socialLink.link"
                        @click="logEvent(socialLink.event)"
                        target="_blank"
                        class=""
                        dark
                        icon
                    >
                        <v-icon size="24px">
                            {{ socialLink.icon }}
                        </v-icon>
                    </v-btn>
                </v-row>
                
            </v-card-title>

        </v-card>

    </v-footer>
</template>
<script>
// Analytics
import { CLICK_QR_CODE, CLICK_VIBEFAM_LINK, CLICK_FACEBOOK_LINK, CLICK_INSTAGRAM_LINK, CLICK_YOUTUBE_LINK, CLICK_LINKEDIN_LINK } from "@/analytics/events";
import { getCurrentYear } from "../../util/dateformat";

export default {
    data() {
        return {
            dialog: false,
            socialLinks: [
                {
                    icon: "mdi-link",
                    link: "https://vibefam.com/",
                    event: CLICK_VIBEFAM_LINK
                },
                {
                    icon: "mdi-facebook",
                    link: "https://www.facebook.com/vibefam.official",
                    event: CLICK_FACEBOOK_LINK
                },
                {
                    icon: "mdi-instagram",
                    link: "https://www.instagram.com/vibefam.official/",
                    event: CLICK_INSTAGRAM_LINK
                },
                {
                    icon: "mdi-youtube",
                    link: "https://www.youtube.com/channel/UCZk8fuzxrk9MZn-oA5L_rCg",
                    event: CLICK_YOUTUBE_LINK
                },
                {
                    icon: "mdi-linkedin",
                    link: "https://www.linkedin.com/company/vibefam/",
                    event: CLICK_LINKEDIN_LINK
                }
            ],
        }
    },
    computed: {
        footerBrandText() {
            const year = getCurrentYear();
            return `vibefam © ${year}`
        },
        isMobile() {
            return this.$vuetify.breakpoint.name === 'xs';
        },
        studioLogoSize() {
            switch (this.$vuetify.breakpoint.name) {
                case "xs": return 52;
                case "sm": return 70;
                case "md":
                case "lg":
                case "xl":
                    return 80;
            }
            return 52;
        },
    },
    methods: {
        openQRDialog() {
            this.logEvent(CLICK_QR_CODE);
            this.dialog = true;
        },
        logEvent(event) {
            this.$gtag.event(event);
        }
    }
}
</script>

<style scoped>
.background {
    background-color: #A259FF;
}
.qr-code {
    height: 50px;
    cursor: pointer;
}
.letter {
    letter-spacing: 5px;
    font-weight: 600;
    font-size: 20px;
}
</style>