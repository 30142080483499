import getAllStudios from "@/util/cloudFunctions/getAllStudios";

export const actionsGlobal = {
    getStudios({ commit}) {
        setup(commit);
    }
}

async function setup(commit) {
    const studios = await getAllStudios();
    commit('setStudios', studios);
    commit('setLoading', false);
}