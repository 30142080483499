const defaultCurrency = "SGD";

const currencyMap = {
    "SGD": "S$",
    "HKD": "HK$",
    "MYR": "RM",
    "VND": "₫",
    "IDR": "Rp",
}

function getDisplayCurrency(currency) {
    if (!currency) {
        return currencyMap[defaultCurrency];
    }
    return currencyMap[currency] ?? currencyMap[defaultCurrency];
}

export default getDisplayCurrency;
