<template>
    <div>
        <h2 class="font-weight-medium">
            Locations
        </h2>

        <v-col no-gutters>
            <div v-for="location of locations" :key="location.id" class="ma-3 d-flex">
                <div class="locationImage">
                    <v-img :src="location.images[0]"></v-img>
                </div>
                <div>
                    <div class="font-weight-medium">
                        {{ location.name }}
                    </div>
                    <div class="text--secondary">
                        {{ parseAddress(location.address, location.postalCode) }}
                    </div>
                    <div class="d-flex">
                        <div v-for="(amenity, index) of location.amenities" :key="index" class="mr-1">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-icon v-bind="attrs" v-on="on">{{ amenitiesMap[amenity] }}</v-icon>
                                </template>
                                <span>{{ amenity }}</span>
                            </v-tooltip>
                        </div>
                    </div>
                </div>
            </div>
        </v-col>
        
        
    </div>
</template>
<script>
export default {
    props: {
        studio: Object,
    },
    data() {
        return {
            amenitiesMap: {
                "Showers": "mdi-shower-head",
                "Lockers": "mdi-locker-multiple",
                "Parking": "mdi-parking",
                "Mat": "mdi-rug",
                "Towel": "mdi-shape-rectangle-plus",
            }
        }
    },
    computed: {
        locations() {
            return this.studio.locations;
        },
        locationHeight() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 250
                case 'sm': return 350
                case 'md':
                case 'lg':
                case 'xl':
                    return 400;
            }
            return 250;
        },
    },
    methods: {
        parseAmenities(amenities) {
            return amenities.join(", ");
        },
        parseAddress(address, postalCode) {
            return `${address} ${postalCode}`;
        },
    }
}
</script>
<style scoped>
.locationImage {
    border-radius: 5px;
    width: 200px;
    height: 111px;
    overflow: hidden;
    margin-right: 16px;
}
</style>
