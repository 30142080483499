<template>
    <div>
        <BlockingLoader v-model="isPaymentProcessing" text="Waiting for your payment to succeed...">
            <v-btn @click="checkCheckoutSessionStatus" block color="primary">Done</v-btn>
        </BlockingLoader>
        <v-btn @click="createCheckoutSession" color="primary" :loading="loading" x-large>
            <v-icon left>mdi-credit-card</v-icon> Checkout
        </v-btn>
    </div>
</template>
<script>
import BlockingLoader from "@/components/shared/BlockingLoader.vue";

import { createCheckoutSessionForProductsPurchase } from "@/util/cloudFunctions/stripe";
import { retrieveCheckoutSessionStatus } from "@/util/cloudFunctions/stripe";
import { getNumberOfCartItems } from "@/util/cloudFunctions/shoppingCart";

import { mapGetters } from 'vuex';
import { STUDIO_MODULE_NAME } from '@/store/moduleNames';

export default {
    components: {
        BlockingLoader
    },
    data() {
        return {
            loading: false,
            isPaymentProcessing: false,
        }
    },
    computed: {
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
    },
    methods: {
        logError(error) {
            console.error(error);
            this.loading = false;
            return;
        },
        async createCheckoutSession() {
            this.loading = true;
            const baseUrl = window.location.href.split('?')[0];
            // check whether there are any cart items first
            const countResult = await getNumberOfCartItems(this.studio.id);
            if (!countResult.success) {
                this.loading = false;
                return;
            }
            if (countResult.count <= 0) {
                this.loading = false;
                return;
            }

            const result = await createCheckoutSessionForProductsPurchase(this.studio.id, baseUrl);
            if (!result.success) {
                this.logError(result.error);
                this.loading = false;
                return;
            }
            this.loading = false;
            this.sessionId = result.sessionId;
            this.url = result.sessionUrl;
            this.openCheckoutSession();
        },
        openCheckoutSession() {
            window.open(this.url, '_blank');
            this.isPaymentProcessing = true;
        },
        async checkCheckoutSessionStatus() {
            const result = await retrieveCheckoutSessionStatus(this.sessionId, this.studio.stripeAccountId);
            if (result.success) {
                this.$emit("success");
            }
            this.isPaymentProcessing = false;
        },
    }
}
</script>
