import { render, staticRenderFns } from "./MerchandiseCard.vue?vue&type=template&id=d4a3381c&scoped=true&"
import script from "./MerchandiseCard.vue?vue&type=script&lang=js&"
export * from "./MerchandiseCard.vue?vue&type=script&lang=js&"
import style0 from "./MerchandiseCard.vue?vue&type=style&index=0&id=d4a3381c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d4a3381c",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCarousel } from 'vuetify/lib/components/VCarousel';
import { VCarouselItem } from 'vuetify/lib/components/VCarousel';
installComponents(component, {VBtn,VCard,VCardSubtitle,VCardText,VCardTitle,VCarousel,VCarouselItem})
