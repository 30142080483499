<template>
  <v-dialog v-model="showDialog" @click:outside="cancelCallback" width="600">
    <v-card flat max-width="600" min-width="200">
      <v-card-title class="secondary white--text">{{ title }}</v-card-title>
      <v-card-text>
        <v-row justify="center" align="center">
          <v-col cols="12">
            <p class="text--secondary my-5 text-center">
              {{ content }}
            </p>
          </v-col>
          <v-col cols="12" v-if="errorMessage">
            <p ref="errorMessage" class="error--text my-5 text-center">
              {{ errorMessage }}
            </p>
          </v-col>
          <v-col cols="12">
            <slot></slot>
          </v-col>
          <v-btn @click="cancelCallback" class="mx-2" :disabled="loading">Cancel</v-btn>
          <v-btn
            class="secondary"
            depressed
            @click="confirmCallback"
            :loading="loading"
            :disabled="disabled"
            >{{ confirmButtonText }}</v-btn
          >
        </v-row>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    showDialog: Boolean,
    title: String,
    content: String,
    errorMessage: {
      type: String,
      default: "",
    },
    cancelCallback: Function,
    confirmCallback: Function,
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    confirmButtonText: {
      type: String,
      default: "Confirm"
    }
  },
  created() {
  }
};
</script>
