<template>
  <v-menu
    ref="menu"
    v-model="dateMenu"
    :close-on-content-click="false"
    :return-value.sync="date"
    transition="scale-transition"
    offset-y
    min-width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :prepend-icon="icon"
        ref="dateTextField"
        v-model="date"
        :label="label ? label : 'Date'"
        readonly
        :error-messages="errors"
        v-bind="attrs"
        v-on="on"
        :hint="hint"
        v-bind:persistent-hint="true"
        background-color="white"
        :clearable="clearable"
        :outlined="outlined"
        :solo="solo"
        :dense="dense"
        :rounded="rounded"
        :hide-details="hideDetails"
      ></v-text-field>
    </template>
    <v-date-picker
      color="primary white--text"
      v-model="date"
      scrollable
      :min="this.min ? this.min : null"
      :max="this.max ? this.max : null"
      slot
    >
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="dateMenu = false" depressed
        >Cancel</v-btn
      >
      <v-btn text color="primary" @click="emitDate" depressed>OK</v-btn>
    </v-date-picker>
  </v-menu>
</template>

<script>
export default {
  props: [
    "label",
    "value",
    "errors",
    "min",
    "max",
    "hint",
    "outlined",
    "solo",
    "dense",
    "rounded",
    "hideDetails",
    "clearable",
    "icon"
  ],
  methods: {
    emitDate() {
      const date = this.date;
      this.$refs.menu.save(date);
      this.$emit("input", date);
    },
  },
  data() {
    return {
      dateMenu: false,
      date: this.value,
    };
  },
};
</script>
