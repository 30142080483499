<template>
    <div>
        <v-col align="center">
            <p class="error--text text-center" v-if="error !== ''">
                {{ error }}
            </p>

            <v-btn
                class="facebook-auth-btn white--text"
                :color="color"
                @click="submit"
                :loading="loading"
                depressed
            >
                <v-icon left>mdi-facebook</v-icon>Continue With Facebook
            </v-btn>
        </v-col>
    </div>
</template>

<script>
import getAuthErrorMessage from "@/util/authErrors";
import { auth, FacebookAuthProvider, signInWithPopup } from "@/firebase";

// Analytics
import { FACEBOOK_LOGIN, FACEBOOK_LOGIN_ERROR } from "@/analytics/events";
import { subStr } from "@/analytics/util";

export default {
    data() {
        return {
            loading: false,
            error: "",
            color: "#3b5998",
            dialog: false,
        };
    },
    methods: {
        submit() {
            this.error = "";
            this.loading = true;

            const provider = new FacebookAuthProvider();

            signInWithPopup(auth, provider)
                .then(async () => {
                    this.$gtag.event(FACEBOOK_LOGIN);
                })
                .catch((error) => {
                    this.$gtag.event(FACEBOOK_LOGIN_ERROR, {
                        errorMessage: subStr(error.code),
                    });
                    this.error = getAuthErrorMessage(error.code);
                    this.loading = false;
                });
        },
    },
};
</script>
<style scoped>
.facebook-auth-btn {
    background-color: "#3b5998";
}
</style>
