<template>
    <v-col style="height: 100%;">
        <h2 class="choose-spot-header mb-3">1. Pick a spot</h2>
        <v-card height="100%" :min-height="cardHeight">
            <v-container ref="SpotsContainer" style="position: relative;" fill-height>
                <div v-for="(spot, index) in spots" :key="index" :style="spotsStyle[index]" class="d-flex flex-column align-center">
                    <v-tooltip bottom :disabled="!(spot.isClasspassActive && hasAvailableNonClasspassSpot)">
                        <template v-slot:activator="{ on }">
                            <v-btn
                                v-on="on"
                                fab 
                                @click="onSelectSpot(parseInt(index))" 
                                :color="spotsColor[index]"
                                :class="getSpotClass(spot)"
                                :depressed="isSpotDisabled(spot)"
                                v-bind="spotSize"
                            >
                                <v-icon v-if="spot.isClasspassActive && hasAvailableNonClasspassSpot">
                                    mdi-block-helper
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>These spots will open up after the currently available spots are fully booked.</span>
                    </v-tooltip>
                    <p :class="spot.isInstructor ? '' : 'text--secondary'">{{ spotsText[index] }}</p>
                </div>
            </v-container>
        </v-card>
    </v-col>
    
</template>
<script>
import { mapGetters } from "vuex";
import { AUTHENTICATION_MODULE_NAME } from "@/store/moduleNames";

// Analytics
import { SELECT_SPOT } from "@/analytics/events";

export default {
    props: {
        spots: Array,
        height: Number,
        width: Number
    },
    mounted() {
        this.mountSpots();
        window.addEventListener('resize', this.mountSpots, { passive: true });
    },
    data() {
        return {
            spotsStyle: this.spots.map(() => ({})),
            selectedSpotIndex: null // starts from 1
        }
    },
    computed: {
        cardHeight() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs': return 250
                case 'sm': return 350
                case 'md':
                case 'lg':
                case 'xl':
                    return 480
            }
            return 250;
        },
        spotSize() {
            const size = { xs: 'x-small', sm: 'small' }[this.$vuetify.breakpoint.name];
            return size ? { [size]: true } : {};
        },
        ...mapGetters({
            isUserAuth: `${AUTHENTICATION_MODULE_NAME}/isUserAuth`,
            user: `${AUTHENTICATION_MODULE_NAME}/getUser`,
        }),
        spotsColor() {
            return this.spots.map((spot) => {

                if (spot.isInstructor) {
                    return "#00008b";
                }

                if (spot.isBlocked || spot.isOccupied) {
                    return "primary"
                }

                if (this.isSpotSelected(spot)) {
                    return "yellow";
                }

                return "";
            })
        },
        spotsText() {
            if (this.$vuetify.breakpoint.name === "xs") {
                return this.spots.map(() => "");
            } 

            return this.spots.map((spot) => {
                if (spot.isInstructor) {
                    return "Instructor";
                }

                if (spot.isBlocked) {
                    return "Reserved";
                }

                if (spot.isOccupied) {
                    return spot.cp_reservationId ? "Reserved" : spot.username;
                }

                if (this.isSpotSelected(spot)) {
                    if (this.isUserAuth) {
                        return this.user.username;
                    }
                    return 'Selected';
                }

                return "";
            })
        },
        hasAvailableNonClasspassSpot() {
            const availableNonClasspassSpot = this.spots.find((spot) => !spot.isInstructor && !spot.isBlocked && !spot.isOccupied && !spot.isClasspassActive);
            if (availableNonClasspassSpot) {
                return true;
            }
            return false;
        },
    },
    methods: {
        mountSpots() {
            const scaleFactor = 0.90; // Arbitrary scaling factor
            const spotsContainerHeight = this.$refs.SpotsContainer.clientHeight * scaleFactor;
            const spotsContainerWidth = this.$refs.SpotsContainer.clientWidth * scaleFactor;
            this.spotsStyle = this.spots.map((spot) => {
                const xAxis = (spot.x / this.width) * spotsContainerWidth;
                const yAxis = (spot.y / this.height) * spotsContainerHeight;
                return {
                    position: 'absolute',
                    left: `${xAxis}px`,
                    top: `${yAxis}px`
                }
            });
        },
        isSpotSelected(spot) {
            const spotIndex = parseInt(this.spots.indexOf(spot));
            if (spotIndex === this.selectedSpotIndex) {
                return true;
            }
            return false;
        },
        onSelectSpot(spotNumber) {
            if (this.spots[spotNumber].isClasspassActive && this.hasAvailableNonClasspassSpot) {
                this.selectedSpotIndex = null;
                this.$emit('input', null);
                return;
            }
            this.selectedSpotIndex = spotNumber;
            this.$gtag.event(SELECT_SPOT);
            this.$emit('input', spotNumber);
        },
        isSpotDisabled(spot) {
            if (spot.isInstructor || spot.isBlocked || spot.isOccupied) {
                return true;
            }
            return false;
        },
        getSpotClass(spot) {
            return this.isSpotDisabled(spot) ? "disable-events" : "";
        },
    },
    beforeDestroy () {
        if (typeof window === 'undefined') return;
        window.removeEventListener('resize', this.onResize, { passive: true });
    },
}
</script>
<style scoped>
.disable-events {
    pointer-events: none
}

.choose-spot-header {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    /* font-size: 45px; */
    line-height: 32px;
    /* identical to box height, or 71% */

    display: flex;
    align-items: center;
}

</style>
