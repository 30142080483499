import { auth } from '@/firebase';
import getUserDocument from '@/util/cloudFunctions/userFunctions';

export const actionsAuth = {
    authAction({ commit }) {
        auth.onAuthStateChanged(async (user) => {
            if (user) {
                await setUserDocument(commit);
            } else {
                commit("setUser", null);
            }            
            commit("setLoading", false);
        });
    },
    closeCollectUserDetails({ commit }) {
        commit("setCollectUserDetails", false);
    },
    refreshUserDocument({ commit }) {
        setUserDocument(commit);
    },
}

async function setUserDocument(commit) {
    const result = await getUserDocument(auth.currentUser.uid);
    if (result.success) {
        const userDoc = result.doc;
        const credits = result.credits;
        commit("setCredits", credits);
        commit("setUser", userDoc);
    } else {
        // Logged in but cannot find user document, so we need to collect the user details
        // Only happens with external auth
        if (auth.currentUser.providerData[0].providerId !== "password") {
            commit("setUser", { id: auth.currentUser.uid });
            commit("setCollectUserDetails", true);
        }
    }
}
