import { auth, fetchSignInMethodsForEmail } from "@/firebase";


/**
 * Returns a formatted sign in method.
 * 
 * Possible sign in methods.
 * EmailAuthProviderID: password
 * PhoneAuthProviderID: phone
 * GoogleAuthProviderID: google.com
 * FacebookAuthProviderID: facebook.com
 * TwitterAuthProviderID: twitter.com
 * GitHubAuthProviderID: github.com
 * AppleAuthProviderID: apple.com
 * YahooAuthProviderID: yahoo.com
 * MicrosoftAuthProviderID: hotmail.com
 * 
 * @param {String} email 
 * @returns sign in method string without the ".com" at the end
 */
async function fetchSignInMethod(email) {
    const result = await fetchSignInMethodsForEmail(auth, email);

    // When account is created on the web portal, there is no provider associated with the account
    // Therefore, if the user requests to reset the password, it means that we can safely assume that the user
    // is trying to login via the email/password method, and send him the reset password link.
    if (result.length == 0) {
        return "password";
    }

    let providerId = result[0];

    if (providerId.endsWith(".com")) {
        providerId = providerId.slice(0, -4);
    }

    return providerId;
}

export { fetchSignInMethod }