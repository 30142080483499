export const gettersStudio = {
    isLoading(state) {
        return state.loading;
    },
    isInvalid(state) {
        return !state.studio && !state.loading;
    },
    getStudio(state) {
        return state.studio;
    }
};
