import { db } from "@/firebase";
import { collection, query, getDocs, where, Timestamp } from "firebase/firestore";
import moment from "moment";

async function getStudioClassesTwoWeeks(studioId, locationId, startTime, endTime) {
    const startHour = startTime.split(':')[0];
    const startMinutes = startTime.split(':')[1];
    const endHour = endTime.split(':')[0];
    const endMinutes = endTime.split(':')[1];
    const today = moment().hour(startHour).minute(startMinutes);
    const fourteenDays = today.clone().add(13, 'days');
    const start = Timestamp.fromDate(today.toDate());
    const end = Timestamp.fromDate(fourteenDays.toDate());
    const q = query(
        collection(db, "classes"),
        where("studioId", "==", studioId),
        where("locationId", "==", locationId),
        where("isPublic", "==", true),
        where("startTimestamp", ">=", start),
        where("startTimestamp", "<=", end),
        where('creditBookable', '==', true)
    );

    const querySnapshot = await getDocs(q);
    const mapped = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })).filter((data) => {
        const start = data.startTimestamp.toDate();
        const momentStart = moment(start);
        const minimumStart = momentStart.clone().hour(startHour).minute(startMinutes);
        const minimumEnd = momentStart.clone().hour(endHour).minute(endMinutes);
        return momentStart.isSameOrAfter(minimumStart) && momentStart.isSameOrBefore(minimumEnd);
    });
    return mapped;
}

/**
 * Gets studio classes at the location for the date and time
 * @param {String} studioId 
 * @param {String} locationId 
 * @param {String} date 
 * @param {String} startTime
 * @param {String} endTime 
 */
async function getStudioClasses(studioId, locationId, date, startTime, endTime) {
    const start = Timestamp.fromDate(moment(`${date} ${startTime}`).toDate());
    const end = Timestamp.fromDate(moment(`${date} ${endTime}`).toDate());
    const q = query(
        collection(db, "classes"),
        where("studioId", "==", studioId),
        where("locationId", "==", locationId),
        where("isPublic", "==", true),
        where("startTimestamp", ">=", start),
        where("startTimestamp", "<=", end),
        where('creditBookable', '==', true)
    );

    const querySnapshot = await getDocs(q);
    const mapped = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
    return mapped;
}

export { getStudioClasses, getStudioClassesTwoWeeks };
