import Vue from 'vue'
import App from './App.vue'
import Vuex from "vuex";
import vuetify from './plugins/vuetify'
import router from "./router"
import '@/assets/global.scss';
import { store } from "./store";
import VueGtag from "vue-gtag";
import Hotjar from 'vue-hotjar';
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
import * as VueGoogleMaps from 'vue2-google-maps'


Vue.component('vue-phone-number-input', VuePhoneNumberInput);
Vue.config.productionTip = false
Vue.use(
  VueGtag,
  {
    config: { id: process.env.VUE_APP_FIREBASE_MEASUREMENT_ID },
  },
);
Vue.use(Vuex);
Vue.use(router);
Vue.use(Hotjar, {
  id: process.env.VUE_APP_HOTJAR_SITE_ID,
  isProduction: process.env.VUE_APP_SERVER === "production",
  snippetVersion: 6
})

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_KEY,
    libraries: 'places',
  },
})

new Vue({
  vuetify,
  Vuex,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
