import { functions, httpsCallable } from '@/firebase';

export function getClassesFromStudioId(data) {
    const { studioId, classFilter, 
        instructorFilter, locationFilter, startDate, endDate, lastDocId } = data;
    const getClassesByStudio = httpsCallable(functions, 'getClassesByStudio');
    const callData = { studioId, classFilter, instructorFilter, locationFilter, startDate, endDate, lastDocId };
    return getClassesByStudio(callData).then(result => result.data)
}

export function getUserUpcomingClasses(studioId) {
    const data = {}
    if (studioId) {
        data.studioId = studioId;
    }
    const getMyUpcomingClasses = httpsCallable(functions, 'getMyUpcomingClasses');
    return getMyUpcomingClasses(data).then(result => result.data);
}

export function getUserPastClasses(lastDocId, studioId) {
    const userData = { lastDocId };
    if (studioId) {
        userData.studioId = studioId;
    }
    const getMyPastClasses = httpsCallable(functions, 'getMyPastClasses');
    return getMyPastClasses(userData).then(result => result.data);
}

export function getUserWaitlist(studioId) {
    const data = { }
    if (studioId) {
        data.studioId = studioId;
    }
    const getMyWaitlist = httpsCallable(functions, 'getMyWaitlist');
    return getMyWaitlist(data).then(result => result.data);
}

export function cancelClass(data) {
    const { signupId, canRefund } = data
    const cancelClassSignup = httpsCallable(functions, "cancelClassSignup");
    return cancelClassSignup({ signupId, canRefund }).then(result => result.data);
}

export function removeWaitlistClass(data) {
    const { waitlistId } = data;
    const removeFromWaitlist = httpsCallable(functions, "removeFromWaitlist");
    return removeFromWaitlist({ waitlistId }).then(result => result.data);
}

export function getClassByClassId(data) {
    const { classId } = data;
    const getClassById = httpsCallable(functions, "getClassById");
    return getClassById({ classId }).then(result => result.data);
}

/**
 * Books Class for the logged in user.
 * @param {String} classId 
 * @param {Integer} spotPosition
 * @param {String} packageId
 */
export function bookClass(classId, spotNumber, isCredit, packageId) {
    const bookClassFromWebApp = httpsCallable(functions, 'bookClass');
    return bookClassFromWebApp({ classId, isCredit, spotPosition: spotNumber, packageId }).then((result) => result.data).catch(() => ({ success: true }));
}

export function waitlistClass(classId, packageId) {
    const addToWaitlist = httpsCallable(functions, 'addToWaitlist');
    return addToWaitlist({ classId, packageId }).then((result) => result.data).catch(() => ({ success: true }));
}