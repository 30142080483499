<template>
  <v-col align="center" class="pb-0">
    <lottie-animation
      path="lottie/payment-success.json"
      :height="dimensions"
      :width="dimensions"
    />
    <h1 class="success--text">Payment Successful</h1>
    <p v-if="!isMobile">You can safely close this tab</p>
    <v-btn class="primary my-5" v-if="isMobile" @click="routeToStudio"
      >Back to home page</v-btn
    >
  </v-col>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs

export default {
  components: {
    LottieAnimation,
  },
  computed: {
    dimensions() {
      return this.$vuetify.breakpoint.mdAndUp ? 500 : 350;
    },
  },
  data() {
    return {
      isMobile: false,
      studioRoute: "",
    };
  },
  methods: {
    routeToStudio() {
      const route = this.studioRoute;
      const isMobile = this.isMobile;
      if (isMobile && route) {
        this.$router.replace(`/${route}`);
      }
    },
  },
  created() {
    const route = this.$route.query.route;
    const isMobile = this.$vuetify.breakpoint.smAndDown;
    if (isMobile && route) {
      this.studioRoute = route;
      this.isMobile = isMobile;
    }
  },
};
</script>