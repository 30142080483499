<template>
  <div class="card-center">
    <v-btn
      outlined
      text
      class="text-capitalize text--secondary"
      @click="openDialog(subItem)"
      v-on:click.prevent
    >
      Cancel Class
    </v-btn>
    <!-- Dialog for Deletion of Class Registration  -->
    <WarningDialog
      :showDialog="dialog"
      title="Cancel Sign Up"
      :content="warningDialogContent"
      :cancelCallback="closeDialog"
      :confirmCallback="cancelSignup"
      :errorMessage="error"
      :loading="loading"
    >
    </WarningDialog>
  </div>
</template>

<script>
import WarningDialog from "@/components/shared/WarningDialog";
import { cancelClass } from "@/util/cloudFunctions/classesFunctions";
// Analytics
import { CANCEL_CLASS, CANCEL_CLASS_ERROR } from "@/analytics/events";
import { subStr } from "@/analytics/util";
import { mapActions } from "vuex";
import { AUTHENTICATION_MODULE_NAME } from "@/store/moduleNames";

export default {
  props: {
    subItem: Object,
  },
  components: {
    WarningDialog,
  },
  data() {
    return {
      dialog: false,
      cancelItem: {},
      error: "",
      loading: false,
    };
  },
  computed: {
    warningDialogContent() {
      return `You are about to cancel this class. You will ${
        this.canRefund
          ? `be refunded ${
              this.subItem.creditsRequired
                ? `${this.subItem.creditsRequired} class credits`
                : ""
            }.`
          : "not be refunded."
      }
                There will be an email sent to you to confirm the cancellation.`;
    },
    canRefund() {
      const classDateSeconds = this.subItem.classDate._seconds;
      const limitSeconds =
        classDateSeconds - this.subItem.refundPolicy * 60 * 60;
      const todaySeconds = new Date().getTime() / 1000;

      const canRefund = todaySeconds <= limitSeconds;
      return canRefund;
    },
  },
  methods: {
    ...mapActions({
      refreshUserDocument: `${AUTHENTICATION_MODULE_NAME}/refreshUserDocument`,
    }),
    async cancelSignup() {
      this.loading = true;
      const canRefund = this.canRefund;

      const data = {
        signupId: this.cancelItem.id,
        canRefund: canRefund,
      };

      const result = await cancelClass(data);
      if (result.success) {
        this.$gtag.event(CANCEL_CLASS);
        this.$emit("success");
        this.closeDialog();
        this.refreshUserDocument();
      } else {
        this.$gtag.event(CANCEL_CLASS_ERROR, {
          errorMessage: subStr(result.error),
        });
        this.error = result.error;
      }
      this.loading = false;
    },
    openDialog(item) {
      this.dialog = true;
      this.cancelItem = item;
    },
    closeDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped>
.card-center {
  margin-bottom: -10px !important;
  margin-top: 40px;
  margin-left: 5px;
}
</style>
