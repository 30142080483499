<template>
    <v-autocomplete
        dense
        rounded
        prepend-inner-icon="mdi-magnify"
        outlined
        placeholder="Search for studio directly"
        class="searchBox"
        hide-details
        :items="studios"
        item-text="studioName"
        return-object
        @change="navigateToStudioPage"
    >
        <template v-slot:item="data">
            <img :src="data.item.location.images[0]" class="studioImage my-3">
            <v-list-item-content>
                <v-list-item-title v-html="data.item.studioName"></v-list-item-title>
                <v-list-item-subtitle class="text-uppercase" v-html="data.item.category ? data.item.category.join(', ') : ''"></v-list-item-subtitle>
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>
<script>
export default {
    props: {
        studios: Array,
    },
    methods: {
        navigateToStudioPage(studio) {
            const studioRoute = studio.webAppRoute;
            const routeData = this.$router.resolve({path: `/${studioRoute}/studio`});
            window.open(routeData.href, '_blank');
        },
    },
}
</script>
<style scoped>
.searchBox {
    max-width: 400px;
    margin-right: 16px;
}

.studioImage {
    border-radius: 5px;
    width: 80px;
    min-height: 60px;
    min-width: 80px;
    overflow: hidden;
    margin-right: 16px;
}
</style>
