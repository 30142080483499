<template>
  <div>
    <v-form @submit.prevent="formHandler" v-if="!codeApplied">
      <div v-if="showHaveAPromoCodeText" id="defaultDiv">
        <v-btn plain @click="changeToField" ref="promoCodeText" class="primary--text"
          >Have a Promo Code?</v-btn
        >
      </div>
      <div id="promoCodeDiv" v-else>
        <div id="empty-space"></div>
        <div id="promoCodeField">
          <v-text-field
            label="Enter code"
            v-model="promoCode"
            ref="promoCodeFormField"
          ></v-text-field>
        </div>
        <div id="submitButton">
          <v-btn
            type="submit"
            color="primary"
            ref="applyButton"
            :loading="loading"
            :disabled="!promoCode"
            >Apply</v-btn
          >
        </div>
      </div>
    </v-form>
    <span id="messageDiv">
      <span style="color: red" v-if="message != null && isErrorMessage">
        {{ message }}
      </span>

      <v-btn
        id="messageBtn"
        v-else-if="message != null && !isErrorMessage"
        plain
        ref="messageBtn"
        @click.prevent="resetPromo"
      >
        {{ message }}
      </v-btn>
    </span>
  </div>
</template>

<script>
import { getEligiblePackageDiscounts } from '../../util/cloudFunctions/getEligiblePackageDiscounts';
import { getDiscountedCoursePrice } from '../../util/cloudFunctions/coursesFunctions';
import { auth } from '../../firebase';

export default {
  props: {
    packageItem: Object,
    courseItem: Object,
    studio: Object,
  },
  data() {
    return {
      isErrorMessage: false,
      showHaveAPromoCodeText: true,
      codeApplied: false,
      promoCode: null,
      message: null,
      loading: false,
    };
  },
  methods: {
    changeToField() {
      this.showHaveAPromoCodeText = false;
    },
    async formHandler() {
      try {
        this.loading = true;
        this.message = null;
        this.isErrorMessage = false;
        const currentUserID = auth.currentUser.uid;
        const studioId = this.studio.id;

        let newPriceEvent = {};
        if (this.packageItem) {
          const packageName = this.packageItem.name;
          const data = { studioId: studioId, code: this.promoCode };
          const results = await getEligiblePackageDiscounts(data, currentUserID);
          if (!results.data.success) {
            this.handleError(results.data.error);
            return;
          }
          const { eligiblePackages } = results.data;
          if (!eligiblePackages ||
            eligiblePackages[packageName] == null ||
            eligiblePackages[packageName] == undefined
          ) {
            this.handleError('The promo code does not apply to this package.');
            return;
          } else {
            this.codeApplied = true;
            newPriceEvent = {
              price: eligiblePackages[packageName],
              promocodeUsed: this.promoCode,
            };
        }
        } else {
          // course
          const { success, discountedPrice, error } = 
            await getDiscountedCoursePrice(this.courseItem.id, this.promoCode);
          if (!success) {
            this.handleError(error);
            return;
          }
          newPriceEvent = { price: discountedPrice, promocodeUsed: this.promoCode };
        }
        this.$emit('newPrice', newPriceEvent);
        this.message = 'You have applied the promo code ' + this.promoCode;
        this.loading = false;
      } catch (e) {
        this.message = e;
        this.loading = false;
      }
    },
    handleError(errMessage) {
      this.isErrorMessage = true;
      this.loading = false;
      this.promoCode = null;
      this.message = errMessage;
    },
    resetPromo() {
      this.isErrorMessage = false;
      this.message = null;
      this.promoCode = null;
      this.codeApplied = false;
      this.showHaveAPromoCodeText = true;
      this.$emit('newPrice', {
        price: this.packageItem.price,
        promocodeUsed: null,
      });
    },
  },
};
</script>

<style scoped>
#defaultDiv {
  display: flex;
  justify-content: flex-end;
  text-decoration: underline;
}

#promoCodeDiv {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

#empty-space {
  flex: 6;
}
#promoCodeField {
  flex: 3;
  margin-right: 3%;
}

#submitButton {
  flex: 1;
  text-align: end;
}

#messageDiv {
  display: flex;
  justify-content: center;
  margin-right: 2%;
}

.danger_text {
  color: red;
}
</style>
