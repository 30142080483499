<template>
  <v-card>
    <v-card-text>
      <p class="primary--text mb-1">Categories</p>

      <v-checkbox
        :key="`cat-${id}`"
        :label="name"
        v-for="{ id, name } in renderedCategories"
        class="black--text text-center"
        v-model="categoryFilter[id]"
        @change="categoryFilterChange"
      />

      <a v-if="categories.length > 10" @click="isExpand = !isExpand">
        <span class="mdi mdi-chevron-down"></span>
        {{!isExpand ? 'See more' : 'See less'}}
      </a>

      <p class="primary--text pt-4 mt-10 mb-1">Price</p>

      <v-checkbox
        :key="`price-${idx}`"
        :label="label"
        v-for="({ label }, idx) in priceRanges"
        class="black--text text-center"
        :value="priceFilter === idx"
        @change="priceFilterChecked(idx, $event)"
      />

      <v-row class="mt-4">
        <v-col cols="8">
          <v-row>
            <v-col cols="6">
              <v-text-field placeholder="Min" v-model="minPrice" />
            </v-col>
            <v-col cols="6">
              <v-text-field placeholder="Max" v-model="maxPrice" />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="4">
          <v-btn color="primary" :disabled="!minPrice && !maxPrice" @click="filterPrice">Go</v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { STUDIO_MODULE_NAME } from '@/store/moduleNames';
import { mapGetters } from 'vuex';
import { getCategories } from '@/util/cloudFunctions/merchandiseFunctions';

export default {
  data: () => ({
    categories: [],
    categoryFilter: {},
    priceFilter: null,
    minPrice: null,
    maxPrice: null,
    isExpand: false,

    priceRanges: [
      {
        label: 'Under $10',
        range: [null, 10],
      },
      {
        label: '$10 to $25',
        range: [10, 25],
      },
      {
        label: '$25 to $50',
        range: [25, 50],
      },
      {
        label: '$100 & Above',
        range: [100, null],
      },
    ],
  }),

  created() {
    this.fetchCategories();
  },

  methods: {
    async fetchCategories() {
      this.loading = true;
      const result = await getCategories(this.studio.id);
      if (!result.error) {
        this.categories = result.docs;
      }
      this.loading = false;
    },

    priceFilterChecked(idx, isChecked) {
      this.minPrice = null;
      this.maxPrice = null;
      this.priceFilter = isChecked ? idx : null;
      this.filterPrice();
    },

    filterPrice() {
      let filter = [];

      if (this.minPrice || this.maxPrice) {
        this.priceFilter = null;
        filter = [this.minPrice, this.maxPrice];
      } else if (this.priceFilter || this.priceFilter === 0) {
        filter = this.priceRanges[this.priceFilter].range;
      }

      this.$emit('priceFiltered', JSON.stringify(filter));
    },

    categoryFilterChange() {
      this.$emit(
        'categoryFiltered',
        JSON.stringify(
          Object.keys(this.categoryFilter).filter(
            (id) => this.categoryFilter[id],
          ),
        ),
      );
    },
  },

  computed: {
    ...mapGetters({
      studio: `${STUDIO_MODULE_NAME}/getStudio`,
    }),

    renderedCategories() {
      if (this.categories.length > 10)
        return this.isExpand ? this.categories : this.categories.slice(0, 10);

      return this.categories;
    },
  },
};
</script>
