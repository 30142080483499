<template>
  <v-card height="100%">
    <v-carousel height="200px">
      <v-carousel-item
        v-for="(image, i) in product.images"
        :key="i"
        :src="image"
      ></v-carousel-item>
    </v-carousel>

    <v-card-title>
      <div class="product-name">{{ product.name }}</div>
    </v-card-title>

    <v-card-subtitle>
      {{ product.category.name }}
    </v-card-subtitle>

    <v-card-text class="black--text">
      <p class="description">{{ product.description }}</p>
      <div class="d-flex justify-space-between">
        <div>
          <strong class="price">{{ `${studio.displayCurrency}${product.price}` }}</strong>
        </div>

        <div class="d-flex flex-column justify-space-between right-col">
          <v-btn
            ref="add-to-cart-btn"
            color="primary"
            @click="addToCart"
          >
            <span class="mdi mdi-plus"></span> Add to cart
          </v-btn>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { addToCart } from "@/util/cloudFunctions/shoppingCart";

import { ALERT_MODULE_NAME, STUDIO_MODULE_NAME } from "@/store/moduleNames";
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    product: Object,
  },
  methods: {
    ...mapActions({
      setAlertStateAction: `${ALERT_MODULE_NAME}/setAlertStateAction`,
    }),
    async addToCart() {
      const result = await addToCart(this.studio.id, this.product.id, 1);
      if (result.success) {
        this.setAlertStateAction({
          type: 'success',
          message: `Added ${this.product.name} to cart!`,
          timeout: 3000,
        });
        this.$emit("addToCart");
      } else {
        const error = result.error;
        console.error(error);
        this.setAlertStateAction({
          type: 'error',
          message: `Failed to add to cart - ${error}`,
        });
      }
    }
  },
  computed: {
    ...mapGetters({
      studio: `${STUDIO_MODULE_NAME}/getStudio`,
    }),
  },
};
</script>

<style scoped>
.product-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.price {
  line-height: 36px;
  display: block;
}

.description {
  position: relative;
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.description:after {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 34px;
  height: 20px;
  content: '';
  background: white;
  -webkit-mask-image: linear-gradient(to left, black, transparent);
}
</style>
