<template>
  <div>
    <v-row :class="rowStyle">
      <v-col>
        <v-tabs v-model="tabs" :centered="!isWidget">
          <v-tab>Classes</v-tab>
          <v-tab>Courses</v-tab>
        </v-tabs>
      </v-col>
      <v-col cols="4" v-if="isWidget">
        <UserAuth />
      </v-col>
    </v-row>
    
    <v-tabs-items v-model="tabs" class="my-5 px-5">
      <v-tab-item>
        <Classes class="mx-5" />
      </v-tab-item>
      <v-tab-item>
        <Courses class="mx-5" />
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import Classes from "./Classes";
import Courses from "./Courses";
import UserAuth from "@/components/auth/UserAuth";
import { WIDGET_ROUTE } from "@/constants";
import { mapGetters } from "vuex";
import { STUDIO_MODULE_NAME } from "@/store/moduleNames";


export default {
  components: {
    Classes,
    Courses,
    UserAuth,
  },
  data() {
    return {
      tabs: null,
    };
  },
  computed: {
    ...mapGetters({
      studio: `${STUDIO_MODULE_NAME}/getStudio`,
    }),
    isWidget() {
      return this.$route.hash === WIDGET_ROUTE;
    },
    rowStyle() {
      return this.isWidget ? "px-5 pt-3" : ""
    },
  },
  methods: {
    setDefaultTab() {
      this.tabs = this.studio.webAppTab == "courses" ? 1 : 0;
    },
  },
  created() {
    this.setDefaultTab();
  },
};
</script>