/// General utility functions for analytics.

/**
 * Substring to get first 100 characters from string even for strings with <100 characters.
 * @param {String} str
 */
function subStr(str) {
    return str.toString().substring(0, Math.min(str.length, 99));
}

export { subStr };