<template>
    <div>

        <!-- Sign Up Button -->
        <v-btn
            text
            :block="block"
            v-show="!hideButton"
            @click="openDialog"
        >
            Sign Up
        </v-btn>
        
        <v-dialog v-model="dialog" width="600px">

            <!-- Login Form -->
            <v-card>
                <v-card-title>
                    <v-col>
                        <v-row class="justify-end">
                            <v-icon @click="closeDialog">mdi-close</v-icon>
                        </v-row>
                        <v-row class="justify-center">
                            <h4 class="ml-2">Sign up</h4>
                        </v-row>
                    </v-col>
                </v-card-title>
                <v-card-text>
                    <SignUpForm :key="signUpFormKey" @success="onSuccess" />
                </v-card-text>
            </v-card>

        </v-dialog>
    </div>
    
</template>

<script>
import SignUpForm from "@/components/auth/SignUpForm";
import { mapActions } from "vuex";
import { ALERT_MODULE_NAME } from "@/store/moduleNames";
export default {
    props: ['block', 'hideButton'],
    components: {
        SignUpForm
    },
    data() {
        return {
            dialog: false,
            signUpFormKey: false
        }
    },
    methods: {
        ...mapActions({
            setAlertStateAction: `${ALERT_MODULE_NAME}/setAlertStateAction`,
        }),
        onSuccess() {
            this.closeDialog();
            const payload = {
                type: 'success',
                message: 'You have successfully created your account! Please verify your email to begin buying packages.'
            }
            this.setAlertStateAction(payload);
        },
        closeDialog() {
            this.dialog = false;
            this.toggleKey();
        },
        openDialog() {
            this.$emit("openDialog");
            this.toggleKey();
            this.dialog = true;
        },
        toggleKey() {
            this.signUpFormKey = !this.signUpFormKey;
        },
    }
}
</script>
