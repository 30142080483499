<template>
  <v-container class="py-10 px-5">
    <ProgressLoader v-if="loading" />
    <div v-if="!loading && !courseExists" class="course-error">
      <v-img
        :src="require('@/assets/search-error.gif')"
        alt="Course does not exist"
        max-width="400px"
        min-height="400px"
      />
      <div class="course-error-text">
        Course does not exist/has been deleted, please contact your studio.
      </div>
    </div>
    <v-col v-if="!loading && courseExists">
      <v-row class="top-border">
        <CourseIntroduction :courseObject="courseItem" />
      </v-row>
      <course-details :courseObject="courseItem" />
      <v-row justify="center">
        <v-btn v-if="isBookable" text x-large class="primary btn-style" @click="submit"
          >Book Course</v-btn
        >
     <v-btn v-else text class="btn-style-disabled" disabled
        >Book Course</v-btn
      >
       
      </v-row>
      <p class="text-center my-5"> {{reason}}</p>
      <CheckoutWindow
        ref="CheckoutWindow"
        :courseItem="courseItem"
        :isPurchaseCourse="true"
        :hideButton="true"
        :key="checkoutWindowKey"
        @closeDialog="reload"
        @error="handleError"
      />
    </v-col>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { getCourseByCourseId } from "@/util/queries/coursesQueries";
import { AUTHENTICATION_MODULE_NAME } from "@/store/moduleNames";
import { STUDIO_MODULE_NAME } from "@/store/moduleNames";
import CourseIntroduction from "@/components/bookcourse/CourseIntroduction.vue";
import ProgressLoader from "@/components/shared/ProgressLoader";
import CourseDetails from "../components/bookcourse/CourseDetails.vue";
import CheckoutWindow from "@/components/checkout/CheckoutWindow.vue";
export default {
  components: {
    CourseIntroduction,
    ProgressLoader,
    CourseDetails,
    CheckoutWindow,
  },
  data() {
    return {
      courseItem: {},
      error: "",
      isBookable: false,
      reason: "",
      loading: true,
      validationError: "",
      checkoutWindowKey: false,
      courseExists: false,
    };
  },
  watch: {
    $route: {
      handler(to, from) {
        if (to !== from) {
          this.setRequiredDataWithLoading();
        }
      },
      immediate: true,
    },
    isUserAuth: {
      handler() {
        this.setRequiredData();
      },
    },
  },
  computed: {
    ...mapGetters({
      user: `${AUTHENTICATION_MODULE_NAME}/getUser`,
      isUserAuth: `${AUTHENTICATION_MODULE_NAME}/isUserAuth`,
      studio: `${STUDIO_MODULE_NAME}/getStudio`,
    }),
    normalMemberships() {
      return this.studio.memberships;
    },
  },
  methods: {
    async setRequiredDataWithLoading() {
      this.loading = true;
      await this.setRequiredData();
      this.loading = false;
    },
    async setRequiredData() {
      const promises = [this.setCourses()];
      await Promise.all(promises);
    },
    async setCourses() {
      const courseId = this.$route.params.courseId;
      const result = await getCourseByCourseId(courseId);
      if (result.success) {
        this.courseItem = result.doc;
        this.isBookable = result.isBookable;
        this.reason = result.reason;
        this.courseExists = true;
      } else {
        this.error = result.error;
        this.courseExists = false;
      }
    },
    submit() {
      this.$refs.CheckoutWindow.openDialog();
    },
    async reload() {
      await this.setCourses();
      this.toggleKeys();
    },
    toggleKeys() {
      this.checkoutWindowKey = !this.checkoutWindowKey;
    },
    handleError(error) {
      this.validationError = error;
    },
  },
};
</script>

<style scoped>
.top-border {
  border-bottom: 0.2px solid #000;
}

.btn-style {
  width: inherit;
  height: 80px !important;
  margin-bottom: 10px;
  margin-top: 10px;
}

.btn-style-disabled {
  width: inherit;
  height: 80px !important;
  margin-bottom: 10px;
  margin-top: 10px;
  border: 1px solid rgb(196, 195, 195);
}

.book-spot-header {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  /* font-size: 45px; */
  line-height: 32px;
  /* identical to box height, or 71% */

  display: flex;
  align-items: center;
}

.error-text {
  display: flex;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;
  color: red;
}

.course-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.course-error-text {
  font-family: Roboto;
  font-size: 24px;
  text-align: center;
}
</style>
