export const mutationsAuth = {
    setLoading(state, payload) {
        state.loading = payload;
    },
    setCredits(state, payload) {
        state.credits = payload;
    },
    setUser(state, payload) {
        state.user = payload;
    },
    setCollectUserDetails(state, payload) {
        state.collectUserDetails = payload;
    },
};
  