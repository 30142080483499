<template>
  <div class="signup-page">
    <div class="text-container">
      <h1 class="signup-title">Your Wellness Journey Begins Here</h1>
      <p class="signup-body">
        With over 300 diverse fitness classes available across Singapore, you
        have the freedom to choose and embrace your favorite way to sweat.
        {{ desktopMessage }}
      </p>
    </div>
    <div class="form-container">
      <img src="@/assets/logo_transparent.png" class="logo" />
      <form @submit.prevent="submit">
        <ValidationObserver ref="observer">
          <div class="form-group">
            <label for="name">Name*</label>
            <ValidationProvider
              name="Name"
              rules="required|max:64"
              v-slot="{ errors }"
            >
              <input type="text" id="name" v-model="formData.name" />
              <span class="error--text">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label for="email">Personal Email*</label>
            <ValidationProvider
              name="Personal Email"
              rules="required|max:128|email"
              v-slot="{ errors }"
            >
              <input type="text" id="email" v-model="formData.personalEmail" />
              <span class="error--text">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>

          <div class="form-group">
            <label for="companyName">Company Name*</label>
            <ValidationProvider
              name="Company Name"
              rules="required|max:64"
              v-slot="{ errors }"
            >
              <input
                type="text"
                id="companyName"
                v-model="formData.companyName"
              />
              <span class="error--text">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <label for="company-email">Company Email</label>
            <ValidationProvider
              name="Company Email"
              rules="max:128|email"
              v-slot="{ errors }"
            >
              <input
                type="text"
                id="company-email"
                v-model="formData.companyEmail"
              />
              <span class="error--text">{{ errors[0] }}</span>
            </ValidationProvider>
          </div>
          <div class="form-group">
            <p class="error--text mx-5" ref="error"></p>
            <p class="success--text mx-5" ref="success"></p>
            <v-btn
              type="submit"
              class="primary submit-btn"
              large
              @click="submit"
              :loading="loading"
              >Sign Up</v-btn
            >
          </div>
        </ValidationObserver>
      </form>
    </div>
  </div>
</template>

<script>
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from "vee-validate";
import { required, email, max } from "vee-validate/dist/rules";
import applyCorporateMembership from "../util/cloudFunctions/corporateFunctions";

setInteractionMode("passive");
extend("required", {
  ...required,
  message: "{_field_} cannot be empty",
});
extend("max", {
  ...max,
  message: "{_field_} cannot not be greater than {length} characters",
});
extend("email", {
  ...email,
  message: "Email must be valid",
});
export default {
  components: {
    ValidationObserver,
    ValidationProvider,
  },
  computed: {
    desktopMessage() {
      return this.$vuetify.breakpoint.mdAndUp
        ? `Take
        the first step towards a fitter future, and let us help you unlock your
        full potential.`
        : "";
    },
  },
  data() {
    return {
      formData: {
        companyName: "",
        companyEmail: "",
        personalEmail: "",
        name: "",
      },
      loading: false,
    };
  },
  methods: {
    submit() {
      if (this.loading) {
        return;
      }
      return this.$refs.observer.validate().then(async (isValid) => {
        if (!isValid) {
          return;
        }
        console.log(this.companyEmail, this.companyName, this.personalEmail);
        this.loading = true;
        this.$refs.success.innerHTML = "";
        this.$refs.error.innerHTML = "";
        const res = await applyCorporateMembership(
          this.formData.name,
          this.formData.companyName,
          this.formData.personalEmail,
          this.formData.companyEmail
        );
        if (res.success) {
          this.$refs.success.innerHTML =
            "Success! You will receive a follow-up email to your email address within 2-3 business days on how to redeem your free package";
        } else {
          this.$refs.error.innerHTML = res.error;
        }
        this.loading = false;
      });
    },
  },
};
</script>

<style scoped>
.logo {
  display: block;
  max-height: 50px;
  width: auto;
  height: auto;
  margin-left: auto;
  margin-right: auto;
}
@font-face {
  font-family: "Raleway";
  src: local("Raleway"), url("../fonts/Raleway.ttf") format("truetype");
}
@font-face {
  font-family: "Lato";
  src: local("Lato"), url("../fonts/Lato.ttf") format("truetype");
}

.submit-btn {
  width: 100%;
  font-family: "Raleway";
  font-weight: 800;
  font-size: 18px;
}

.signup-page {
  background: url("https://images.unsplash.com/photo-1607962837359-5e7e89f86776?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1170&q=80")
    rgba(0, 0, 0, 0.6);
  background-size: cover;
  background-position: center;
  height: 100vh;
  background-blend-mode: multiply;
}

.form-container {
  width: 100%;
  padding: 20px;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 5px;
}

.signup-title {
  font-family: "Raleway";
  font-weight: 800;
  font-size: 24px; /* Adjust the font size as needed */
  letter-spacing: 2px; /* Adjust the letter spacing as needed */
  margin-bottom: 10px; /* Add some spacing between title and body */
  color: #fff;
  margin-top: 20px;
}

.signup-body {
  font-family: "Lato";
  font-weight: 1 00;
  color: #eeeeee;
  font-size: 18px; /* Adjust the font size as needed */
  letter-spacing: 1px; /* Adjust the letter spacing as needed */
}

@media (min-width: 768px) {
  .signup-page {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text-container {
    width: 50%;
    padding: 20px;
    color: #fff;
    border-radius: 5px;
    margin-right: 40px;
  }

  .form-container {
    width: 20%;
  }

  .signup-title {
    font-size: 36px;
  }
  .signup-body {
    font-size: 18px;
  }
}

h1,
h2 {
  text-align: center;
}

p {
  margin-bottom: 20px;
  text-align: center;
}

form {
  max-width: 100%;
}

.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-weight: bold;
}

input[type="text"],
input[type="email"] {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.form-container input[type="text"]:focus,
.form-container input[type="email"]:focus {
  border: 1px solid #8924ff !important;
}

button {
  color: white;
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
}
</style>
