<template>
  <div>
    <!-- Appbar -->
    <v-app-bar
      color="primaryMenu d-flex justify-center appbar"
      :height="appBarHeight"
    >
      <v-row class="global-container">
        <!-- Studio Logo -->
        <v-img
          src="@/assets/logo_only.png"
          class="hover"
          v-if="studio"
          :max-width="studioLogoSize"
          :max-height="studioLogoSize"
          @click="redirectHome"
        />

        <v-spacer />
        <!-- List of Navigation -->
        <v-col class="d-none d-sm-flex pa-0">
          <v-container class="text-center" fill-height>
            <v-col
              v-for="item in computedheaderNavigation"
              :key="item.name"
              :class="item.class"
            >
              <v-btn text link :to="item.link" active-class="primary">
                {{ item.name }}
              </v-btn>
            </v-col>
          </v-container>
        </v-col>

        <!-- User Profile / Signup Login -->
        <v-col class="d-flex user-width pa-0" v-if="isUserAuth">
          <v-container class="text-center pa-0" fill-height>
            <UserProfile :user="user" />
          </v-container>
        </v-col>

        <v-col class="d-none d-sm-flex user-width pa-0" v-if="!isUserAuth">
          <v-container class="text-center pa-0" fill-height>
            <SignUp />
          </v-container>
        </v-col>

        <v-col class="d-flex user-width pa-0 px-2 ml-auto" v-if="!isUserAuth">
          <v-container class="text-center pa-0" fill-height>
            <Login />
          </v-container>
        </v-col>
      </v-row>
    </v-app-bar>

    <!-- Change Password Dialog -->
    <ChangePassword
      ref="ChangePassword"
      :hideButton="true"
      @openDialog="closeDrawer()"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import SignUp from "@/components/auth/SignUp";
import Login from "@/components/auth/Login";

import UserProfile from "@/components/auth/UserProfile";
import ChangePassword from "@/components/auth/ChangePassword";
import {
  AUTHENTICATION_MODULE_NAME,
  STUDIO_MODULE_NAME,
} from "@/store/moduleNames";
export default {
  components: {
    SignUp,
    Login,

    UserProfile,
    ChangePassword,
  },
  computed: {
    appBarHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 60;
        case "sm":
          return 72;
        case "md":
        case "lg":
        case "xl":
          return 80;
      }
      return 60;
    },
    studioLogoSize() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 52;
        case "sm":
          return 60;
        case "md":
          return 65;
        case "lg":
        case "xl":
          return 70;
      }
      return 52;
    },
    ...mapGetters({
      user: `${AUTHENTICATION_MODULE_NAME}/getUser`,
      isUserAuth: `${AUTHENTICATION_MODULE_NAME}/isUserAuth`,
      studio: `${STUDIO_MODULE_NAME}/getStudio`,
    }),
    computedheaderNavigation() {
      if (this.isUserAuth) {
        return this.headerNavigation.concat(this.headerNavigationAuth);
      }
      return this.headerNavigation;
    },
  },
  methods: {
    changePassword() {
      this.$refs.ChangePassword.openDialog();
    },
    redirectHome() {
      this.$router.push("/");
    },
    closeDrawer() {
      this.drawer = false;
    },
  },
  data() {
    const studioRoute = this.$route.params.studioRoute;
    return {
      drawer: false,
      headerNavigation: [],
      headerNavigationAuth: [],
      drawerNavigation: [
        {
          link: `/${studioRoute}/classes`,
          icon: "mdi-calendar",
          name: "Browse Classes",
        },
      ],
    };
  },
};
</script>
<style scoped>
.item-width {
  width: 150px;
  flex-grow: 0;
}
@media screen and (min-width: 768px) {
  .item-width {
    width: 170px;
  }
}
.user-width {
  width: 200px;
  flex-grow: 0;
}
.hover {
  cursor: pointer;
}
</style>
