<template>
  <v-card
    class="white pb-6 pt-6 mb-1 mb-2"
    align-self-center="true"
    :to="isClickable ? classLink : ''"
    flat
    v-if="isMobileView"
  >
    <v-layout column>
      <v-flex>
        <v-card-text class="py-2"
          >{{ subItem.timeStart }} - {{ subItem.timeEnd }}</v-card-text
        >
      </v-flex>
      <v-flex>
        <v-card-text class="py-2 primary--text">{{ subItem.name }}</v-card-text>
          <v-chip color="primary" class="mx-2" outlined v-if="!bookBtn">{{subItem.creditsRequired}} Credits</v-chip>
      </v-flex>
      <v-flex>
        <v-card-text class="py-5" v-if="bookBtn">
          <v-icon class="mr-1">mdi-account-circle</v-icon>
          {{ subItem.instructor.name }}
        </v-card-text>
        <v-card-text v-else class="py-5 mx-0">
          <v-icon class="mx-0">mdi-dumbbell</v-icon>
          {{ subItem.studioName}}
        </v-card-text>
      </v-flex>
      <v-flex>
        <v-card-text class="py-2">
          <v-icon class="mr-1">mdi-map-marker</v-icon>
          {{ subItem.location }}
        </v-card-text>
      </v-flex>
      <v-flex xs12 class="mt-6" v-if="bookBtn">
        <v-btn
          v-if="isDisabled(subItem)"
          outlined
          text
          class="secondary text-capitalize"
          link
          :to="classLink"
          v-on:click.prevent
        >
          Add To Waitlist
        </v-btn>
        <v-btn
          v-else
          outlined
          text
          class="primary text-capitalize white--text"
          link
          :to="classLink"
        >
          Book Class
        </v-btn>
        <span
          v-if="!hideSpots"
          class="card-font ml-3 text--secondary font-italic"
        >
          {{ subItem.capacity - subItem.participantCount }}/{{
            subItem.capacity
          }}
          Available
        </span>
      </v-flex>
      <v-flex xs12 v-if="cancelWaitlistBtn">
        <RemoveClass :subItem="subItem" />
        <v-card-text class="mb-5 text--secondary font-italic"
          >{{ subItem.waitlistPosition }} position</v-card-text
        >
      </v-flex>
      <v-flex xs10 v-if="cancelUpcomingBtn" align-self-end>
        <CancelBooking :subItem="subItem" @success="successCallback" />
      </v-flex>
    </v-layout>
  </v-card>

  <v-card
    class="card-flex white mb-2 pr-2"
    align-self-center="true"
    flat
    width="100vw"
    height="15vh"
    :hover="isClickable"
    :to="isClickable ? classLink : ``"
    v-else
  >
    <v-layout class="pl-2 pr-2" align-content-center justify-center>
      <v-flex xs9 align-self-center>
        <v-card-text class="py-2"
          >{{ subItem.timeStart }} - {{ subItem.timeEnd }}</v-card-text
        >
      </v-flex>
      <v-flex xs12 align-self-center>
        <v-card-text class="py-2 primary--text">{{ subItem.name }}
          
        </v-card-text>
        <v-chip color="primary" class="mx-2" outlined v-if="!bookBtn">{{subItem.creditsRequired}} Credits</v-chip>
      </v-flex>
      <v-flex xs9 align-self-center>
        <v-card-text v-if="bookBtn" class="py-2">{{ subItem.instructor.name }}</v-card-text>
        <v-card-text v-else class="py-2">
          {{ subItem.studioName}}
        </v-card-text>
      </v-flex>
      <v-flex xs9 align-self-center>
        <v-card-text class="py-2">{{ subItem.location }}</v-card-text>
      </v-flex>
      <v-flex xs6 v-if="bookBtn" align-self-center>
        <v-btn
          v-if="isDisabled(subItem)"
          outlined
          text
          class="secondary card-center text-capitalize"
          link
          :to="classLink"
          v-on:click.prevent
        >
          Add To Waitlist
        </v-btn>
        <v-btn
          v-else
          outlined
          text
          class="primary card-center text-capitalize white--text"
          link
          :to="classLink"
        >
          Book Class
        </v-btn>
        <v-card-text class="card-font text--secondary font-italic">
          <span v-if="!hideSpots">
            {{ subItem.capacity - subItem.participantCount }}/{{
              subItem.capacity
            }}
            Available</span
          >
        </v-card-text>
      </v-flex>
      <v-flex xs6 v-if="cancelWaitlistBtn" align-self-center>
        <RemoveClass :subItem="subItem" />
        <v-card-text class="card-font ml-3 text--secondary font-italic">
          {{ subItem.waitlistPosition }} position
        </v-card-text>
      </v-flex>
      <v-flex
        xs6
        v-if="cancelUpcomingBtn && !subItem.courseName"
        align-self-center
      >
        <CancelBooking :subItem="subItem" @success="successCallback" />
        <v-card-text class="card-font-cancel ml-3 text--secondary font-italic">
        </v-card-text>
      </v-flex>
    </v-layout>
    <SuccessDialog ref="successDialog" message="Your booking has been cancelled" />
  </v-card>
</template>

<script>
import RemoveClass from "@/components/myclasses/RemoveClass.vue";
import CancelBooking from "@/components/myclasses/CancelBooking.vue";
import SuccessDialog from "@/components/shared/SuccessDialog";
import { GLOBAL_MODULE_NAME } from "@/store/moduleNames";
import { mapGetters } from "vuex";

export default {
  props: {
    subItem: Object,
    isRoot: {
      type: Boolean,
      default: false,
    },
    isClickable: {
      type: Boolean,
      default: false,
    },
    bookBtn: {
      type: Boolean,
      default: true,
    },
    cancelWaitlistBtn: {
      type: Boolean,
      default: false,
    },
    cancelUpcomingBtn: {
      type: Boolean,
      default: false,
    },
    isMobileView: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    RemoveClass,
    CancelBooking,
    SuccessDialog,
  },
  computed: {
    ...mapGetters({
      studiosGlobal: `${GLOBAL_MODULE_NAME}/allStudios`,
    }),
    hideSpots() {
      return this.subItem.hideSpots === true;
    },
    classLink() {
      const studio = this.studiosGlobal.find((studio) => studio.studioId === this.subItem.studioId);
      if (!studio && this.isRoot) {
        return;
      }
      let route;
      const hash = this.$route.hash;
      route = this.subItem.courseOfferingId
        ? `courses/${this.subItem.courseOfferingId}${hash}`
        : `classes/${this.subItem.classId || this.subItem.id}${hash}`;
        return `/${studio.webAppRoute}/${route}`;
    },
  },
  methods: {
    successCallback() {
      this.$refs.successDialog.openDialog();
      this.$emit('successCancel');
    },
    isDisabled(item) {
      return item.isFull || item.participantCount === item.capacity;
    },
  },
};
</script>

<style scoped>
.card-center {
  margin-bottom: -10px !important;
  margin-top: 40px;
  margin-left: 5px;
}

.card-font {
  font-size: 13px;
}

.card-font-cancel {
  font-size: 13px;
  margin-top: 10px;
}

.card-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
</style>
