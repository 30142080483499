<template>
    <body>
        <div class="body">
            <v-sheet elevation="2" class="px-1">
                <v-col>
                    <v-row align="center" no-gutters>
                        <v-img src="@/assets/logo_transparent.png" max-width="150px"></v-img>
                        
                        <GeneralSearch :studios="originalStudios" class="d-none d-md-block ml-3" />
                        <LocationSearch class="d-none d-md-block" @search="searchBasedOnLocation" />
                        
                        <v-spacer></v-spacer>
                        <div class="userAuth">
                            <UserAuth :hideRedirect="true" />
                        </div>
                    </v-row>
    
                    <v-row align="center" no-gutters class="mt-3 mb-5 d-flex d-md-none">
                        <GeneralSearch :studios="originalStudios" />
                        <LocationSearch class="mt-3 mt-sm-0" @search="searchBasedOnLocation" />
                    </v-row>
                    
                    <ul class="filtersContainer px-0">
                        <li class="filter" v-show="isClassesTab">
                            <DatePicker
                                v-model="date"
                                solo 
                                dense
                                rounded
                                hideDetails
                                :min="getTodaysDate()"
                            />
                        </li>
                        <li class="filter timeFilter" v-show="isClassesTab">
                            <TimeSlider
                                v-model="timeRange"
                                solo 
                                dense
                                rounded
                                hideDetails
                            />
                        </li>
                        
                        <li class="filter">
                            <ActivityFilter @update="updateActivityFilter" />
                        </li>
                        <li class="filter">
                            <AmenitiesFilter @update="updateAmenitiesFilter" />
                        </li>
                    </ul>
    
                </v-col>
            </v-sheet>
            
            <main class="studiosWrapper">
                <div class="list">
                     <v-tabs height="50px" v-model="tab" class="py-0 my-0">
                        <v-tab class="text-capitalize">Classes</v-tab>
                        <v-tab class="text-capitalize">Studios</v-tab>
                    </v-tabs>
                    <div class="listScrollWrapper">
                        <div>
                            <div v-if="loading">
                                <v-skeleton-loader v-for="index in 5" :key="index" type="card" height="150px" class="my-3 mx-3"></v-skeleton-loader>
                            </div>
                            <v-tabs-items v-model="tab">
                                <v-tab-item>
                                    <ClassesList :studios="studios" :date="date" :timeRange="timeRange" />
                                </v-tab-item>
                                <v-tab-item>
                                    <div v-for="(studio, index) of studios" :key="index">
                                        <NewStudioCard :studio="studio" class="py-3" />
                                        <v-divider></v-divider>
                                    </div>
                                </v-tab-item>
                            </v-tabs-items>
                            
                        </div>
                    </div>
                </div>
                <div class="map mt-1 d-none d-lg-block">
                    <GoogleMaps :studios="studios" :center="center" @update="updateStudios" />
                </div>
            </main>
        </div>
        
    </body>
</template>

<script>
import UserAuth from "@/components/auth/UserAuth";
import NewStudioCard from "@/components/home/NewStudioCard";
import GeneralSearch from "@/components/home/GeneralSearch";
import LocationSearch from "@/components/home/LocationSearch";
import GoogleMaps from "@/components/home/GoogleMaps";
import ActivityFilter from "@/components/home/ActivityFilter";
import AmenitiesFilter from "@/components/home/AmenitiesFilter";
import ClassesList from "@/components/home/ClassesList";
import DatePicker from "@/components/shared/DatePicker";
import TimeSlider from "@/components/shared/TimeSlider";
import { GLOBAL_MODULE_NAME } from "@/store/moduleNames";
import { haversine_distance } from "@/util/maps/distance";
import {  mapGetters } from "vuex";
export default {
    components: {
    NewStudioCard,
    UserAuth,
    GeneralSearch,
    LocationSearch,
    GoogleMaps,
    ActivityFilter,
    AmenitiesFilter,
    ClassesList,
    DatePicker,
    TimeSlider,
},
    data() {
        return {
            studios: [],
            originalStudios: [],
            loading: true,
            search: "",
            averageRatingWeight: 0.7,
            numberReviewsWeight: 0.3,
            markers: [],
            center: { lat: 1.352083, lng: 103.819836 },
            distanceCutOff: 20, // distance cut off between center and the studio
            activitiesFilter: [],
            amenitiesFilter: [],
            tab: 1,
            date: "",
            timeRange: ["00:00", "23:30"],
        };
    },
    created() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    this.center = pos;
                    this.initStudios();
                },
                () => {
                    console.error("user did not allow");
                }
            );
            } else {
            // Browser doesn't support Geolocation
            console.error("browser does not support geolocation");
        } 
        this.initData();
    },
    computed: {
         ...mapGetters({
            studiosGlobal: `${GLOBAL_MODULE_NAME}/allStudios`
         }),
        tagLine() {
            return "Where fitness communities are built";
        },
        isClassesTab() {
            return this.tab == 0;
        },
    },
    methods: {
        getTodaysDate() {
            return new Date().toISOString().substring(0, 10);
        },
        async initData() {
            const studios = this.studiosGlobal;
            const studiosLocations = [];
            for (const studio of studios) {
                for (const location of studio.locations) {
                    studiosLocations.push({ ...studio, location });
                }
            }
            const studiosWithShowDescription = studiosLocations.map(studio => ({ ...studio, showDescription: false }));
            this.originalStudios = studiosWithShowDescription;
            this.initStudios();
            this.loading = false;
        },
        initStudios(bounds) {
            const filteredStudios = this.filterStudios(this.originalStudios, bounds);
            this.studios = this.orderStudios(filteredStudios);
        },
        filterStudios(studios, bounds) {
            return studios.filter(studio => {
                const coordinates = studio.location.coordinates;
                if (coordinates && coordinates.latitude && coordinates.longitude && bounds) {
                    const position = { lat: coordinates.latitude, lng: coordinates.longitude };
                    return bounds.contains(position);
                }
                return true; 
            }).filter(studio => {
                if (this.activitiesFilter.length == 0) {
                    return true;
                }
                if (studio.category) {
                    return studio.category.some(cat => this.activitiesFilter.includes(cat));
                }
                return false; // studio does not have a category that belong to the requested query
            }).filter(studio => {
                if (this.amenitiesFilter.length == 0) {
                    return true;
                }
                return this.amenitiesFilter.every(amenity => studio.location.amenities.includes(amenity));
            })
        },
        orderStudios(studios) {
            return studios
                .sort((x, y) => {
                    // sort by reviews
                    return this.calculateStudioScore(y) - this.calculateStudioScore(x);
                }).sort((x, y) => {
                    // sort by distance
                    const yCoordinates = y.location.coordinates;
                    const xCoordinates = x.location.coordinates;
                    if (yCoordinates && yCoordinates.latitude && yCoordinates.longitude && xCoordinates && xCoordinates.latitude && xCoordinates.longitude) {
                        const yPosition = { lat: yCoordinates.latitude, lng: yCoordinates.longitude };
                        const xPosition = { lat: xCoordinates.latitude, lng: xCoordinates.longitude };
                        return haversine_distance(xPosition, this.center) - haversine_distance(yPosition, this.center); 
                    }

                    if (yCoordinates && yCoordinates.latitude && yCoordinates.longitude) {
                        return 1;
                    }

                    if (xCoordinates && xCoordinates.latitude && xCoordinates.longitude) {
                        return -1;
                    }

                    return 0;
                });
        },
        calculateStudioScore(studio) {
            if (studio.averageRating && studio.numberReviews) {
                return studio.averageRating * this.averageRatingWeight + studio.numberReviews * this.numberReviewsWeight;
            }
            return 0;
        },
        searchBasedOnLocation(position) {
            this.center = position;
            this.initStudios();
        },
        updateActivityFilter(activities) {
            this.activitiesFilter = activities;
            this.initStudios();
        },
        updateAmenitiesFilter(amenities) {
            this.amenitiesFilter = amenities;
            this.initStudios();
        },
        updateStudios(bounds) {
            this.center = {lat: bounds.getCenter().lat(), lng: bounds.getCenter().lng()};
            this.initStudios(bounds);
        },
    },
};
</script>

<style scoped>
body {
    min-height: 100%;
    background-color: #fff;
}


@media screen and (max-width: 768px) {
    .body {
        overflow: hidden;
    }
}

.body {
    height: 100vh;
    min-height: 100vh;
    flex-direction: column;
    display: flex;
}

.userAuth {
    max-width: 300px;
}

.filtersContainer {
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    list-style-type: none;
    padding-bottom: 16px;
    padding-top: 16px;
}

.filter {
    margin-left: 4px;
    margin-right: 16px;
    width: 140px;
    display: inline-block;
}

.timeFilter {
    width: 200px !important;
}

.studiosWrapper {
    display: flex;
    flex: auto;
    overflow: hidden;
    flex-direction: row;
}

.list {
    display: flex;
    flex-direction: column;
    width: 850px;
    height: 100%;
    padding: 16px;
    background-color:#e4e9ec;

    @media screen and (max-width: 1279px)   {
        width: 100%;
    }
}

.listScrollWrapper {
    height: 100%;
    overflow-y: scroll;
    min-height: 146px;
}

.map {
    position: relative;
    z-index: 0;
    background-color: #fff;
    flex: 1;
    display: block;
}

</style>