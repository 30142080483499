<template>
  <div id="stripe-div">
    <v-container>
      
      <v-fade-transition>
        <BlockingLoader text="Creating your secure payment session..." v-model="loading" />
      </v-fade-transition>

      <v-row class="justify-center mb-5" no-gutters>
        <v-icon>mdi-lock</v-icon>
        <h4 class="ml-2">Complete Payment</h4>
      </v-row>

      <v-col align="center">
        <v-img
          :src="require('@/assets/credit-card.png')"
          max-width="400px"
          min-height="200px"
        />
      </v-col>

      <p class="error--text mt-3">{{ error }}</p>

      <v-btn 
        v-if="hasOpenedCheckoutSession"
        :class="{ 'disable-events': success || purchaseStatusLoading }"
        block
        :color="submitButtonColor"
        :loading="purchaseStatusLoading"
        @click="getPurchaseStatus"
      >
        <v-icon>mdi-success</v-icon>
        Continue
      </v-btn>

      <v-btn
        v-else
        :class="{ 'disable-events': success || loading }"
        block
        :color="submitButtonColor"
        :loading="loading"
        @click="openCheckoutSession"
      >
        <template v-slot:loader>
          <v-progress-circular indeterminate size="20" />&nbsp;
          <span>Creating checkout session...</span>
        </template>
        <span>
          <span v-if="!hasDiscount">
            Pay {{ price }} 
          </span>
          <span v-else
            >Pay
            <span id="originalPriceText">
              {{ price }} </span
            >{{ studio.displayCurrency + newPrice }}
          </span>
          <v-icon right>mdi-open-in-new</v-icon>
        </span>
      </v-btn>

    </v-container>

    <PromoCode
      v-if="newPrice != 0"
      id="promo"
      :packageItem="packageItem"
      :courseItem="courseItem"
      @newPrice="updatePrice"
      :studio="studio"
    />

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { STUDIO_MODULE_NAME } from '@/store/moduleNames';
import { createCheckoutSessionForPackagePurchase, createCheckoutSessionForCoursePurchase, retrieveCheckoutSessionStatus } from "@/util/cloudFunctions/stripe";

// Analytics
import {
  PURCHASE,
  PURCHASE_ERROR,
  PURCHASE_COURSE,
  WEBAPP_PURCHASE_COURSE,
  PURCHASE_COURSE_ERROR,
  WEBAPP_PURCHASE_COURSE_ERROR,
  WEBAPP_PURCHASE,
  WEBAPP_PURCHASE_ERROR,
} from '@/analytics/events';
import { subStr } from '@/analytics/util';
import PromoCode from '../checkout/PromoCode.vue';
import BlockingLoader from "@/components/shared/BlockingLoader.vue";

export default {
  components: {
    PromoCode,
    BlockingLoader,
  },
  props: {
    packageItem: Object,
    classId: String,
    spotNumber: Number,
    isPurchaseCourse: {
      default: false,
      type: Boolean,
    },
    courseItem: Object,
  },
  created() {
    this.createCheckoutSession();
  },
  data() {
    return {
      loading: false,
      error: '',
      success: false,
      newPrice: this.isPurchaseCourse ? this.courseItem.price : this.packageItem.price,
      promocodeUsed: null,
      url: '',
      sessionId: '',
      purchaseStatusLoading: false,
      hasOpenedCheckoutSession: false,
      itemId: '', // either packageId or courseOfferingId
    };
  },
  computed: {
    ...mapGetters({
      studio: `${STUDIO_MODULE_NAME}/getStudio`,
    }),
    stripeAccountId() {
      return this.studio.stripeAccountId;
    },
    price() {
      if (this.isPurchaseCourse) {
        return `${this.studio.displayCurrency}${this.courseItem.price}`
      } else {
        return `${this.studio.displayCurrency}${this.packageItem.price}` 
      }
    },
    hasDiscount() {
      const originalPrice = this.isPurchaseCourse
        ? this.courseItem.price 
        : this.packageItem.price;
      return originalPrice != this.newPrice;
    },
    submitButtonColor() {
      if (this.loading) {
        return 'grey';
      }
      if (this.success) {
        return 'success';
      }
      return 'primary';
    },
    isSubscription() {
      return !this.isPurchaseCourse && this.packageItem.id;
    }
  },
  methods: {
    updatePrice(event) {
      this.newPrice = event.price;
      this.promocodeUsed = event.promocodeUsed;
      this.createCheckoutSession();
    },
    logSuccess() {
      if (this.isPurchaseCourse) {
        this.logSuccessPurchaseCourse();
        return;
      }
      const parameters = {
        item_id: this.studio.id,
        item_name: this.studio.studioName,
        quantity: 1,
        price: this.newPrice,
        currency: this.studio.currency ?? 'SGD',
        value: this.newPrice,
        studioId: this.studio.id,
        studioName: this.studio.studioName,
        packageId: this.itemId,
        packageName: this.packageItem.name,
      };
      this.$gtag.event(PURCHASE, parameters);
      this.$gtag.event(WEBAPP_PURCHASE, parameters);
    },
    logSuccessPurchaseCourse() {
      const parameters = {
        item_id: this.studio.id,
        item_name: this.studio.studioName,
        quantity: 1,
        price: this.newPrice,
        currency: this.studio.currency ?? "SGD",
        value: this.newPrice,
        studioId: this.studio.id,
        studioName: this.studio.studioName,
        courseId: this.itemId,
        courseName: this.courseItem.courseName,
      };
      this.$gtag.event(PURCHASE_COURSE, parameters);
      this.$gtag.event(WEBAPP_PURCHASE_COURSE, parameters);
    },
    logError(error) {
      this.error = error;
      this.loading = false;
      if (this.isPurchaseCourse) {
        this.$gtag.event(PURCHASE_COURSE_ERROR, { errorMessage: subStr(this.error) });
        this.$gtag.event(WEBAPP_PURCHASE_COURSE_ERROR, { errorMessage: subStr(this.error) });
        return;
      }
      this.$gtag.event(PURCHASE_ERROR, { errorMessage: subStr(this.error) });
      this.$gtag.event(WEBAPP_PURCHASE_ERROR, { errorMessage: subStr(this.error) });
      return;
    },
    emitSuccess() {
      this.$emit('success', this.itemId);
    },
    async createCheckoutSession() {
      this.loading = true;
      const baseUrl = window.location.href.split('?')[0];
      const result = this.isPurchaseCourse
        ? await createCheckoutSessionForCoursePurchase(this.courseItem.id, this.promocodeUsed, baseUrl)
        : await createCheckoutSessionForPackagePurchase(
          this.studio.id, this.packageItem.name, this.packageItem.id, this.promocodeUsed, baseUrl, this.classId, this.spotNumber);
      if (result.success) {
        // Setting itemId
        if (this.isPurchaseCourse) {
          this.itemId = result.courseOfferingId;
        } else {
          this.itemId = result.packageId;
        }
        // Account for Free Packages
        if (result.isFree) {
          this.loading = false;
          this.success = true;
          this.logSuccess();
          this.emitSuccess();
          return;
        }
        this.sessionId = result.sessionId;
        this.url = result.sessionUrl;
      } else {
        this.logError(result.error);
      }
      this.loading = false;
    },
    openCheckoutSession() {
      window.open(this.url, '_blank');
      this.hasOpenedCheckoutSession = true;
    },
    async getPurchaseStatus() {
      this.purchaseStatusLoading = true;
      const result = await retrieveCheckoutSessionStatus(this.sessionId, this.stripeAccountId);
      this.success = result.success;
      if (this.success) {
        this.logSuccess();
        this.emitSuccess();
      } else {
        this.error = "Please complete your payment to proceed. If you have completed your payment and are unable to proceed, please send an email to contact@vibefam.com";
        this.openCheckoutSession();
      }
      this.purchaseStatusLoading = false;
    },
  },
};
</script>
<style scoped>
.disable-events {
  pointer-events: none;
}

#originalPriceText {
  text-decoration: line-through;
}

#stripe-div {
  display: flex;
  flex-direction: column;
}

#promo {
  justify-content: flex-end;
  padding: 12px;
}
</style>
