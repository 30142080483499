<template>
    <v-col>
        <v-row class="justify-center mb-5" no-gutters>
        <h3 class="ml-2">Purchase Agreement</h3>
        </v-row>

        <v-col class="purchase-agreement-container">
        <div v-html="html" />
        <v-checkbox
            class="black--text text-center"
            label="I Agree"
            v-model="hasAgreed"
        ></v-checkbox>
        </v-col>

        <v-row no-gutters class="pt-3 justify-center">
        <v-btn :disabled="!hasAgreed" color="primary" @click="emitSuccess"
            >Proceed to Payment</v-btn
        >
        </v-row>
    </v-col>
</template>
<script>
export default {
    props: {
        html: String,
    },
    data() {
        return {
        hasAgreed: false,
        };
    },
    methods: {
        emitSuccess() {
        this.$emit("success");
        },
    },
};
</script>
<style scoped>
.purchase-agreement-container {
  max-height: 400px;
  overflow-y: scroll;

  @media screen and (min-width: 600px) {
    max-height: 500px;
  }
}
</style>
