<template>
  <v-row>
    <v-col v-if="isUserAuth" align="end">
      <UserProfile :user="user" :hideRedirect="hideRedirect" />
    </v-col>
    <v-row class="ma-0" v-if="!isUserAuth">
      <v-col>
        <SignUp class="d-none d-sm-block" block />
      </v-col>
      <v-col>
        <Login block />
      </v-col>
    </v-row>
  </v-row>
</template>
<script>
import SignUp from "@/components/auth/SignUp";
import Login from "@/components/auth/Login";
import UserProfile from "@/components/auth/UserProfile";
import { mapGetters } from "vuex";
import { AUTHENTICATION_MODULE_NAME } from "@/store/moduleNames";

export default {
  props: {
    hideRedirect: Boolean,
  },
  components: {
    UserProfile,
    SignUp,
    Login,
  },
  computed: {
    ...mapGetters({
        user: `${AUTHENTICATION_MODULE_NAME}/getUser`,
        isUserAuth: `${AUTHENTICATION_MODULE_NAME}/isUserAuth`,
    }),
  },
}
</script>