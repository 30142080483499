import { gettersAlert } from "@/store/moduleComponents/alert/gettersAlert";
import { actionsAlert } from "@/store/moduleComponents/alert/actionsAlert";
import { mutationsAlert } from "@/store/moduleComponents/alert/mutationsAlert";

export const alertModule = {
    namespaced: true,
    state: { 
        type: "success",
        message: "",
        isActive: false
    },
    getters: gettersAlert,
    actions: actionsAlert,
    mutations: mutationsAlert
}