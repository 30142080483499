
export const actionsAlert = {
    setAlertStateAction({ commit }, payload) {
        const { timeout } = payload;
        commit('setAlertState', payload);
        if (timeout) {
            setTimeout(() => commit('clearAlertState'), timeout);
        }
    },
}
