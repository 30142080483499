<template>
    <v-col>
        <UserAuth v-if="isWidget" />

        <p class="text-center text--secondary my-3" v-if="isUserAuth && !isEmailVerified">
            <v-icon>mdi-alert</v-icon>
            You cannot purchase a package until you have verified your email.
            <a class="font-weight-bold text-decoration-underline text--secondary text-no-wrap" @click="sendVerificationEmail">Resend Verification email</a>
        </p>

        <p class="text-center text--secondary my-3">
            {{ `The deadline to cancel your class without any penalty is ${studio.refundPolicy} hours before class starts` }}
        </p>

        <PurchaseStatus />

        <!-- Loading Skeleton -->
        <v-row v-if="loading">
            <v-col cols="12" md="6" v-for="i in 3" :key="i" >
                <v-skeleton-loader class="mt-3" type="card" />
            </v-col>
        </v-row>

        <v-scroll-y-transition>
            <PackageList v-show="!loading" :packages="packages" :recurringPackages="recurringPackages" />
        </v-scroll-y-transition>

    </v-col>
</template>

<script>
import PackageList from "@/components/packages/PackageList";
import PurchaseStatus from "@/components/stripe/PurchaseStatus";
import UserAuth from "@/components/auth/UserAuth.vue";
import { mapGetters, mapActions } from "vuex";
import { ALERT_MODULE_NAME, STUDIO_MODULE_NAME, AUTHENTICATION_MODULE_NAME } from "@/store/moduleNames";
import { getNormalPackages, getRecurringPackages } from "@/util/cloudFunctions/packagesFunctions";
import getAuthErrorMessage from "@/util/authErrors";
import { auth, sendEmailVerification } from "@/firebase";
import { WIDGET_ROUTE } from "@/constants";

export default {
    components: {
        PackageList,
        PurchaseStatus,
        UserAuth,
    },
    data() {
        return {
            loading: true,
            packages: [],
            recurringPackages: [],
            canSendVerificationEmail: true, 
            sendVerificationEmailTimeout: 10000 // 10 seconds buffer, to avoid sending too many emails
        }
    },
    computed: {
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
            isUserAuth: `${AUTHENTICATION_MODULE_NAME}/isUserAuth`
        }),
        isEmailVerified() {
            return true;
        },
        isWidget() {
            return this.$route.hash === WIDGET_ROUTE;
        },
    },
    methods: {
        ...mapActions({
            setAlertStateAction: `${ALERT_MODULE_NAME}/setAlertStateAction`,
        }),
        async setPackages() {
            this.loading = true;
            await Promise.all([this.setNormalPackages(), this.setRecurringPackages()])
            this.loading = false;
        },
        async setNormalPackages() {
            const result = await getNormalPackages(this.studio.id);
            if (result.success) {
                this.packages = result.memberships;
            }
        },
        async setRecurringPackages() {
            const result = await getRecurringPackages(this.studio.id);
            if (result.success) {
                this.recurringPackages = result.memberships;
            }
        },
        async sendVerificationEmail() {
            if (!this.canSendVerificationEmail) {
                return;
            }

            this.canSendVerificationEmail = false;
            let payload = {};
            await sendEmailVerification(auth.currentUser)
                .then(() => {
                    payload = {
                        type: 'success',
                        message: 'Your verification email has been successfully sent to your email!'
                    }
                })
                .catch((error) => {
                    payload = {
                        type: 'error',
                        message: getAuthErrorMessage(error.code)
                    }
                });
            this.setAlertStateAction(payload);
            setTimeout(() => {
                this.canSendVerificationEmail = true;
            }, this.sendVerificationEmailTimeout);
        }
    },
    created() {
        this.setPackages();
    }
};
</script>
