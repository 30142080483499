<template>
<div class="card-center">
    <v-btn
        outlined
        text
        class="text-capitalize text--secondary"
        @click="openWaitlistDialog(subItem)"
        v-on:click.prevent
    >
        Remove Class
    </v-btn>
    <WarningDialog
        :showDialog="waitlistDialog"
        title="Remove Class From Waitlist"
        :content="waitlistDialogContent"
        :cancelCallback="closeWaitlistDialog"
        :confirmCallback="removeWaitlist"
        :errorMessage="waitlistError"
        :loading="waitlistLoading"
    >
    </WarningDialog>
</div>
</template>

<script>
import WarningDialog from "@/components/shared/WarningDialog";
import {
  removeWaitlistClass,
} from "@/util/cloudFunctions/classesFunctions";
import { CANCEL_WAITLIST, CANCEL_WAITLIST_ERROR } from "@/analytics/events";
import { subStr } from "@/analytics/util";

export default {
    props: {
        subItem: Object,
    },
    components: {
        WarningDialog
    },
    data() {
        return {
            waitlistDialog: false,
            removeItem: {},
            waitlistError: "",
            waitlistLoading: false,
        }
    },
    computed: {
        waitlistDialogContent() {
            return `You are about to remove this class from your waitlist. 
                There will be an email sent to you to confirm the action.`;
        },
    },
    methods: {
        openWaitlistDialog(item) {
            this.waitlistDialog = true;
            this.removeItem = item;
        },
        closeWaitlistDialog() {
            this.waitlistDialog = false;
        },
        async removeWaitlist() {
            this.waitlistLoading = true;
            const data = {
                waitlistId: this.removeItem.id,
            };
            const result = await removeWaitlistClass(data);
            if (result.success) {
                this.$gtag.event(CANCEL_WAITLIST);
                this.$emit("success");
                this.closeWaitlistDialog();
            } else {
                this.$gtag.event(CANCEL_WAITLIST_ERROR, { errorMessage: subStr(result.error) });
                this.waitlistError = result.error;
            }
            this.waitlistLoading = false;
        },
    }
    
}
</script>


<style scoped>
.card-center {
  margin-bottom: -10px !important;
  margin-top: 40px;
  margin-left: 5px;
}
</style>