<template>
    <ValidationObserver ref="observer">
        <v-form ref="form">

            <!-- Email Field -->
            <ValidationProvider
                v-slot="{ errors }"
                name="Email"
                rules="required|max:64|email"
            >
                <text-trimmer
                    label="Email"
                    v-model="email"
                    prepend-icon="mdi-account-outline"
                    :error-messages="errors"
                ></text-trimmer>
            </ValidationProvider>

            <p class="error--text text-center py-3" v-if="error !== ''">{{ error }}</p>

            <p class="success--text text-center py-3" v-if="success !== ''">{{ success }}</p>

            <v-row justify="center" class="my-3">
                <v-btn
                    class="primary"
                    @click="submit"
                    :loading="loading"
                    depressed
                >
                    Forget Password
                </v-btn>
            </v-row>

        </v-form>
    </ValidationObserver>
</template>

<script>
import { auth, sendPasswordResetEmail } from '@/firebase';
import TextTrimmer from "@/components/shared/TextTrimmer.vue";
import {
  extend,
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from "vee-validate";
import { required, max } from "vee-validate/dist/rules";
import { fetchSignInMethod } from "@/util/loginProviders";
import getAuthErrorMessage from "@/util/authErrors";
extend("required", {
  ...required,
  message: "{_field_} cannot be empty",
});
extend("max", {
  ...max,
  message: "{_field_} cannot not be greater than {length} characters",
});
setInteractionMode("eager"); //eager form validation

// Analytics
import { FORGET_PASSWORD, FORGET_PASSWORD_ERROR } from "@/analytics/events";
import { subStr } from "@/analytics/util";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        TextTrimmer
    },
    data() {
        return {
            password: "",
            email: "",
            success: "",
            error: "",
            loading: false,
            passwordToggle: true,
        }
    },
    methods: {
        async validate() {
            return await this.$refs.observer.validate();
        },
        async isPasswordSignInMethod() {
            const signInMethod = await fetchSignInMethod(this.email);

            if (signInMethod !== "password") {
                this.error = `Please login with ${signInMethod} instead`;
                return false;
            }

            return true;
        },
        async submit() {
            this.success = "";
            this.error = "";
            this.loading = true;

            const isValid = await this.validate();
            const isPasswordSignIn = await this.isPasswordSignInMethod();

            if (!isValid || !isPasswordSignIn) {
                this.loading = false;
                return;
            }
            
            sendPasswordResetEmail(auth, this.email)
                .then(() => {
                    this.$gtag.event(FORGET_PASSWORD);
                    this.success = "An email to reset your password has been sent!";
                })
                .catch((error) => {
                    this.$gtag.event(FORGET_PASSWORD_ERROR, { errorMessage: subStr(error.code) });
                    this.error = getAuthErrorMessage(error.code);
                })
                .finally(() => this.loading = false);

        },
    }
}

</script>
