<template>
    <v-select 
        solo
        dense
        rounded
        label="Activities"
        multiple
        hide-details
        :items="items"
        v-model="selected"
    ></v-select>
</template>
<script>
export default {
    data() {
        return {
            items: [
                { text: "Martial Arts", value: "martialArts" },
                { text: "Dance", value: "dance" },
                { text: "Pilates", value: "pilates" },
                { text: "Strength", value: "strength" },
                { text: "Wellness", value: "wellness" },
                { text: "Yoga", value: "yoga" },
            ],
            selected: [],
        }
    },
    methods: {
        onSelected() {
            this.$emit("update", this.selected);
        }
    },
    watch: {
        selected() {
            this.onSelected();
        },
    },
}
</script>
