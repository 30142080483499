<template>
  <div>
    <v-col>
      <div class="carouselContainer">
        <v-carousel :height="carouselHeight">
          <v-carousel-item
            v-for="(img, index) of carouselImages"
            :key="index"
            :src="img"
          ></v-carousel-item>
        </v-carousel>
      </div>
      <h1 class="font-weight-medium mt-3">
        {{ studio.studioName }}
      </h1>
      <div class="studioCategory text-ellipsis">
        {{ studioCategory }}
      </div>
      <div v-if="studio.averageRating">
        <span class="studioAverageRating">{{ studio.averageRating }}</span>
        <StarSVG
          v-for="index in numberOfStars"
          :key="index"
          style="padding-right: 1px"
          :fill="$vuetify.theme.themes.light.reviewGold"
        />

        <span class="studioNumberReviews">({{ studio.numberReviews }})</span>
      </div>

      <div class="studioDescription">
        {{ studio.description }}
      </div>

      <div>
        <v-divider class="my-3"></v-divider>
        <Locations :studio="studio" />
      </div>

      <div v-if="studio.studioVideo">
        <v-divider class="my-3"></v-divider>
        <StudioVideo :studio="studio" />
      </div>

      <div v-if="studio.averageRating">
        <v-divider class="my-3"></v-divider>
        <Reviews :studio="studio" />
      </div>

      <div>
        <v-divider class="my-3"></v-divider>
        <ClassesList
          :studios="studioLocations"
          :date="date"
          :timeRange="timeRange"
        />
      </div>
    </v-col>
  </div>
</template>
<script>
import Reviews from "@/components/home/Reviews";
import Locations from "@/components/home/Locations";
import StudioVideo from "@/components/home/StudioVideo.vue";
import ClassesList from "@/components/home/ClassesList";
import StarSVG from "@/components/shared/StarSVG";
import { mapGetters } from "vuex";
import { STUDIO_MODULE_NAME } from "@/store/moduleNames";

export default {
  data() {
    return {
      timeRange: ["00:00", "23:30"],
      studioLocations: [],
      date: "",
    };
  },
  methods: {
    initData() {
      const studio = this.studio;
      const locations = studio.locations;
      const studiosLocations = [];
      for (const location of locations) {
        studiosLocations.push({ ...studio, location });
      }
      this.studioLocations = studiosLocations;
    },
  },
  components: {
    Reviews,
    StarSVG,
    Locations,
    StudioVideo,
    ClassesList,
  },
  created() {
    this.initData()
  },
  computed: {
    ...mapGetters({
      studio: `${STUDIO_MODULE_NAME}/getStudio`,
    }),
    studioCategory() {
      if (this.studio.category) {
        return this.studio.category.join(", ");
      }
      return "";
    },
    carouselImages() {
      const images = [this.studio.studioImage];
      for (const location of this.studio.locations) {
        images.push(location.images[0]);
      }
      return images;
    },
    carouselHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 250;
        case "sm":
          return 350;
        case "md":
        case "lg":
        case "xl":
          return 400;
      }
      return 250;
    },
    numberOfStars() {
      if (this.studio.averageRating >= 4.5) {
        return 5;
      } else if (this.studio.averageRating >= 3.5) {
        return 4;
      } else if (this.studio.averageRating >= 2.5) {
        return 3;
      } else if (this.studio.averageRating >= 1.5) {
        return 2;
      } else if (this.studio.averageRating >= 0.5) {
        return 1;
      }
      return 0;
    },
  },
};
</script>
<style scoped>
.mainWrapper {
  width: 960px !important;
  max-width: 960px;
}
.carouselContainer {
  max-width: 600px;

  @media screen and (min-width: 768px) {
    width: 100%;
  }
}

.studioAverageRating {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-weight: 500;
  color: #000;
  margin-right: 4px;
}

.studioNumberReviews {
  color: #555;
  font-size: 0.875rem;
  line-height: 1.4285714286;
  letter-spacing: 0;
  margin-left: 4px;
}

.studioCategory {
  font-size: 0.75rem;
  line-height: 1.33;
  font-weight: 700;
  text-transform: uppercase;
  color: #999;
}

.studioDescription {
  font-size: 0.875rem;
  line-height: 1.4285714286;
}
</style>
