<template>
  <div>
    <br />
    <div class="d-none d-sm-flex">
      <FilterDesktop :studio="studio" @onFilterChange="onFilterChange" :type="'course'" />
    </div>
    <v-row
      min-height="20vh"
      width="100vw"
      class="d-flex d-sm-none px-5 py-5 mb-1"
      :style="{ backgroundColor: $vuetify.theme.themes.light.dilutedPrimary, color: $vuetify.theme.themes.light.primary }"
    >
      Filter Courses
      <v-row justify="end">
        <v-icon
          v-if="toggleDropdown"
          right
          class="my-3 mx-1"
          @click="toggleDropdown = !toggleDropdown"
          >mdi-chevron-down</v-icon
        >
        <v-icon
          v-else
          right
          class="my-3 mx-1"
          @click="toggleDropdown = !toggleDropdown"
          >mdi-chevron-right</v-icon
        >
      </v-row>
      <v-slide-y-transition>
        <FilterDesktop
          v-if="toggleDropdown"
          :studio="studio"
          :isMobileView="true"
          @onFilterChange="onFilterChange"
          :type="'course'"
        />
      </v-slide-y-transition>
    </v-row>
    <v-layout row wrap class="d-none d-sm-flex">
      <v-flex>
        <v-card
          class="white pb-2 pt-2"
          align-self-center="true"
          flat
          width="100vw"
          min-height="5vh"
        >
          <v-layout
            class="white pb-4 pt-2 pl-2 pr-4"
            align-content-center="true"
          >
            <v-flex xs9>
              <v-card-text class="py-2 primary--text font-weight-bold">
                Time
              </v-card-text>
            </v-flex>
            <v-flex xs12>
              <v-card-text class="py-2 primary--text font-weight-bold">
                Class
              </v-card-text>
            </v-flex>
            <v-flex xs9>
              <v-card-text class="py-2 primary--text font-weight-bold">
                Instructor
              </v-card-text>
            </v-flex>
            <v-flex xs9>
              <v-card-text class="py-2 primary--text font-weight-bold">
                Location
              </v-card-text>
            </v-flex>
            <v-flex xs6>
              <div class="py-2"></div>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
      <br /><br />
    </v-layout>
    <div>
      <v-layout row wrap class="d-none d-sm-flex">
        <course-view :studio="studio" :courses="courses" :bookBtn="bookBtn" />
      </v-layout>
      <v-layout row wrap class="d-flex d-sm-none">
        <course-view
          :studio="studio"
          :courses="courses"
          :bookBtn="bookBtn"
          :isMobileView="true"
        />
      </v-layout>
      <!--Add here the vuetify directive -->
      <v-skeleton-loader
        class="mt-10"
        v-if="hasMoreCourses && !errorInFetching"
        v-intersect="infiniteScrolling"
        type="card"
      />
      <p class="text-center py-8" v-if="!hasMoreCourses">No course sessions</p>
      <p class="text-center py-5" v-if="errorInFetching">
        Unable to fetch courses. Please reload.
      </p>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import { getCourseSessionsFromStudioId } from "@/util/cloudFunctions/coursesFunctions";
import FilterDesktop from "@/components/browseclasses/FilterDesktop.vue";
import CourseView from "@/components/browsecourses/CourseView.vue";
import { STUDIO_MODULE_NAME } from "@/store/moduleNames";
import {
  dateStringFromTimestamp,
  timeStringFromTimestamp,
} from "@/util/dateformat.js";

export default {
  components: {
    FilterDesktop,
    CourseView,
  },
  data() {
    return {
      courses: {},
      coursesRaw: [],
      lastCourseId: "",
      filters: { 
        studioId: "",
        courseNameFilter: [],
        instructorFilter: [],
        locaitonFilter: [],
        startDate: ""
      },
      toggleDropdown: false,
      isFetchingData: false,
      hasMoreCourses: true,
      errorInFetching: false,
      bookBtn: true,
      coursesQueryLength: 20,
    };
  },
  computed: {
    ...mapGetters({
      studio: `${STUDIO_MODULE_NAME}/getStudio`,
    }),
  },
  methods: {
    async fetchCourses() {
      if (this.isFetchingData) {
        return [];
      }
      this.isFetchingData = true;
      const result = await getCourseSessionsFromStudioId({
        ...this.filters,
        studioId: this.studio.id,
        lastDocId: this.lastCourseId
      });
      if (!result.success) {
        this.errorInFetching = true;
        this.isFetchingData = false;
        return [];
      }
      const docs = result.docs;
      if (docs.length >= 1) this.lastCourseId = docs[docs.length - 1].id;
      if (docs.length < this.coursesQueryLength) {
        this.hasMoreCourses = false;
      }
      return docs;
    },
    async onFilterChange(filters) {
      this.filters = { ...filters, courseNameFilter: filters.classFilter };
      this.lastCourseId = "";
      const docs = await this.fetchCourses();
      this.coursesRaw = docs;
      this.courses = this.formatArrayToObject();
      this.isFetchingData = false;
    },
    isMobile() {
      return window.innerWidth < 600;
    },
    isDisabled(item) {
      return item.isFull || item.participantCount === item.capacity;
    },
    formatArrayToObject() {
      // helper function to get the month name from an item
      let formattedObject = {};
      for (let item of this.coursesRaw) {
        // let date = formatTimestamp(item.startTimestamp, 'll');
        let date = dateStringFromTimestamp(item.startTimestamp);
        if (formattedObject[date] === undefined) {
          formattedObject[date] = [];
          item.timeStart = timeStringFromTimestamp(
            item.startTimestamp
          );
          item.timeEnd = timeStringFromTimestamp(
            item.endTimestamp
          );
          formattedObject[date].push(item);
        } else {
          item.timeStart = timeStringFromTimestamp(
            item.startTimestamp
          );
          item.timeEnd = timeStringFromTimestamp(
            item.endTimestamp
          );
          formattedObject[date].push(item);
        }
      }
      return formattedObject;
    },
    async infiniteScrolling() {
      const docs = await this.fetchCourses();
      this.coursesRaw.push(...docs);
      this.courses = this.formatArrayToObject();
      this.isFetchingData = false;  
    },
  },
};
</script>
