<template>
    <div>
        <br />
        <div class="d-none d-sm-flex">
            <FilterDesktop :studio="studio" @onFilterChange="onFilterChange" :type="'class'" />
        </div>
        <v-row
        min-height="20vh"
        width="100vw"
        class="d-flex d-sm-none px-5 py-5 mb-1"
        :style="{ backgroundColor: $vuetify.theme.themes.light.dilutedPrimary, color: $vuetify.theme.themes.light.primary }"
        >
        Filter Classes
        <v-row justify="end">
            <v-icon
            v-if="toggleDropdown"
            right
            class="my-3 mx-1"
            @click="toggleDropdown = !toggleDropdown"
            >mdi-chevron-down</v-icon
            >
            <v-icon
            v-else
            right
            class="my-3 mx-1"
            @click="toggleDropdown = !toggleDropdown"
            >mdi-chevron-right</v-icon
            >
        </v-row>
        <v-slide-y-transition>
          <FilterDesktop
            v-if="toggleDropdown"
            :studio="studio"
            :isMobileView="true"
            @onFilterChange="onFilterChange"	
            :type="'class'"
          />
        </v-slide-y-transition>
        </v-row>
        <v-layout row wrap class="d-none d-sm-flex">
        <v-flex>
            <v-card
            class="white pb-2 pt-2"
            align-self-center="true"
            flat
            width="100vw"
            min-height="5vh"
            >
            <v-layout
                class="white pb-4 pt-2 pl-2 pr-4"
                align-content-center="true"
            >
                <v-flex xs9>
                <v-card-text class="py-2 primary--text font-weight-bold">
                    Time
                </v-card-text>
                </v-flex>
                <v-flex xs12>
                <v-card-text class="py-2 primary--text font-weight-bold">
                    Class
                </v-card-text>
                </v-flex>
                <v-flex xs9>
                <v-card-text class="py-2 primary--text font-weight-bold">
                    Instructor
                </v-card-text>
                </v-flex>
                <v-flex xs9>
                <v-card-text class="py-2 primary--text font-weight-bold">
                    Location
                </v-card-text>
                </v-flex>
                <v-flex xs6>
                <div class="py-2"></div>
                </v-flex>
            </v-layout>
            </v-card>
        </v-flex>
        <br /><br />
        </v-layout>
        <div>
        <v-layout row wrap class="d-none d-sm-flex">
            <class-view
            :studio="studio"
            :classes="classes"
            :bookBtn="bookBtn"
            />
        </v-layout>
        <v-layout row wrap class="d-flex d-sm-none">
            <class-view
            :studio="studio"
            :classes="classes"
            :bookBtn="bookBtn"
            :isMobileView="true"
            />
        </v-layout>
        <!--Add here the vuetify directive -->
        <v-skeleton-loader
            class="mt-10"
            v-if="hasMoreClasses && !errorInFetching"
            v-intersect="infiniteScrolling"
            type="card"
        />
        <p class="text-center py-8" v-if="!hasMoreClasses">No more classes</p>
        <p class="text-center py-5" v-if="errorInFetching">
            Unable to fetch classes. Please reload.
        </p>
        </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
import { getClassesFromStudioId } from "@/util/cloudFunctions/classesFunctions";
import FilterDesktop from "@/components/browseclasses/FilterDesktop.vue";
import ClassView from "@/components/browseclasses/ClassView.vue";
import { STUDIO_MODULE_NAME } from "@/store/moduleNames";
import {
  dateStringFromTimestamp,
  timeStringFromTimestamp,
} from "@/util/dateformat.js";

export default {
  components: {
    FilterDesktop,
    ClassView,
  },
  data() {
    return {
      classes: [],
      classesRaw: [],
      lastClassId: "",
      filters: { 
        studioId: "",
        classFilter: [],
        instructorFilter: [],
        locaitonFilter: [],
        startDate: ""
      },
      toggleDropdown: false,
      isFetchingData: false,
      hasMoreClasses: true,
      errorInFetching: false,
      bookBtn: true,
      classesQueryLength: 10,
    };
  },
  computed: {
    ...mapGetters({
      studio: `${STUDIO_MODULE_NAME}/getStudio`,
    }),
  },
  methods: {
    async fetchClasses() {
      if (this.isFetchingData) {
        return [];
      }
      this.isFetchingData = true;

      const result = await getClassesFromStudioId({ ...this.filters, studioId: this.studio.id, lastDocId: this.lastClassId });
      // Error in querying
      if (!result.success) {
        this.errorInFetching = true;
        this.isFetchingData = false;
        return [];
      }
      const docs = result.docs;
      this.isFetchingData = false;

      // Process the classes
      if (docs.length >= 1) this.lastClassId = docs[docs.length - 1].id;

      // No more classes
      if (docs.length < this.classesQueryLength) {
        this.hasMoreClasses = false;
      }
      return docs;
    },
    async onFilterChange(filters) {
      this.filters = filters;
      this.lastClassId = "";
      const docs = await this.fetchClasses();
      this.classesRaw = docs;
      this.classes = this.formatArrayToObject();
    },
    isMobile() {
      return window.innerWidth < 600;
    },
    isDisabled(item) {
      return item.isFull || item.participantCount === item.capacity;
    },
    formatArrayToObject() {
      let formattedObject = new Map();
      for (let item of this.classesRaw) {
        const date = new Date(item.startTimestamp._seconds * 1000);
        date.setHours(0, 0, 0, 0);
        const dateKey = date.toISOString();
        if (!formattedObject.has(dateKey)) {
          formattedObject.set(dateKey, [])
        } 
        item.timeStart = timeStringFromTimestamp(item.startTimestamp);
        item.timeEnd = timeStringFromTimestamp(item.endTimestamp);
        formattedObject.get(dateKey).push(item);
      }
      const sortedDates = Array.from(formattedObject.keys()).sort((a, b) =>
        new Date(a).getTime() - new Date(b).getTime());
      return sortedDates.map(date => ({
        date: dateStringFromTimestamp(new Date(date)), item: formattedObject.get(date)
      }));
    },
    async infiniteScrolling() {
      const docs = await this.fetchClasses();
      this.classesRaw.push(...docs);
      this.classes = this.formatArrayToObject();
      setTimeout(() => {
        this.isFetchingData = false;
      }, 500);
    },
  },
};
</script>
