<template>
    <v-container fill-height fluid>
        <BackButton />
        <h3 class="my-5 text-center" v-if="!isUserAuth">You are not logged in</h3>

        <v-skeleton-loader type="card" v-if="isLoading" class="my-5"></v-skeleton-loader>

        <v-row justify="center" align="center">
            <v-col cols="12" md="6">
                <v-card v-if="isUserAuth && !isLoading">

                    <v-card-title class="primary--text">
                        <v-row align="center" class="mx-10 mt-3">
                            <v-avatar :size="userPhotoSize" class="mr-5">
                                <v-img :src="profilePhoto"></v-img>
                            </v-avatar>
                            <h4>{{ fullName }}</h4>
                        </v-row>
                    </v-card-title>

                    <v-card-text class="my-5">

                        <v-row align="center" class="mx-10">

                            <v-col cols="12" md="6" lg="4">
                                <span class="font-weight-light"> Username </span>
                                <br>
                                <span class="nextLine">{{ username }}</span>
                            </v-col>

                            <v-col cols="12" md="6" lg="4">
                                <span class="font-weight-light"> Date Of Birth</span>
                                <br>
                                <span class="nextLine">
                                    {{ dob }}
                                </span>
                            </v-col>

                            <v-col cols="12" md="6" lg="4">
                                <span class="font-weight-light"> Country</span>
                                <br>
                                <span class="nextLine">
                                    {{ country }}
                                </span>
                            </v-col>

                            <v-col cols="12">
                                <span class="font-weight-light"> Email </span>
                                <br>
                                <span class="nextLine">
                                    {{ email }}
                                </span>
                            </v-col>

                        </v-row>

                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { AUTHENTICATION_MODULE_NAME } from "@/store/moduleNames";
import BackButton from "@/components/shared/BackButton.vue";

export default {
  components: {
    BackButton,
  },
  computed: {
    ...mapGetters({
        isUserAuth: `${AUTHENTICATION_MODULE_NAME}/isUserAuth`,
        user: `${AUTHENTICATION_MODULE_NAME}/getUser`,
        isLoading: `${AUTHENTICATION_MODULE_NAME}/isLoading`,
    }),
    profilePhoto() {
        return this.user.profilePhoto;
    },
    fullName() {
        return this.user.fullName;
    },
    username() {
        return this.user.username;
    },
    dob() {
        return this.user.dob;
    },
    country() {
        return this.user.country;
    },
    email() {
        return this.user.email;
    },
    userPhotoSize() {
        switch (this.$vuetify.breakpoint.name) {
            case "xs":
                return 40;
            case "sm":
                return 60;
            case "md":
                case "lg":
            case "xl":
                return 70;
        }
        return 52;
    },
  },
};
</script>

<style scoped>
.nextLine {
    word-wrap: break-word;
    color: #000;
    font-weight: 400;
}
</style>