<template>
  <div>
    <h2 class="font-weight-medium">Reviews</h2>

    <h3 v-if="!studio.averageRating" class="text--secondary">No reviews yet</h3>

    <div v-if="studio.averageRating">
      <v-row align="center">
        <v-col style="max-width: 250px">
          <div class="bigRating">
            {{ studio.averageRating }}
          </div>
          <StarSVG
            v-for="index in numberOfStars"
            :key="index"
            style="padding-right: 1px"
            :fill="$vuetify.theme.themes.light.reviewGold"
          />
          <div class="text--secondary my-1">
            {{ studio.numberReviews }} rating{{
              studio.numberReviews > 1 ? "s" : ""
            }}
          </div>
        </v-col>
        <v-col style="max-width: 250px">
          <div class="d-flex flex-row align-center">
            <span class="mr-3">5</span>
            <v-progress-linear
              v-model="fiveStarRating"
              color="success"
              background-color="#d9d9d9"
              rounded
              height="10"
            ></v-progress-linear>
          </div>
          <div class="d-flex flex-row align-center">
            <span class="mr-3">4</span>
            <v-progress-linear
              v-model="fourStarRating"
              color="reviewFour"
              background-color="#d9d9d9"
              rounded
              height="10"
            ></v-progress-linear>
          </div>
          <div class="d-flex flex-row align-center">
            <span class="mr-3">3</span>
            <v-progress-linear
              v-model="threeStarRating"
              color="reviewThree"
              background-color="#d9d9d9"
              rounded
              height="10"
            ></v-progress-linear>
          </div>
          <div class="d-flex flex-row align-center">
            <span class="mr-3">2</span>
            <v-progress-linear
              v-model="twoStarRating"
              color="reviewTwo"
              background-color="#d9d9d9"
              rounded
              height="10"
            ></v-progress-linear>
          </div>
          <div class="d-flex flex-row align-center">
            <span class="mr-3">1</span>
            <v-progress-linear
              v-model="oneStarRating"
              color="reviewOne"
              background-color="#d9d9d9"
              rounded
              height="10"
            ></v-progress-linear>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="pt-0">
          <div v-if="loading">
            <v-skeleton-loader
              class="my-3"
              height="150px"
              type="card"
              v-for="index in 5"
              :key="index"
            ></v-skeleton-loader>
          </div>
          <div v-else>
            <div v-for="review of reviews" :key="review.id">
              <v-divider class="my-3"></v-divider>
              <div class="font-weight-medium">{{ review.title }}</div>
              <div class="text--secondary">{{ review.username }}</div>
              <StarSVG
                v-for="index in review.userRating"
                :key="index"
                style="padding-right: 1px"
                :fill="$vuetify.theme.themes.light.reviewGold"
              />
              <TimeAgo
                class="ml-1 text--secondary"
                long
                :datetime="dateObjFromTimestamp(review.timestamp)"
              ></TimeAgo>
              <div class="py-3">{{ review.comment }}</div>
            </div>
          </div>
        </v-col>
      </v-row>

      <v-divider class="my-3"></v-divider>
      <v-row no-gutters>
        <v-spacer></v-spacer>
        <div>
          <span class="mr-1" v-if="isNextPageDefined(page) || isPreviousPageDefined">Page {{ page }}</span>
          <v-icon class="cursor-pointer" @click="prevPage" v-if="isPreviousPageDefined"
            >mdi-chevron-left</v-icon
          >
          <v-icon class="cursor-pointer" @click="nextPage" v-if="isNextPageDefined(page)"
            >mdi-chevron-right</v-icon
          >
        </div>
      </v-row>
    </div>
  </div>
</template>
<script>
import StarSVG from "@/components/shared/StarSVG";
import { TimeAgo } from "vue2-timeago";

import { getRecentReviews } from "@/util/cloudFunctions/reviews";
import { dateObjFromTimestamp } from "@/util/dateformat";

export default {
  components: {
    StarSVG,
    TimeAgo,
  },
  props: {
    studio: Object,
  },
  computed: {
    isPreviousPageDefined() {
        return this.page > 1;
    },
    numberOfStars() {
      if (this.studio.averageRating >= 4.5) {
        return 5;
      } else if (this.studio.averageRating >= 3.5) {
        return 4;
      } else if (this.studio.averageRating >= 2.5) {
        return 3;
      } else if (this.studio.averageRating >= 1.5) {
        return 2;
      } else if (this.studio.averageRating >= 0.5) {
        return 1;
      }
      return 0;
    },
    fiveStarRating() {
      return this.ratingPercentage(this.studio.numberFiveReviews);
    },
    fourStarRating() {
      return this.ratingPercentage(this.studio.numberFourReviews);
    },
    threeStarRating() {
      return this.ratingPercentage(this.studio.numberThreeReviews);
    },
    twoStarRating() {
      return this.ratingPercentage(this.studio.numberTwoReviews);
    },
    oneStarRating() {
      return this.ratingPercentage(this.studio.numberOneReviews);
    },
  },
  data() {
    return {
      page: 1,
      reviewsMap: {}, // key is the page number (int) and the value is an array of reviews
      reviews: [],
      lastDocId: null,
      noMoreReviews: false,
      loading: false,
      count: 5,
    };
  },
  methods: {
    dateObjFromTimestamp,
    ratingPercentage(numberReviewsForRating) {
      return (numberReviewsForRating / this.studio.numberReviews) * 100;
    },
    isNextPageDefined(page) {
        return this.reviewsMap[page + 1] !== undefined && this.reviewsMap[page + 1].length > 0;
    },
    setCurrentPageReviews() {
      if (this.page in this.reviewsMap) {
        this.reviews = this.reviewsMap[this.page];
      }
    },
    prevPage() {
      if (this.page == 1) {
        return;
      }
      this.page -= 1;
      this.setCurrentPageReviews();
    },
    async nextPage() {
      if (this.reviews.length == 0) {
        return;
      }
      this.page += 1;
      if (!(this.page in this.reviewsMap)) {
        await this.setRecentReviews();
      }
      this.setCurrentPageReviews();
    },
    async setRecentReviews(stopHere) {
      this.loading = true;
      const reviews = await getRecentReviews(
        this.studio.id,
        this.lastDocId,
        this.count
      );
      this.reviewsMap[this.page] = reviews;
      if (reviews.length > 0 && !stopHere) {
        this.lastDocId = reviews[reviews.length - 1].id;
        this.page++;
        await this.setRecentReviews(true);
        this.prevPage();
      }
      this.loading = false;
    },
  },
  async created() {
    await this.setRecentReviews();
    this.setCurrentPageReviews();
  },
};
</script>
<style scoped>
.bigRating {
  font-size: 2.3rem;
  line-height: 1.1428571429;
  font-weight: 750;
  font-family: circuit, sans-serif;
}
</style>
