import { functions, httpsCallable } from '@/firebase';

/**
 * Adds User Document to Database.
 * @param {String} userId 
 * @param {String} email 
 * @param {String} fullName 
 * @param {String} username 
 * @param {String} dateOfBirth in ISO format
 * @param {String} country in Country Code
 * @returns 
 */
function addUserDocument(userId, email, fullName, username, dob, country) {
    const addUserDoc = httpsCallable(functions, 'addUserDoc');
    return addUserDoc({ userId, email, fullName, username, dob, country }).then((result) => result.data).catch(() => ({ success: false, error: "Something went wrong. Please contact vibefam at contact@vibefam.com for help." }));
}

export default addUserDocument;
