var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"signup-page"},[_c('div',{staticClass:"text-container"},[_c('h1',{staticClass:"signup-title"},[_vm._v("Your Wellness Journey Begins Here")]),_c('p',{staticClass:"signup-body"},[_vm._v(" With over 300 diverse fitness classes available across Singapore, you have the freedom to choose and embrace your favorite way to sweat. "+_vm._s(_vm.desktopMessage)+" ")])]),_c('div',{staticClass:"form-container"},[_c('img',{staticClass:"logo",attrs:{"src":require("@/assets/logo_transparent.png")}}),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[_c('ValidationObserver',{ref:"observer"},[_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"name"}},[_vm._v("Name*")]),_c('ValidationProvider',{attrs:{"name":"Name","rules":"required|max:64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.name),expression:"formData.name"}],attrs:{"type":"text","id":"name"},domProps:{"value":(_vm.formData.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "name", $event.target.value)}}}),_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"email"}},[_vm._v("Personal Email*")]),_c('ValidationProvider',{attrs:{"name":"Personal Email","rules":"required|max:128|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.personalEmail),expression:"formData.personalEmail"}],attrs:{"type":"text","id":"email"},domProps:{"value":(_vm.formData.personalEmail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "personalEmail", $event.target.value)}}}),_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"companyName"}},[_vm._v("Company Name*")]),_c('ValidationProvider',{attrs:{"name":"Company Name","rules":"required|max:64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.companyName),expression:"formData.companyName"}],attrs:{"type":"text","id":"companyName"},domProps:{"value":(_vm.formData.companyName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "companyName", $event.target.value)}}}),_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"form-group"},[_c('label',{attrs:{"for":"company-email"}},[_vm._v("Company Email")]),_c('ValidationProvider',{attrs:{"name":"Company Email","rules":"max:128|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formData.companyEmail),expression:"formData.companyEmail"}],attrs:{"type":"text","id":"company-email"},domProps:{"value":(_vm.formData.companyEmail)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.formData, "companyEmail", $event.target.value)}}}),_c('span',{staticClass:"error--text"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('div',{staticClass:"form-group"},[_c('p',{ref:"error",staticClass:"error--text mx-5"}),_c('p',{ref:"success",staticClass:"success--text mx-5"}),_c('v-btn',{staticClass:"primary submit-btn",attrs:{"type":"submit","large":"","loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v("Sign Up")])],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }