import { gettersGlobal } from "@/store/moduleComponents/global/gettersGlobal";
import { actionsGlobal } from "@/store/moduleComponents/global/actionsGlobal";
import { mutationsGlobal } from "@/store/moduleComponents/global/mutationsGlobal";

export const globalModule = {
    namespaced: true,
    state: { 
        studios: [],
        loading: true,
    },
    getters: gettersGlobal,
    actions: actionsGlobal,
    mutations: mutationsGlobal
}
