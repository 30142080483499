<template>
  <v-col align="center" class="pb-0">
    <v-img
      :src="require('@/assets/success.gif')"
      max-width="400px"
      min-height="400px"
    />
    <p>Success! Please check your email for confirmation.</p>
    <v-btn
      v-if="this.packageItem.isPackageShareable"
      color="primary"
      width="40%"
      depressed
      @click="openDialog"
      >Share Package</v-btn
    >

    <v-dialog v-model="dialog" width="600px">
      <v-card>
        <v-card-title>
          <v-col class="pb-0">
            <v-row class="justify-end">
              <v-icon @click="closeDialog">mdi-close</v-icon>
            </v-row>
          </v-col>
        </v-card-title>
        <v-card-text>
          <PackageShareWithCard
            :packageItem="this.packageItem"
            @shareAfterPurchase="getPackageItem"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-col>
</template>
<script>
import { SHARE_PACKAGE } from "@/analytics/events";
import PackageShareWithCard from "@/components/packages/PackageShareWithCard";
import { getSharedMembers } from "../../util/packages/getSharedMembers";

export default {
  props: {
    packageId: String,
  },
  components: {
    PackageShareWithCard,
  },
  data() {
    return {
      dialog: false,
      users: [],
      packageItem: Object,
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    openDialog() {
      this.$gtag.event(SHARE_PACKAGE);
      this.dialog = true;
    },
    async getPackageItem() {
      this.users = await getSharedMembers(this.packageId);
      this.packageItem = { ...this.users[0], id: this.packageId };
    },
  },
  async created() {
    this.getPackageItem();
  },
};
</script>
