<template>
    <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="timeRange"
        transition="scale-transition"
        offset-y
        min-width="290px"
    >
        <template v-slot:activator="{ on, attrs }">
            <v-text-field
                :value="timeRangeText"
                readonly
                :error-messages="errors"
                v-bind="attrs"
                v-on="on"
                :hint="hint"
                v-bind:persistent-hint="true"
                background-color="white"
                :clearable="clearable"
                :outlined="outlined"
                :solo="solo"
                :dense="dense"
                :rounded="rounded"
                :hide-details="hideDetails"
            ></v-text-field>
        </template>
        <div class="menuContainer">
            <div>
                {{ timeRangeFromSliderText }}
            </div>
            <v-range-slider
                v-model="sliderValue"
                color="primary white--text"
                scrollable
                :min="0"
                :max="47"
            >
            </v-range-slider>
            <v-btn text color="primary" @click="menu = false" depressed>Cancel</v-btn>
            <v-btn text color="primary" @click="emitTimeRange" depressed>OK</v-btn>
        </div>
        
    </v-menu>
</template>

<script>
import { timeStringFromTimestamp } from "@/util/dateformat";

export default {
    props: ["value", "errors", "min", "max", "hint", "outlined", "solo", "dense", "rounded", "hideDetails", "clearable"],
    computed: {
        timeRangeText() {
            const date = new Date();
            const startTime = new Date(`${date.toDateString()} ${this.timeRange[0]}`);
            const endTime = new Date(`${date.toDateString()} ${this.timeRange[1]}`);
            return `${timeStringFromTimestamp(startTime)} - ${timeStringFromTimestamp(endTime)}`;
        },
        timeRangeFromSliderText() {
            const timeRange = [this.sliderToTime(this.sliderValue[0]), this.sliderToTime(this.sliderValue[1])];
            const date = new Date();
            const startTime = new Date(`${date.toDateString()} ${timeRange[0]}`);
            const endTime = new Date(`${date.toDateString()} ${timeRange[1]}`);
            return `${timeStringFromTimestamp(startTime)} - ${timeStringFromTimestamp(endTime)}`;
        },
    },
    methods: {
        timeStringFromTimestamp,
        emitTimeRange() {
            const timeRange = [this.sliderToTime(this.sliderValue[0]), this.sliderToTime(this.sliderValue[1])];
            this.$refs.menu.save(timeRange);
            this.$emit("input", timeRange);
        },
        timeToSliderValue(time) {
            const splitTime = time.split(":");
            return parseInt(splitTime[0]) * 2 + (splitTime == "30" ? 1 : 0);
        },
        sliderToTime(slider) {
            const hour = ("0" + Math.floor(slider / 2).toString()).slice(-2);
            const minute = (slider / 2 == 0) ? "00" : "30";
            return `${hour}:${minute}`; 
        },
    },
    data() {
        return {
            menu: false,
            timeRange: this.value,
            sliderValue: [this.timeToSliderValue(this.value[0]), this.timeToSliderValue(this.value[1])]
        };
    },
};
</script>
<style scoped>
.menuContainer {
    padding: 16px;
    background-color: white;
}
</style>
