import getStudioFromWebAppRoute from '@/util/cloudFunctions/getStudioFromWebAppRoute';
import getDisplayCurrency from "@/util/currencyUtil";

export const actionsStudio = {
    async setStudioAction({ commit, state }, webAppRoute) {
        const currentStudio = state.studio;
        if (currentStudio && currentStudio.webAppRoute && currentStudio.webAppRoute === webAppRoute) {
            return;
        }
        commit('setLoading', true);
        const result = await getStudioFromWebAppRoute(webAppRoute);
        if (result.success) {
            const studioDoc = result.doc;
            const displayCurrency = getDisplayCurrency(studioDoc.currency);
            commit('setStudio', { ...studioDoc, displayCurrency });
        } else {
            commit('setStudio', null);
        }

        commit('setLoading', false);
    },
}
