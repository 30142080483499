<template>
  <div class="merchandise-page">
    <v-row class="my-5">
      <v-col cols="12" lg="3">
        <h1>Products</h1>
      </v-col>

      <v-col cols="12" lg="9">
        <div class="d-flex">
          <v-text-field
            :placeholder="`Search ${studio.studioName} Products`"
            v-model="searchTerm"
          />
          <v-btn
            color="primary"
            class="ml-4 d-lg-none"
            @click="filterIsShown = !filterIsShown"
          >
            <span class="mdi mdi-filter"></span>
            FILTER
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <v-row class="my-5">
      <v-col cols="12" lg="3">
        <v-scroll-y-reverse-transition>
          <MerchandiseFilter
            ref="filter"
            v-if="filterIsShown"
            @priceFiltered="priceFilter = $event"
            @categoryFiltered="categoryFilter = $event"
          />
        </v-scroll-y-reverse-transition>
      </v-col>

      <v-col cols="12" lg="9">
        <MerchandiseList
          ref="merchandise-list"
          :loading="loading"
          :merchandise="merchandise"
          :search-term="searchTerm"
          :price-filter="priceFilter"
          :category-filter="categoryFilter"
          @addToCart="setNumberOfCartItems"
        />
      </v-col>
    </v-row>

    <!-- View Cart Button -->
    <div class="viewCartButton">
      <v-badge :content="numberOfCartItems" :value="isShowBadge">
        <v-btn class="secondary" x-large @click="navigateToCartPage">
          <v-icon left>mdi-cart</v-icon> View Cart
        </v-btn>
      </v-badge>
    </div>

  </div>
</template>

<script>
import MerchandiseFilter from '@/components/merchandise/MerchandiseFilter.vue';
import MerchandiseList from '@/components/merchandise/MerchandiseList.vue';

import { getProducts } from '@/util/cloudFunctions/merchandiseFunctions';
import { getNumberOfCartItems } from "@/util/cloudFunctions/shoppingCart";

import { STUDIO_MODULE_NAME } from '@/store/moduleNames';
import { mapGetters } from 'vuex';

export default {
  components: {
    MerchandiseFilter,
    MerchandiseList,
  },

  data() {
    return {
      studioName: '',
      searchTerm: '',
      priceFilter: '',
      categoryFilter: '',
      filterIsShown: false,
      loading: true,
      merchandise: [],
      numberOfCartItems: 0,
    }
  },

  created() {
    this.setFilterVisibility();
    window.addEventListener('resize', this.setFilterVisibility, { passive: true });
    this.initData();
    this.setNumberOfCartItems();
  },

  destroy() {
    window.removeEventListener('resize', this.setFilterVisibility, { passive: true });
  },

  methods: {
    setFilterVisibility() {
      this.filterIsShown = window.innerWidth >= 1264;
    },
    async initData() {
      this.loading = true;
      const result = await getProducts(this.studio.id);
      if (!result.success) {
        this.loading = false;
        return;
      }
      this.merchandise = result.docs;
      this.loading = false;
    },
    async setNumberOfCartItems() {
      const result = await getNumberOfCartItems(this.studio.id);
      if (result.success) {
        this.numberOfCartItems = result.count;
      }
    },
    navigateToCartPage() {
      const studioRoute = this.$route.params.studioRoute;
      this.$router.push(`/${studioRoute}/merchandise/cart`);
    },
  },

  computed: {
    ...mapGetters({
      studio: `${STUDIO_MODULE_NAME}/getStudio`,
    }),
    isShowBadge() {
      return this.numberOfCartItems > 0;
    },
  },
};
</script>

<style scoped lang="scss">

.viewCartButton {
  position: fixed;
  bottom:50px;
  right:50px;
  z-index: 100;
}
.merchandise-page {
  ::v-deep {
    .v-input {
      margin-top: 0;
      padding-top: 0;
    }

    .v-messages {
      display: none;
    }
  }

  .filter-is-shown {
    display: block;
  }
}
</style>
