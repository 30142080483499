<template>
    <v-autocomplete
        dense
        rounded
        prepend-inner-icon="mdi-map-marker"
        outlined
        placeholder="City, neighbourhood"
        class="searchBox"
        hide-details
        :search-input.sync="text"
        :items="items"
        item-text="description"
        v-model="selectedPlace"
        return-object
        clearable
        no-data-text="Type to start searching cities"
    ></v-autocomplete>
</template>
<script>
import { gmapApi } from "vue2-google-maps"

export default {
    computed: {
        google: gmapApi,
    },
    data() {
        return {
            text: "",
            items: [],
            selectedPlace: null,
        }
    },
    methods: {
        async search() {
            if (!this.text) {
                return;
            }
            const displaySuggestions = async (predictions, status) => {
                if (status != this.google.maps.places.PlacesServiceStatus.OK || !predictions) {
                    alert(status);
                    return;
                }

                this.items = predictions;
            };

            const service = new this.google.maps.places.AutocompleteService();
            service.getQueryPredictions({ input: this.text }, displaySuggestions);
        },
    },
    watch: {
        text() {
            this.search();
        },
        async selectedPlace() {
            if (!this.selectedPlace) {
                return;
            }

            const geocoder = new this.google.maps.Geocoder();
            geocoder
                .geocode({ placeId: this.selectedPlace.place_id })
                .then(({ results }) => {
                    const location = results[0].geometry.location;
                    this.$emit("search", { lat: location.lat(), lng: location.lng() });
                })
                .catch((e) => window.alert("Geocoder failed due to: " + e));

        },
    }
}
</script>
<style scoped>
.searchBox {
    max-width: 400px;
    margin-right: 16px;
}
</style>

