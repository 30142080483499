<template>
  <div>
    <div v-if="loading">
      <v-skeleton-loader
        v-for="index in 10"
        :key="index"
        type="card"
        height="150px"
        class="my-3 mx-3"
      ></v-skeleton-loader>
    </div>
    <div v-else class="background-class">
      <div v-for="(classObject, index) of classes" :key="index">
     <v-row justify="center" class=" my-3"  v-if="index == 0 || isDayDifferent(classes, index)" >
        
       <h3
       :class="index == 0? 'mt-5 primary--text' : 'primary--text'"
        >
          {{ getClassDateString(classObject) }}
        </h3>
       
     </v-row>
 
      
        <ClassCard :classObject="classObject" class="my-5" />
      </div>
    </div>
    <div v-if="!loading && classes.length == 0" class="pa-3">
      No classes available
    </div>
  </div>
</template>
<script>
import ClassCard from "@/components/home/ClassCard";
import moment from "moment";
import {
  getStudioClasses,
  getStudioClassesTwoWeeks,
} from "@/util/classes/getStudioClasses";

export default {
  components: {
    ClassCard,
  },
  props: {
    studios: Array,
    date: String,
    timeRange: Array, // [ startTime, endTime ]
  },
  computed: {
    startTime() {
      return this.timeRange[0];
    },
    endTime() {
      return this.timeRange[1];
    },
  },
  data() {
    return {
      loading: true,
      classes: [],
    };
  },
  methods: {
    getClassDateString(classObject) {
      return moment(classObject).format("DD MMMM");
    },
    isDayDifferent(classes, index) {
      if (index === 0) {
        return false;
      }
      const classA = classes[index];
      const classB = classes[index - 1];
      const classAMoment = moment(classA.startTimestamp.toDate()).startOf(
        "day"
      );
      const classBMoment = moment(classB.startTimestamp.toDate()).startOf(
        "day"
      );
      const diff = classAMoment.diff(classBMoment, "day");
      return diff != 0;
    },
    async initData() {
      this.loading = true;
      const studioClasses = await Promise.all(
        this.studios.map(async (studio) => {
          let classes;
          if (this.date) {
            classes = await getStudioClasses(
              studio.id,
              studio.location.id,
              this.date,
              this.startTime,
              this.endTime
            );
            return classes
              .map((classObject) => ({
                ...classObject,
                studio,
                showDescription: false,
              }))
              .filter(this.checkClassFull)
              .filter(this.checkDateFuture)
              .sort(this.sortDate);
          } else {
            classes = await getStudioClassesTwoWeeks(
              studio.id,
              studio.location.id,
              this.startTime,
              this.endTime
            );
            return classes
              .map((classObject) => ({
                ...classObject,
                studio,
                showDescription: false,
              }))
              .filter(this.checkClassFull)
              .filter(this.checkDateFuture)
              .sort(this.sortDate);
          }
        })
      );
      const studioClassesFlattened = studioClasses.flatMap((x) => x);
      this.classes = studioClassesFlattened.sort(this.sortDate);
      this.loading = false;
    },
    checkDateFuture(classObject) {
      const start = classObject.startTimestamp.toDate();
      return moment(start).isAfter(new Date(), "minute");
    },
    sortDate(aClass, bClass) {
      const aStart = aClass.startTimestamp.toDate();
      const bStart = bClass.startTimestamp.toDate();
      return moment(aStart).diff(bStart);
    },
    checkClassFull(classObject) {
      return (
        !classObject.isFull &&
        !(classObject.participantCount == classObject.capacity)
      );
    },
  },
  watch: {
    $props: {
      handler() {
        this.initData();
      },
      immediate: true,
      deep: true,
    },
  },
};
</script>

<style scoped>
.background-class {
  background-color: #e4e9ec;
}
.default-divider {
  background-color: #C6C6C8;
}
.thickness {
  background-color: #C6C6C8;
  height: 3px; /* Adjust the height value as per your requirement */
}
</style>
