<template>
  <v-card outlined tile class="mt-2" width="110%" height="50px">
    <v-row>
      <v-col cols="3">
        <v-icon large class="mt-1 ml-3" color="grey lighten-1"
          >mdi-account-circle-outline</v-icon
        >
      </v-col>

      <v-col cols="6">
        <v-row class="mt-1">
          <p class="mb-0">{{ username }}</p>
        </v-row>
        <v-row class="mt-2">
          <p class="mb-2 grey--text">{{ name }}</p>
        </v-row>
      </v-col>

      <v-col class="mt-3">
        <p>{{ numberClassesUsed }}/{{ numberClassesShared }}</p>
      </v-col>
    </v-row>
  </v-card>
</template>
<script>
export default {
  props: {
    username: String,
    name: String,
    numberClassesUsed: Number,
    numberClassesShared: Number,
  },
};
</script>
