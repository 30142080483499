<template>
  <v-dialog v-model="dialogVisible" max-width="500px">
    <v-card class="success-dialog">
      <v-card-title class="text-center">
        <lottie-animation
          class="success-icon"
          path="lottie/greentick.json"
          :height="dimensions"
          :width="dimensions"
        />
      </v-card-title>
      <v-card-text class="text-center">
        <span class="success-msg">{{ message }}</span>
        <v-divider class="mt-5"></v-divider>
      </v-card-text>
      <v-card-actions class="text-right">
        <v-spacer></v-spacer>
        <v-btn class="success" @click="closeDialog">OK</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue";

export default {
  props: ["message"],
  computed: {
    dimensions() {
      return this.$vuetify.breakpoint.mdAndUp ? 200 : 150;
    },
  },
  components: {
    LottieAnimation,
  },
  data() {
    return {
      dialogVisible: false,
    };
  },
  methods: {
    openDialog() {
      this.dialogVisible = true;
    },
    closeDialog() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style scoped>
.success-dialog {
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.success-icon {
  margin: 0 auto;
}

.success-msg {
  font-size: 18px;
  display: block;
  margin-top: 10px;
  color: #333333;
}

</style>
