<template>
    <div>
        <h2>Thank you for your order!</h2>
        <p class="error--text">{{ error }}</p>
        <v-col v-if="loading">
            <v-skeleton-loader height="100" type="card" v-for="i in 5" :key="i" class="my-3"></v-skeleton-loader>
        </v-col>
        <div v-if="!loading && !error">
            <div>Order Id: #{{ orderId }}</div>
            <div>
                Total: {{ `${studio.displayCurrency}${payment.amount}` }}
            </div>
            <div>
                Payment Method: {{ payment.paymentMethod }}
            </div>
            <div>
                Paid on: {{ dateTimeStringFromTimestamp(order.createdAt) }}
            </div>
            <v-col>
                <v-card v-for="orderItem in orderItems" :key="orderItem.id" class="my-3">
                    <div class="pa-3">
                        <v-img max-width="200px" max-height="200px" :src="orderItem.product.images[0]"></v-img>
                    </div>

                    <v-card-title>
                        <div class="product-name">{{ orderItem.product.name }}</div>
                    </v-card-title>
                  
                    <v-card-subtitle>
                        <span class="description">{{ orderItem.product.description }}</span>
                    </v-card-subtitle>
                  
                    <v-card-text class="black--text">
                        <div class="d-flex justify-space-between">
                          <div>
                            {{ `${studio.displayCurrency}${orderItem.price}` }}
                          </div>
                          <div>
                            Quantity: {{ orderItem.quantity }}
                          </div>
                        </div>
                        <div class="pt-3">
                            <strong>
                                Sub-total: {{ studio.displayCurrency }}{{ calculateTotalPrice(orderItem.quantity, orderItem.price) }}
                            </strong>
                        </div>
                    </v-card-text>
    
                </v-card>
            </v-col>
        </div>
    </div>
</template>
<script>
import { getMyOrder } from "@/util/cloudFunctions/orders";

import { dateTimeStringFromTimestamp } from "@/util/dateformat";
import { STUDIO_MODULE_NAME } from "@/store/moduleNames";
import { mapGetters } from "vuex";
import { toTwoDp } from "@/util/dphelpers";

export default {
    props: {
        orderId: String,
    },
    created() {
        this.initData();
    },
    computed: {
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
        orderItems() {
            return this.order.orderItems ?? [];
        },
        payment() {
            return this.order.payment ?? {};
        },
    },
    data() {
        return {
            loading: true,
            order: {},
            error: "",
        }
    },
    methods: {
        dateTimeStringFromTimestamp,
        async initData() {
            this.loading = true;
            const result = await getMyOrder(this.orderId);
            if (!result.success) {
                this.loading = false;
                this.error = result.error;
                return;
            }
            this.order = result.doc;
            this.loading = false;
        },
        calculateTotalPrice(quantity, price) {
            return toTwoDp(quantity * price);
        },
    },
}
</script>
<style scoped>
.description {
    position: relative;
    display: -webkit-box;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
}
.product-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

</style>
