<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn text v-bind="attrs" v-on="on">
          <v-avatar class="mr-3" size="40">
            <v-img :src="user.profilePhoto" />
          </v-avatar>
          <span>
            {{ user.fullName }}
          </span>
          <v-icon>mdi-menu-down</v-icon>
        </v-btn>
      </template>

      <v-list dense nav>
        <v-list-item v-if="credits !== undefined" :key="credits">
          <v-list-item-icon>
            <v-icon>mdi-credit-card</v-icon>
          </v-list-item-icon>
          <v-list-item-title :key="credits"
            >{{ credits }} Credits</v-list-item-title
          >
        </v-list-item>

        <v-list-item
          v-for="item in items"
          :key="item.name"
          :to="item.link"
          :class="item.class"
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          key="Change Password"
          @click="changePassword"
          color="primary"
        >
          <v-list-item-icon>
            <v-icon>mdi-lock</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Change Password</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item key="Logout" @click="signOut" color="primary">
          <v-list-item-icon>
            <v-icon>mdi-logout</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
    <!-- Change Password Dialog -->
    <ChangePassword ref="ChangePassword" :hideButton="true" />

    <!-- Change Password Dialog -->
    <Logout ref="Logout" :hideButton="true" />
  </div>
</template>
<script>
import ChangePassword from "@/components/auth/ChangePassword";
import Logout from "@/components/auth/Logout";
import { AUTHENTICATION_MODULE_NAME } from "@/store/moduleNames";
import { mapGetters } from "vuex";
export default {
  components: {
    ChangePassword,
    Logout,
  },
  props: {
    user: Object,
    hideRedirect: Boolean,
  },
  computed: {
    ...mapGetters({
      credits: `${AUTHENTICATION_MODULE_NAME}/getCredits`,
    }),
  },
  data() {
    if (this.hideRedirect) {
      return {
        items: [
          {
            name: "My Classes",
            icon: "mdi-book-variant",
            link: "/myclasses",
          },
        ],
      };
    }
    return {
      items: [
        {
          name: "My Classes",
          icon: "mdi-book-variant",
          link: `/myclasses`,
        },
      ],
    };
  },
  methods: {
    changePassword() {
      this.$refs.ChangePassword.openDialog();
    },
    signOut() {
      this.$refs.Logout.signOut();
    },
  },
};
</script>
