var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-col',{attrs:{"cols":"12","lg":"3"}},[_c('h1',[_vm._v("Shopping Cart")])]),(_vm.loading)?_c('div',_vm._l((3),function(i){return _c('v-skeleton-loader',{key:i,staticClass:"my-3",attrs:{"height":"100px","type":"card"}})}),1):_vm._e(),_c('v-fade-transition',[(!_vm.loading)?_c('v-col',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.image",fn:function(ref){
var item = ref.item;
return [_c('v-img',{attrs:{"width":"100px","src":item.product.images[0]}})]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.product.name)+" ")]}},{key:"item.price",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.product.price)+" ")]}},{key:"item.order",fn:function(ref){
var item = ref.item;
return [_c('vue-numeric-input',{staticClass:"numeric-input-btn-style",attrs:{"min":0,"align":"center"},on:{"input":function (quantity) { return _vm.updateCart(item, quantity); }},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", $$v)},expression:"item.quantity"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.removeCartItem(item)}}},[_vm._v("mdi-close")])]}}],null,false,466556716)}),_c('div',{staticClass:"py-3 checkoutRow"},[_c('h3',[_vm._v("Total: "+_vm._s(_vm.studio.displayCurrency)+_vm._s(_vm.total))]),_c('v-spacer'),_c('CheckoutShoppingCart',{attrs:{"orders":_vm.items},on:{"success":_vm.onSuccess}})],1)],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }