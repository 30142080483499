<template>
  <v-text-field
    input
    :type="fieldType"
    :error-messages="errors"
    :label="label"
    :placeholder="placeholder"
    :hint="hint"
    :prepend-icon="prependIcon"
    :solo="solo"
    :persistent-hint="hasPersistentHint"
    :disabled="disabled"
    v-model="str"
    @input="emitText"
    @change="emitValue"
  >
  </v-text-field>
</template>

<script>
export default {
  props: [
    "value",
    "type",
    "error-messages",
    "label",
    "placeholder",
    "hint",
    "solo",
    "persistent-hint",
    "prepend-icon",
    "disabled",
  ],
  computed: {
    fieldType: function() {
      return this.type ??  "text";
    },
    errors: function () {
      return this.errorMessages ?? "";
    },
    hasPersistentHint: function () {
      return this.persistentHint ?? false;
    },
  },
  methods: {
    emitText(str) {
      const trimmed = str.trim();
      this.emitToParent("input", trimmed);
    },
    emitValue(str) {
      const trimmed = str.trim();
      this.emitToParent("change", trimmed);
    },
    emitToParent(name, val) {
      this.$emit(name, val);
    },
  },
  data() {
    return {
      str: this.value,
    };
  },
};
</script>
