<template>
    <div>

        <!-- Login Button -->
        <v-btn
            :block="block"
            class="auth-btn"
            color="primary"
            @click="openDialog"
        >
            Login
        </v-btn>

        <v-dialog v-model="dialog" width="600px">

            <!-- Login Form -->
            <v-card>
                <v-card-title>
                    <v-col>
                        <v-row class="justify-end">
                            <v-icon @click="closeDialog">mdi-close</v-icon>
                        </v-row>
                        <v-row class="justify-center">
                            <h4 class="ml-2">Login</h4>
                        </v-row>
                    </v-col>
                </v-card-title>
                <v-card-text>
                    <LoginForm :key="loginFormKey" @success="closeDialog" @signUp="openSignUpDialog" />
                </v-card-text>
            </v-card>
            
            <!-- Sign Up Dialog -->
            <SignUp ref="SignUp" :key="signUpFormKey" :hideButton="true" />

        </v-dialog>

    </div>
</template>

<script>
import LoginForm from "@/components/auth/LoginForm";
import SignUp from "@/components/auth/SignUp";
export default {
    props: ['block'],
    components: {
        LoginForm,
        SignUp
    },
    data() {
        return {
            dialog: false,
            loginFormKey: false,
            signUpFormKey: false
        }
    },
    methods: {
        closeDialog() {
            this.dialog = false;
            this.toggleKeys();
        },
        openDialog() {
            this.$emit("openDialog");
            this.toggleKeys();
            this.dialog = true;
        },
        openSignUpDialog() {
            this.dialog = false;
            this.$refs.SignUp.openDialog();
        },
        toggleKeys() {
            this.loginFormKey = !this.loginFormKey;
            this.signUpFormKey = !this.signUpFormKey;
        }
    }
}
</script>
