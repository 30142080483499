<template>
  <div class="d-flex flex-row justify-center">
    <!-- Button to toggle Card -->
    <v-btn
      :block="block"
      depressed
      color="primary"
      @click="openDialog"
      v-show="!hideButton"
    >
      {{ buttonText }}
    </v-btn>

    <v-dialog v-model="dialog" width="800px" :persistent="loading">
      <v-card class="d-flex flex-column" min-height="70vh">
        <v-window v-model="step" :key="windowKey">
          <!-- Header Steppers -->
          <v-stepper v-model="step" alt-labels>
            <v-stepper-header>
              <template v-for="(component, index) in windowComponents">
                <v-stepper-step :key="index" :step="parseInt(index) + 1">
                  {{ component.header }}
                </v-stepper-step>
                <v-divider
                  v-if="index !== windowComponents.length - 1"
                  :key="component.componentName"
                ></v-divider>
              </template>
            </v-stepper-header>
          </v-stepper>

          <!-- Window Components -->
          <v-window-item
            v-for="(component, index) in windowComponents"
            :key="index"
            :value="parseInt(index) + 1"
          >
            <component
              :is="component.componentName"
              class="pa-7"
              v-bind="{ ...component.props, packageId: packageIdForBookClass }"
              @success="handleSuccess"
              @started="setLoading(true)"
              @done="setLoading(false)"
            />
          </v-window-item>
        </v-window>

        <v-spacer></v-spacer>

        <!-- Cancel/Done Button-->
        <v-btn
          class="mt-3"
          :color="isLastStep ? 'success' : ''"
          @click="closeDialog"
          v-if="!loading"
        >
          {{ isLastStep ? "Done" : "Cancel" }}
        </v-btn>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import StripeCard from "@/components/stripe/StripeCard";
import PurchaseAgreement from "@/components/checkout/PurchaseAgreement";
import BookClassLoader from "@/components/checkout/BookClassLoader";
import SuccessStatus from "@/components/checkout/SuccessStatus";
import MobileNumber from "@/components/checkout/MobileNumber";
import { mapGetters, mapActions } from "vuex";
import {
  ALERT_MODULE_NAME,
  STUDIO_MODULE_NAME,
  AUTHENTICATION_MODULE_NAME,
} from "@/store/moduleNames";
import { auth, sendEmailVerification } from "@/firebase";
import getAuthErrorMessage from "@/util/authErrors";

// Analytics
import {
  BEGIN_CHECKOUT,
  CANCEL_CHECKOUT,
  FINISH_CHECKOUT,
} from "@/analytics/events";

export default {
  props: {
    packageItem: Object, // If a package purchase is involved
    classItem: Object, // if a class booking is involved
    packageId: String, // package used for booking class
    spotNumber: Number,
    buttonText: String,
    hideSpots: {
      default: false,
      type: Boolean,
    },
    block: {
      default: false,
      type: Boolean,
    },
    isPurchasePackage: {
      default: false,
      type: Boolean,
    },
    isCreditBooking: {
      default: false,
      type: Boolean,
    },
    isBookClass: {
      default: false,
      type: Boolean,
    },
    isPurchaseCourse: {
      default: false,
      type: Boolean,
    },
    courseItem: Object,
    hideButton: {
      default: false,
      type: Boolean,
    },
  },
  components: {
    PurchaseAgreement,
    StripeCard,
    BookClassLoader,
    SuccessStatus,
    MobileNumber,
  },
  data() {
    return {
      dialog: false,
      loading: false,
      step: 1,
      windowKey: false,
      sendVerificationEmailTimeout: 10000, // 10 seocnds buffer, to avoid sending too many emails
      canSendVerificationEmail: true,
      defaultWindowComponents: [
        {
          header: "Mobile Number",
          componentName: "MobileNumber",
        },
        {
          header: "Agreement Form",
          componentName: "PurchaseAgreement",
        },
        {
          header: "Buy Package",
          componentName: "StripeCard",
        },
        {
          header: "Book Class",
          componentName: "BookClassLoader",
        },
        {
          header: "Status",
          componentName: "SuccessStatus",
        },
      ],
      purchasedPackageId: "",
    };
  },
  computed: {
    ...mapGetters({
      studio: `${STUDIO_MODULE_NAME}/getStudio`,
      isUserAuth: `${AUTHENTICATION_MODULE_NAME}/isUserAuth`,
      isUserMobileNumberRegistered: `${AUTHENTICATION_MODULE_NAME}/isUserMobileNumberRegistered`,
    }),
    isEmailVerified() {
      return true;
    },
    packageIdForBookClass() {
      if (this.isPurchasePackage) {
        return this.purchasedPackageId;
      }
      if (this.isBookClass) {
        return this.packageId;
      }
      return "";
    },
    studioRequiresMobileNumber() {
      return this.studio.requirePhoneNumber;
    },
    windowComponents() {
      const components = this.defaultWindowComponents.map((component) => {
        switch (component.componentName) {
          case "MobileNumber":
            if (
              this.isPurchasePackage &&
              !this.isUserMobileNumberRegistered &&
              this.studioRequiresMobileNumber
            ) {
              return {
                ...component,
                props: {
                  studioName: this.studio.studioName,
                  country: this.studio.country,
                },
              };
            }
            return false;
          case "PurchaseAgreement":
            if (this.isPurchasePackage || this.isPurchaseCourse) {
              return {
                ...component,
                props: {
                  html: this.studio.agreementForm,
                },
              };
            }
            return false;
          case "StripeCard":
            if (this.isPurchasePackage) {
              return {
                ...component,
                props: {
                  packageItem: this.packageItem,
                  classId: this.classItem ? this.classItem.id : undefined,
                  spotNumber: this.spotNumber,
                },
              };
            } else if (this.isPurchaseCourse) {
              return {
                ...component,
                props: {
                  isPurchaseCourse: true,
                  courseItem: this.courseItem,
                },
              };
            }
            return false;
          case "BookClassLoader":
            if (this.isBookClass || this.isCreditBooking) {
              return {
                ...component,
                props: {
                  classId: this.classItem.id,
                  spotNumber: this.spotNumber,
                  hideSpots: this.hideSpots,
                  isCreditBooking: this.isCreditBooking,
                },
              };
            }
            return false;
          case "SuccessStatus":
            return {
              ...component,
              props: {
                packageId: this.purchasedPackageId,
              },
            };
          default:
            return false;
        }
      });
      return components.filter((component) => component);
    },
    isLastStep() {
      return this.step === this.windowComponents.length;
    },
  },
  methods: {
    ...mapActions({
      setAlertStateAction: `${ALERT_MODULE_NAME}/setAlertStateAction`,
    }),
    setLoading(val) {
      this.loading = val;
    },
    closeDialog() {
      this.dialog = false;
      if (this.step === this.windowComponents.length - 1) {
        this.$gtag.event(FINISH_CHECKOUT);
      } else {
        this.$gtag.event(CANCEL_CHECKOUT);
      }
      this.step = 1;
      this.toggleKey();
      this.$emit("closeDialog");
    },
    openDialog() {
      const isStripeActivated =
        this.studio.stripeAccountId != undefined &&
        (this.studio.stripeAccountId != undefined) != "";
      if (!isStripeActivated) {
        this.setStripeNotSetUpAlert();
        return;
      }

      if (!this.isUserAuth) {
        const payload = {
          type: "error",
          message:
            "Please login before purchasing a package or booking a class.",
        };
        this.setAlertStateAction(payload);
        return;
      }

      if (this.isUserAuth && !this.isEmailVerified) {
        this.sendVerificationEmail();
        this.$emit(
          "error",
          "Your email is not verified! A verification email has been sent."
        );
        return;
      }
      this.$gtag.event(BEGIN_CHECKOUT);
      this.dialog = true;
    },
    toggleKey() {
      this.windowKey = !this.windowKey;
    },
    setStripeNotSetUpAlert() {
      const payload = {
        type: "info",
        message: `${this.studio.studioName} has not setup any payment methods. Please contact the studio directly for enquiries.`,
      };
      this.setAlertStateAction(payload);
    },
    async sendVerificationEmail() {
      if (!this.canSendVerificationEmail) {
        return;
      }

      this.canSendVerificationEmail = false;
      let payload = {};
      await sendEmailVerification(auth.currentUser)
        .then(() => {
          payload = {
            type: "info",
            message:
              "You cannot purchase a package or book a class until you have verified your email. A verification email has been sent to your email.",
          };
        })
        .catch((error) => {
          payload = {
            type: "error",
            message: getAuthErrorMessage(error.code),
          };
        });
      this.setAlertStateAction(payload);
      setTimeout(() => {
        this.canSendVerificationEmail = true;
      }, this.sendVerificationEmailTimeout);
    },
    handleSuccess(packageId) {
      if (this.isPurchasePackage && packageId) {
        this.purchasedPackageId = packageId;
      }
      this.loading = false;
      this.step++;
    },
  },
};
</script>
