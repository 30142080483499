<template>
	<v-row class="mt-5">
		<v-row v-if="isMobileView" no-gutters>
			<v-col cols="12">
				<v-autocomplete
				ref="classesFilterMobile"
				v-model="filteredClasses"
				:items="allClasses"
				label="Classes"
				placeholder="Select..."
				required
				multiple
				outlined
				chips
				@change="onFilterChange"
				background-color="white"
				></v-autocomplete>
			</v-col>
			<v-col cols="12">
				<v-autocomplete
				ref="instructorsFilterMobile"
				v-model="filteredInstructors"
				:items="allInstructors"
				label="Instructors"
				placeholder="Select..."
				required
				multiple
				outlined
				chips
				@change="onFilterChange"
				background-color="white"
				></v-autocomplete>
			</v-col>
			<v-col cols="12">
				<v-autocomplete
				ref="locationsFilterMobile"
				v-model="filteredLocations"
				:items="allLocations"
				label="Locations"
				placeholder="Select..."
				required
				multiple
				outlined
				chips
				@change="onFilterChange"
				background-color="white"
				></v-autocomplete>
			</v-col>
			<v-col cols="12">
				<date-picker
				ref="dateFilterMobile"
				label="Start Date"
				v-model="startDate"
				:outlined="true"
				:min="new Date().toISOString().substring(0, 10)"
				@input="onFilterChange"
				/>
			</v-col>
		</v-row>
		<v-row v-else align-self="start" cols="3" sm="6" md="3">
			<v-col align-self="start" cols="3" sm="6" md="3">
				<v-autocomplete
				ref="classesFilter"
				v-model="filteredClasses"
				:items="allClasses"
				label="Classes"
				placeholder="Select..."
				required
				multiple
				outlined
				small-chips
				@change="onFilterChange"
				background-color="white"
				></v-autocomplete>
			</v-col>
			<v-col cols="3" sm="6" md="3">
				<v-autocomplete
				ref="instructorsFilter"
				v-model="filteredInstructors"
				:items="allInstructors"
				label="Instructors"
				placeholder="Select..."
				required
				multiple
				outlined
				small-chips
				@change="onFilterChange"
				background-color="white"
				></v-autocomplete>
			</v-col>
			<v-col cols="3" sm="6" md="3">
				<v-autocomplete
				ref="locationsFilter"
				v-model="filteredLocations"
				:items="allLocations"
				label="Locations"
				placeholder="Select..."
				required
				multiple
				outlined
				small-chips
				@change="onFilterChange"
				background-color="white"
				></v-autocomplete>
			</v-col>
			<v-col cols="3" sm="6" md="3">
				<date-picker
				ref="dateFilter"
				label="Start Date"
				v-model="startDate"
				:outlined="true"
				:min="new Date().toISOString().substring(0, 10)"
				@input="onFilterChange"
				/>
			</v-col>
		</v-row>
	</v-row>
</template>

<script>
import DatePicker from "@/components/shared/DatePicker.vue";

export default {
  props: {
    studio: Object,
    isMobileView: {
      type: Boolean,
      default: false,
    },
	type: String
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      filteredLocations: [],
      filteredClasses: [],
      filteredInstructors: [],
      startDate: "",
    };
  },
  computed: {
    allClasses() {
		const arr = ["All Classes"];
		const classes = this.type === 'course'
			? this.studio.courses
			: this.studio.classes.map(classItem => classItem.className);
		classes.forEach(classItem => arr.push(classItem));
		return arr;
    },
    allLocations() {
      let arr = ["All Locations"];
      let studioArr = this.studio.locations.map((location) => location.name);
      for (let item of studioArr) {
        arr.push(item);
      }
      return arr;
    },
    allInstructors() {
      let arr = ["All Instructors"];
      for (let item of this.studio.instructorNames) {
        arr.push(item);
      }
      return arr;
    },
  },
  methods: {
    isAllFilter() {
      if (this.filteredClasses.includes("All Classes")) {
        return true;
      }
      if (this.filteredInstructors.includes("All Instructors")) {
        return true;
      }
      if (this.filteredLocations.includes("All Locations")) {
        return true;
      }
    },
    async onFilterChange() {
		let filters = {
			studioId: this.studio.id,
			classFilter: this.isAllFilter() ? [] : this.filteredClasses,
			instructorFilter: this.isAllFilter() ? [] : this.filteredInstructors,
			locationFilter: this.isAllFilter() ? [] : this.filteredLocations,
			startDate: this.startDate,
			lastDocId: "",
		};
		this.$emit("onFilterChange", filters);
    },
  },
};
</script>
