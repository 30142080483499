import { functions, httpsCallable } from '@/firebase';

/**
 * Returns the Studio Document corresponding to webAppRoute.
 * @param {String} webAppRoute 
 * @returns Promise<Object>, Object { success, doc }
 */
function getStudioFromWebAppRoute(webAppRoute) {
    /// TODO: Check Store for studio document, if exists, compare the webAppRoute field, else query the studio document.
    // If same then we use the store document, else query another studio document
    const getStudio = httpsCallable(functions, 'getStudioFromWebAppRoute');
    return getStudio({ webAppRoute }).then((result) => result.data);
}

export default getStudioFromWebAppRoute;
