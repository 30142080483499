<template>
  <div>
    <ProgressLoader v-if="loading" />
    <v-col v-if="!loading" align="center" class="pb-0">
      <lottie-animation
        v-if="success"
        class="animation"
        path="lottie/sad-email.json"
        :height="dimensions"
        :width="dimensions"
      />
      <v-img
        v-if="error"
        src="@/assets/error-bg.gif"
        max-width="400px"
        max-height="400px"
      />
      <h1 v-if="success" v-bind:class="messageClass">
        We're sorry to see you go!
      </h1>
      <p v-bind:class="messageClass">{{ message }}</p>
      <v-col align="center">
        <p v-if="isTestPage">This is a test page</p>
      </v-col>
    </v-col>
  </div>
</template>

<script>
import ProgressLoader from "@/components/shared/ProgressLoader";
import { unsubscribe } from "@/util/cloudFunctions/marketing";
import LottieAnimation from "lottie-vuejs/src/LottieAnimation.vue"; // import lottie-vuejs

export default {
  components: {
    ProgressLoader,
    LottieAnimation,
  },
  props: {
    userId: String,
    studioId: String,
  },
  data() {
    return {
      loading: true,
      message: "",
      studioName: "",
      status: "",
      messageClass: "success--text",
      errorMessage:
        "Unsubscribe failed. Please email us at contact@vibefam.com for help.",
    };
  },
  computed: {
    successMessage() {
      return `You have successfully unsubscribed from marketing emails from ${this.studioName}.`;
    },
    isTestPage() {
      return this.userId === "test";
    },
    dimensions() {
      return this.$vuetify.breakpoint.mdAndUp ? 500 : 350;
    },
    success() {
      return this.status === "success";
    },
    error() {
      return this.status == "error";
    },
  },
  methods: {
    onError() {
      this.loading = false;
      this.message = this.errorMessage;
      this.messageClass = "error--text";
      this.status = "error";
    },
    onSuccess(studioName) {
      this.studioName = studioName;
      this.message = this.successMessage;
      this.messageClass = "success--text";
      this.status = "success";
    },
  },
  async created() {
    if (!this.userId || !this.studioId) {
      this.onError();
      return;
    }
    const { success, studioName } = await unsubscribe(
      this.studioId,
      this.userId
    );
    this.loading = false;
    if (!success) {
      this.onError();
      return;
    }
    this.onSuccess(studioName);
  },
};
</script>
