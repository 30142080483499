<template>
    <ValidationObserver ref="observer">
        <v-form ref="form">

            <!-- Email Field -->
            <ValidationProvider
                v-slot="{ errors }"
                name="Email"
                rules="required|max:64|email"
            >
                <text-trimmer
                    label="Email"
                    v-model="email"
                    prepend-icon="mdi-account-outline"
                    :error-messages="errors"
                ></text-trimmer>
            </ValidationProvider>

            <!-- Password Field -->
            <ValidationProvider
                name="Password"
                rules="required|max:64"
                v-slot="{ errors }"
            >
                <v-text-field
                    label="Password"
                    v-model="password"
                    prepend-icon="mdi-lock"
                    :error-messages="errors"
                    :append-icon="passwordToggle ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="() => (passwordToggle = !passwordToggle)"
                    :type="passwordToggle ? 'password' : 'text'"
                ></v-text-field>
            </ValidationProvider>

            <p class="error--text text-center py-3" v-if="error !== ''">{{ error }}</p>

            <v-row justify="center" class="mt-3">
                <v-btn
                    class="primary auth-btn"
                    @click="submit"
                    :loading="loading"
                    depressed
                    ref="loginButton"
                >
                    Login
                </v-btn>
            </v-row>

        </v-form>
    </ValidationObserver>
</template>

<script>
import { auth, signInWithEmailAndPassword } from '@/firebase';
import getAuthErrorMessage from "@/util/authErrors";
import { extend, ValidationProvider, ValidationObserver, setInteractionMode } from "vee-validate";
import { required, email, max } from "vee-validate/dist/rules";
import TextTrimmer from "@/components/shared/TextTrimmer.vue";

extend("required", {
    ...required,
    message: "{_field_} cannot be empty",
});
extend("max", {
    ...max,
    message: "{_field_} cannot not be greater than {length} characters",
});
extend("email", {
    ...email,
    message: "Email must be valid",
});
setInteractionMode("eager"); //eager form validation

// Analytics
import { LOGIN, LOGIN_ERROR } from "@/analytics/events";
import { subStr } from "@/analytics/util";

export default {
    components: {
        ValidationProvider,
        ValidationObserver,
        TextTrimmer,
    },
    data() {
        return {
            password: "",
            email: "",
            error: "",
            loading: false,
            passwordToggle: true,
        }
    },
    methods: {
        async validate() {
            return await this.$refs.observer.validate();
        },
        async submit() {
            this.error = "";
            this.loading = true;
            
            const isValid = await this.validate();

            if (!isValid) {
                this.loading = false;
                return;
            }

            signInWithEmailAndPassword(auth, this.email, this.password)
                .then(() => {
                    setTimeout(() => {
                        this.$gtag.event(LOGIN);
                        this.$emit('success');
                    }, 1000);
                })
                .catch((error) => {
                    this.$gtag.event(LOGIN_ERROR, { errorMessage: subStr(error.code) });
                    this.error = getAuthErrorMessage(error.code);
                    this.loading = false;
                });
        },
    }
}

</script>
