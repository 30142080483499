<template>
    <div>
        <h2 class="font-weight-medium mb-3">
            Video
        </h2>

        <video width="500px" controls>
            <source :src="studio.studioVideo">
        </video>

    </div>

</template>
<script>
export default {
    props: {
        studio: Object,
    },
}
</script>
