import { gettersStudio } from "@/store/moduleComponents/studio/gettersStudio";
import { actionsStudio } from "@/store/moduleComponents/studio/actionsStudio";
import { mutationsStudio } from "@/store/moduleComponents/studio/mutationsStudio";

export const studioModule = {
    namespaced: true,
    state: { 
        studio: null,
        loading: true,
    },
    getters: gettersStudio,
    actions: actionsStudio,
    mutations: mutationsStudio
}
