<template>
  <v-card
    class="white pb-6 pt-6 mb-1 px-9 mb-2"
    align-self-center="true"
    :to="isClickable ? `courses/${subItem.id}` : ``"
    flat
    width="100vw"
    min-height="15vh"
    v-if="isMobileView"
  >
    <v-layout>
      <v-layout row wrap>
        <v-flex xs10>
          <v-card-text class="py-2"
            >{{ subItem.timeStart }} - {{ subItem.timeEnd }}</v-card-text
          >
        </v-flex>
        <v-flex xs10>
          <v-card-text class="py-2 primary--text">{{
            subItem.courseName
          }}</v-card-text>
        </v-flex>
        <v-flex>
          <v-card-text class="py-2"
            ><v-icon class="mr-1">mdi-account-circle</v-icon>
            {{ subItem.instructor.name }}</v-card-text
          >
        </v-flex>
        <v-flex xs10>
          <v-card-text class="py-2"
            ><v-icon class="mr-1">mdi-map-marker</v-icon>
            {{ subItem.location }}</v-card-text
          >
        </v-flex>
      </v-layout>
      <v-flex width="50px" class="mt-6" v-if="bookBtn" align-self-end>
        <v-btn
          :disabled="isDisabled(subItem)"
          outlined
          text
          class="primary text-capitalize white--text"
          link
          :to="`courses/${subItem.courseOfferingId}`"
        >
          Book Course
        </v-btn>
        <p class="card-font ml-3 text--secondary font-italic">
         {{availability}}
        </p>
      </v-flex>
    </v-layout>
  </v-card>
  <v-card
    class="card-flex white mb-2 pr-2"
    align-self-center="true"
    flat
    width="100vw"
    height="15vh"
    :hover="isClickable"
    :to="isClickable ? `courses/${subItem.id}` : ``"
    v-else
  >
    <v-layout class="pl-2 pr-2" align-content-center justify-center>
      <v-flex xs9 align-self-center>
        <v-card-text class="py-2"
          >{{ subItem.timeStart }} - {{ subItem.timeEnd }}</v-card-text
        >
      </v-flex>
      <v-flex xs12 align-self-center>
        <v-card-text class="py-2 primary--text">{{
          subItem.courseName
        }}</v-card-text>
      </v-flex>
      <v-flex xs9 align-self-center>
        <v-card-text class="py-2">{{ subItem.instructor.name }}</v-card-text>
      </v-flex>
      <v-flex xs9 align-self-center>
        <v-card-text class="py-2">{{ subItem.location }}</v-card-text>
      </v-flex>
      <v-flex xs6 v-if="bookBtn" align-self-center>
        <v-btn
          :disabled="isDisabled(subItem)"
          outlined
          text
          class="primary card-center text-capitalize white--text"
          link
          :to="`courses/${subItem.courseOfferingId}`"
        >
          Book Course
        </v-btn>
        <v-card-text class="card-font text--secondary font-italic">
          {{ availability }}
        </v-card-text>
      </v-flex>
    </v-layout>
  </v-card>
</template>

<script>
export default {
  props: {
    subItem: Object,
    isClickable: {
      type: Boolean,
      default: false,
    },
    bookBtn: {
      type: Boolean,
      default: true,
    },
    isMobileView: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    availability() {
      if (this.subItem.capacity !== undefined) {
        return `${
          this.subItem.capacity - this.subItem.registrationCount
        } / ${this.subItem.capacity} Available`;
      } else {
        return "Available";
      }
    },
  },
  methods: {
    isDisabled(item) {
      return item.registrationCount === item.capacity;
    },
  },
};
</script>

<style scoped>
.card-center {
  margin-bottom: -10px !important;
  margin-top: 40px;
  margin-left: 5px;
}

.card-font {
  font-size: 13px;
}

.card-font-cancel {
  font-size: 13px;
  margin-top: 10px;
}

.card-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}
</style>