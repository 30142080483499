<template>
  <div>
    <v-btn text class="mb-5" @click="navigateToMyOrders"><v-icon left>mdi-arrow-left</v-icon> Back to My Orders</v-btn>
    <ViewOrderItems :orderId="orderId" />
  </div>
</template>
<script>
import ViewOrderItems from '@/components/orders/ViewOrderItems.vue';

export default {
  components: {
    ViewOrderItems,
  },
  computed: {
    orderId() {
      return this.$route.params.orderId;
    },
  },
  methods: {
    navigateToMyOrders() {
      const studioRoute = this.$route.params.studioRoute;
      const hash = this.$route.hash;
      this.$router.push(`/${studioRoute}/myorders${hash}`);
    },
  }
}
</script>
