<template>
    <div class="overlay">
        <GmapMap
            ref="mapRef"
            :center="center"
            :zoom="12"
            style="position: absolute; width: 100%; height: 100%; overflow: hidden; visibility: inherit;"
            map-type-id="roadmap"
            :options="{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
                disableDefaultUi: false,
                clickableIcons: false,
                styles: googleMapStyles,
            }"
            @click="clickOutside"
        >
            <GmapMarker
                :key="index"
                v-for="(studio, index) in filteredStudios"
                :position="studio.position"
                :clickable="true"
                @click="displayStudio(studio)"
            />
        </GmapMap>
        <div class="searchThisArea">
            <v-btn
                rounded
                class="primary--text"
                elevation="10"
                @click="emitCenter"
            >
                Search this area
            </v-btn>
        </div>
        <v-fade-transition>
            <div class="popUp" v-if="selectedStudio">
                <NewStudioCard :isPopUpMode="true" :studio="selectedStudio" />
            </div>
        </v-fade-transition>
    </div>
    
</template>
<script>
import NewStudioCard from './NewStudioCard.vue';

export default {
    components: {
        NewStudioCard
    },
    props: {
        studios: Array,
        center: Object,
    },
    computed: {
        filteredStudios() {
            return this.studios
                .filter(studio => studio.location.coordinates && studio.location.coordinates.longitude && studio.location.coordinates.latitude)
                .map(studio => ({ ...studio, position: { lat: studio.location.coordinates.latitude, lng: studio.location.coordinates.longitude } }));
        },
    },
    data() {
        return {
            map: null, // will be the map object when mounted
            googleMapStyles: [
                {
                    featureType: "all",
                    stylers: [
                        { visibility: "off" }
                    ]
                },
                {
                    featureType: "road",
                    stylers: [
                        { visibility: "on" }
                    ]
                },
                {
                    featureType: "landscape",
                    stylers: [
                        { visibility: "on" }
                    ]
                },
                {
                    featureType: "water",
                    stylers: [
                        { visibility: "on" }
                    ]
                },
                {
                    featureType: "transit.line",
                    stylers: [
                        { visibility: "on" }
                    ]
                },
            ],
            selectedStudio: null,
        };
    },
    mounted() {
        this.$refs.mapRef.$mapPromise.then((map) => {
            this.map = map;
        })
    },
    methods: {
        displayStudio(studio) {
            this.selectedStudio = studio;
        },
        clickOutside() {
            this.selectedStudio = null;
        },
        emitCenter() {
            if (this.map) {
                const bounds = this.$refs.mapRef.$mapObject.getBounds();
                this.$emit("update",bounds);
            }
        },
    },
}
</script>
<style scoped>
.overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    display: block
}

.searchThisArea {
    z-index: 1000;
    position: absolute;
    top: 16px;
    display: flex;
    justify-content: center;
    left: 50%;
    transform: translateX(-50%);
    padding: 12px;
    border-radius: 5px;
}

.popUp {
    z-index: 1000;
    position: absolute;
    bottom: 16px;
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 430px;
    left: 50%;
    transform: translateX(-50%);
    padding: 12px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 8px 24px rgba(0,0,0,.32);
}
</style>
