import {db, doc, getDoc } from "@/firebase";
import { hasRegisteredForCourseOffering } from "../cloudFunctions/coursesFunctions";


export async function getCourseByCourseId(courseId) {
    const docRef = doc(db, 'course_offerings', courseId);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) return { success: false };
    const docData = {...docSnap.data(), id: courseId};
    const hasRegistered = await hasRegisteredForCourseOffering(courseId);
    const isBookable = hasRegistered.success ? !hasRegistered.hasRegistered : false;
    const reason = isBookable ? '' : 'You have signed up for this course already';
    return {doc: docData, success: true, isBookable, reason};
}