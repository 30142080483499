import { functions, httpsCallable } from '@/firebase';

/**
 * Gets available normal packages.
 */
function getNormalPackages(studioId) {
    const getNormalPackagesFn = httpsCallable(functions, 'getAvailableMemberships');
    return getNormalPackagesFn({ studioId }).then((result) => result.data);
}

/**
 * Gets available recurring packages.
 */
function getRecurringPackages(studioId) {
    const getRecurringPackagesFn = httpsCallable(functions, 'getAvailableRecurringMemberships');
    return getRecurringPackagesFn({ studioId }).then((result) => result.data);
}

/**
 * Gets available user's memberships packages.
 */
function getMyMemberships(studioId) {
    const getMyPackagesFn = httpsCallable(functions, 'getMyMemberships');
    return getMyPackagesFn({ studioId }).then((result) => result.data).catch();
}


export { getNormalPackages, getRecurringPackages, getMyMemberships };
