<template>
  <v-col no-gutters class="py-1">
    <p class="primary--text mb-1">
      {{ title }}
    </p>
    <p>
      {{ content }}
    </p>
  </v-col>
</template>
<script>
export default {
  props: ["title", "content"],
};
</script>
