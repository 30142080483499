var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"overlay"},[_c('GmapMap',{ref:"mapRef",staticStyle:{"position":"absolute","width":"100%","height":"100%","overflow":"hidden","visibility":"inherit"},attrs:{"center":_vm.center,"zoom":12,"map-type-id":"roadmap","options":{
            zoomControl: true,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            disableDefaultUi: false,
            clickableIcons: false,
            styles: _vm.googleMapStyles,
        }},on:{"click":_vm.clickOutside}},_vm._l((_vm.filteredStudios),function(studio,index){return _c('GmapMarker',{key:index,attrs:{"position":studio.position,"clickable":true},on:{"click":function($event){return _vm.displayStudio(studio)}}})}),1),_c('div',{staticClass:"searchThisArea"},[_c('v-btn',{staticClass:"primary--text",attrs:{"rounded":"","elevation":"10"},on:{"click":_vm.emitCenter}},[_vm._v(" Search this area ")])],1),_c('v-fade-transition',[(_vm.selectedStudio)?_c('div',{staticClass:"popUp"},[_c('NewStudioCard',{attrs:{"isPopUpMode":true,"studio":_vm.selectedStudio}})],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }