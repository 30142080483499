<template>
  <v-col>
    <v-row class="justify-center mb-5">
      <h3 class="ml-2">Fill in Mobile Number</h3>

      <v-col cols="12">
        <p class="text-center">
          {{ studioName }} would like to collect your mobile number for contact
          purposes
        </p>
      </v-col>
      <v-col cols="12" md="6">
        <vue-phone-number-input
          v-model="input"
          :only-countries="countries"
          :error="true"
          :required="true"
        />

        <p class="error--text text-center my-2">{{ error }}</p>
      </v-col>

      <v-col cols="12" align="center">
        <v-btn class="primary depressed" @click="submit()" :loading="loading">Submit</v-btn>
      </v-col>
    </v-row>
  </v-col>
</template>
            
<script>
import parsePhoneNumber from "libphonenumber-js";
import {
  isValidPhoneNumber,
} from 'libphonenumber-js'

import { updateMobileNumber } from "@/util/cloudFunctions/userFunctions";
export default {
  props: {
    studioName: String,
    country: String,
  },
  data() {
    return {
      countries: [this.country],
      input: "",
      error: "",
      loading: false,
    };
  },
  methods: {
    submit() {
      if (this.loading) {
        return;
      }
      if (!this.input) {
        this.error = "Please key in mobile number";
        return;
      }
      const isValid = isValidPhoneNumber(this.input, this.country);
      if (!isValid) {
        this.error = "Invalid mobile number";
        return;
      }
      this.error = '';
      const phoneNumberObj = parsePhoneNumber(this.input, this.country);
      const phoneNumber = phoneNumberObj.number;
      this.loading = true;
      return updateMobileNumber(phoneNumber).then((res) => {
      this.loding = false;
      if (res.success) {
        this.$emit("success");
      } else {
        this.error = res.error;
      }
      });

    },
  },
};
</script>