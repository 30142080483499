import { functions, httpsCallable } from '@/firebase';

export function getUpcomingUserCourseSessions(studioId) {
    const getClassesByStudio = httpsCallable(functions, 'getMyUpcomingCourseSessions');
    const data = {};
    if (studioId) {
        data.studioId = studioId;
    }
    return getClassesByStudio(data).then(result => result.data)
}

export function getPastUserCourseSessions(lastDocId, studioId) {
    const userData = { lastDocId };
    if (studioId) {
        userData.studioId = studioId;
    }
    const getMyPastClasses = httpsCallable(functions, 'getMyPastCourseSessions');
    return getMyPastClasses(userData).then(result => result.data);    
}

export function getCourseSessionsFromStudioId(data) {
    const { studioId, courseNameFilter, 
        instructorFilter, locationFilter, startDate, endDate, lastDocId } = data;
    const getClassesByStudio = httpsCallable(functions, 'getCourseSessionsByStudio');
    const callData = { studioId, courseNameFilter, instructorFilter, locationFilter, startDate, endDate, lastDocId };
    return getClassesByStudio(callData).then(result => result.data)
}

export async function hasRegisteredForCourseOffering(courseOfferingId) {
    const callData = {courseOfferingId};
    const hasRegisteredFunction = httpsCallable(functions, 'hasRegisteredForCourseOffering');
    return hasRegisteredFunction(callData).then(result => result.data);
}

export async function getDiscountedCoursePrice(courseOfferingId, code) {
    const data = { courseOfferingId, code };
    const fn = httpsCallable(functions, 'getDiscountedCoursePrice');
    return fn(data).then(result => result.data);
}