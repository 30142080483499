<template>
  <div>
    <v-container class="d-flex flex-wrap justify-center">
      <v-row justify="center" align-content="center" align-items="center">
        <v-col cols="12">
          <v-row no-gutters>
            <v-avatar size="56">
              <img
                :src="this.classObject.instructor.image"
                :alt="this.classObject.instructor.image"
              />
            </v-avatar>
            <div class="profile-name">
              {{ this.classObject.instructor.name }}
            </div>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <p class="date-time font-weight-bold">Date</p>
          {{ dateClass }}
        </v-col>

        <v-col cols="12" md="6">
          <p class="date-time font-weight-bold">Time</p>
          {{ startTimeClass }} to {{ endTimeClass }}
        </v-col>

      </v-row>

      <v-btn
        text
        v-if="isUserAuth && classIsBookable && isCreditBookable"
        x-large
        class="secondary btn-style black--text"
        @click="submitCreditBooking"
      >
        Book Class with {{ classObject.creditsRequired }} credits
      </v-btn>
      <v-btn text v-if="classIsDisabled" class="btn-style-disabled" disabled
        >Book Class</v-btn
      >
      <h5
        v-if="classIsDisabled && isUserAuth"
        class="refund-text text--secondary"
      >
        {{ reason }}
      </h5>
      <h5
        v-if="isUserAuth && (classIsWaitlist || classIsBookable)"
        class="refund-text text--secondary"
      >
        {{ refundMessage }}
      </h5>
      <p v-if="!isUserAuth" class="login-text font-weight-bold text--secondary">
        Please login before proceeding.
      </p>
    </v-container>
  </div>
</template>

<script>
import {
  dateStringFromTimestamp,
  timeStringFromTimestamp,
} from "@/util/dateformat.js";
import {
  STUDIO_MODULE_NAME,
  AUTHENTICATION_MODULE_NAME,
} from "@/store/moduleNames";
import { mapGetters } from "vuex";

// Analytics
import { SELECT_PACKAGE } from "@/analytics/events";

export default {
  props: {
    classObject: Object,
    isBookable: Boolean,
    reason: String,
    normalMemberships: Array,
    recurringMemberships: Array,
    myMemberships: Array,
  },
  data() {
    return {
      selectedPackage: null,
      error: "",
    };
  },
  computed: {
    isCreditBookable() {
      return this.classObject.creditBookable === true;
    },
    hideSpots() {
      return this.classObject.hideSpots === true;
    },
    ...mapGetters({
      isUserAuth: `${AUTHENTICATION_MODULE_NAME}/isUserAuth`,
      studio: `${STUDIO_MODULE_NAME}/getStudio`,
      credits: `${AUTHENTICATION_MODULE_NAME}/getCredits`,
    }),
    //Purple button with "Book Class"
    classIsBookable() {
      return this.isBookable;
    },
    //Yellow button with "Waitlist Class"
    classIsWaitlist() {
      return !this.isBookable && this.reason === "Class is full";
    },
    classIsDisabled() {
      return (
        !this.isUserAuth ||
        (!this.isBookable && this.reason !== "Class is full")
      );
    },
    data() {
      return {
        selectedPackage: null,
        error: "",
      };
    },
    allMemberships() {
      const memberships = [];
      if (this.isUserAuth && this.myMemberships.length > 0) {
        memberships.push({ header: "My Packages" }, ...this.myMemberships);
      }
      if (this.normalMemberships.length > 0) {
        memberships.push({ header: "Packages" }, ...this.normalMemberships);
      }
      if (this.recurringMemberships.length > 0) {
        memberships.push(
          { header: "Recurring Packages" },
          ...this.recurringMemberships
        );
      }
      return memberships;
    },
    packageHint() {
      const packageItem = this.selectedPackage;

      if (!packageItem) {
        return "";
      }

      if (this.isMyPackage(packageItem)) {
        return `You have ${packageItem.classesLeft} / ${packageItem.numberClasses} classes left`;
      }
      const currency = this.studio.currency ?? "$";
      return `You can purchase this package for ${currency}${packageItem.price}`;
    },
    refundMessage() {
      return `Refunds class credit if class is cancelled ${this.studio.refundPolicy} hours before class starts.`;
    },
    dateClass() {
      return dateStringFromTimestamp(this.classObject.startTimestamp);
    },
    startTimeClass() {
      return timeStringFromTimestamp(this.classObject.startTimestamp);
    },
    endTimeClass() {
      return timeStringFromTimestamp(this.classObject.endTimestamp);
    },
  },
  watch: {
    isUserAuth: {
      handler() {
        if (this.isMyPackage(this.selectedPackage)) {
          this.selectedPackage = null;
        }
        this.onSelectPackage();
      },
    },
  },
  methods: {
    submitCreditBooking() {
      const isCreditBooking = true;
      this.$emit("click", isCreditBooking);
    },
    isMyPackage(packageItem) {
      return this.myMemberships.includes(packageItem);
    },
    onSelectPackage() {
      const isMyPackage = this.isMyPackage(this.selectedPackage);
      this.$gtag.event(SELECT_PACKAGE);
      this.$emit("input", this.selectedPackage, isMyPackage);
    },
    parsePackageName(membership) {
      const { name, expiresOn, classesLeft, numberClasses } = membership;
      if (expiresOn && classesLeft !== undefined && numberClasses) { // in case classesLeft is 0
        const dateStr = dateStringFromTimestamp(expiresOn);
        return `${name}, expires on ${dateStr} (${classesLeft}/${numberClasses} classes left)`;
      }
      return name;
    }
  },
};
</script>

<style scoped>
.profile-name {
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 30px;
  /* or 150% */

  display: flex;
  align-items: center;
  text-align: center;
  margin-left: 15px;
}

.btn-style {
  width: inherit;
  height: 80px !important;
  margin-bottom: 10px;
  margin-top: 10px;
}

.btn-style-disabled {
  width: inherit;
  height: 80px !important;
  margin-bottom: 10px;
  margin-top: 10px;
  border: 1px solid rgb(196, 195, 195);
}

.refund-text {
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 30px;
  margin-top: 10px;
  /* or 167% */

  display: flex;
  align-items: center;
}

.login-text {
  text-align: center;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;

  display: flex;
  align-items: center;
}

.date-time {
  font-size: 18px;
}

.cost-message {
  margin-top: -20px;
}
</style>
