<template>
    <v-dialog
        v-model="dialog"
        persistent
        width="400"
    >
        <v-card>
            <v-img :src="require('@/assets/payment-loading.gif')" />
            <h3 class="text-center mb-0 pb-3">{{ text }}</h3>
            <v-card-text>
                <slot></slot>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
export default {
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        text: {
            type: String,
            default: "",
        },
    },
    computed: {
        dialog: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit("input", value);
            },
        },
    },
}
</script>
