import Vue from "vue";
import Vuex from "vuex";
import { authenticationModule } from "@/store/modules/authenticationModule";
import { alertModule } from "@/store/modules/alertModule";
import { studioModule } from "@/store/modules/studioModule";
import { globalModule } from "@/store/modules/globalModule";
import { AUTHENTICATION_MODULE_NAME, ALERT_MODULE_NAME, STUDIO_MODULE_NAME } from "@/store/moduleNames";
import { GLOBAL_MODULE_NAME } from "./moduleNames";

Vue.use(Vuex);

export const store = new Vuex.Store({
    modules: {
        [AUTHENTICATION_MODULE_NAME]: authenticationModule,
        [ALERT_MODULE_NAME]: alertModule,
        [STUDIO_MODULE_NAME]: studioModule,
        [GLOBAL_MODULE_NAME]: globalModule,
    },
});