import { render, staticRenderFns } from "./NewStudioCard.vue?vue&type=template&id=4efc0cbd&scoped=true&"
import script from "./NewStudioCard.vue?vue&type=script&lang=js&"
export * from "./NewStudioCard.vue?vue&type=script&lang=js&"
import style0 from "./NewStudioCard.vue?vue&type=style&index=0&id=4efc0cbd&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4efc0cbd",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSlideYTransition } from 'vuetify/lib/components/transitions';
installComponents(component, {VDivider,VImg,VRow,VSlideYTransition})
