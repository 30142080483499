import { functions, httpsCallable } from '@/firebase';

async function getMyOrders(studioId) {
    const getMyOrdersFn = httpsCallable(functions, 'getMyOrders');
    return getMyOrdersFn({ studioId }).then(result => result.data).catch((error) => ({ success: false, error }));
}

async function getMyOrder(orderId) {
    const getMyOrderFn = httpsCallable(functions, 'getMyOrder');
    return getMyOrderFn({ orderId }).then(result => result.data).catch((error) => ({ success: false, error }));
}

async function getOrderItems(orderId) {
    const getOrderItemsFn = httpsCallable(functions, 'getOrderItems');
    return getOrderItemsFn({ orderId }).then(result => result.data).catch((error) => ({ success: false, error }));
}

export { getMyOrders, getMyOrder, getOrderItems };
