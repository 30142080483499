<template>
    <v-select 
        solo
        dense
        rounded
        label="Amenities"
        multiple
        hide-details
        :items="items"
        v-model="selected"
    ></v-select>
</template>
<script>
export default {
    data() {
        return {
            items: [
                { text: "Showers", value: "Showers" },
                { text: "Lockers", value: "Lockers" },
                { text: "Parking", value: "Parking" },
                { text: "Mat", value: "Mat" },
                { text: "Towel", value: "Towel" },
            ],
            selected: [],
        }
    },
    methods: {
        onSelected() {
            this.$emit("update", this.selected);
        }
    },
    watch: {
        selected() {
            this.onSelected();
        },
    },
}
</script>
