<template>
    <v-col>
        <EmailLoginForm @success="emitSuccess" />
        <v-row justify="center" align="center" class="pt-7">
            <ForgetPassword />
        </v-row>
        <v-divider class="mt-10 mb-7"></v-divider>
        <FacebookLogin @success="emitSuccess" />

        <v-row justify="center" align="center" class="pt-5">
            Don't have an account?
            <v-btn text small @click="emitSignUp" ref="signUpButton">
                Sign Up Here
            </v-btn>
        </v-row>
    </v-col>
</template>
<script>
import EmailLoginForm from "@/components/auth/EmailLoginForm";
import ForgetPassword from "@/components/auth/ForgetPassword";
import FacebookLogin from "@/components/auth/FacebookLogin"

export default {
    components: {
        EmailLoginForm,
        ForgetPassword,
        FacebookLogin,
    },
    methods: {
        emitSuccess() {
            this.$emit('success');
        },
        emitSignUp() {
            this.$emit('signUp');
        }
    }
}
</script>
