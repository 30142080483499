<template>
    <div class="background-white">
        <v-row no-gutters class="pa-3">
            <div class="studioImage">
                <v-img 
                    :src="classObject.images[0]" 
                    :aspect-ratio="3/2"
                    style="border-radius: 5px;"
                ></v-img>
            </div>
            <div class="studioMiddleDescription">
                <div class="studioCategory text-truncate">
                    {{ classObject.studioName }}
                </div>
                <div class="studioName text-truncate" @click="navigateToClassPage()">
                    {{ classObject.name }}
                </div>
                <div class="classTime my-1">
                    <v-icon dense>mdi-clock-outline</v-icon>
                    <span class="font-weight-bold">{{ timeStringFromTimestamp(classObject.startTimestamp.toDate()) }} - {{ timeStringFromTimestamp(classObject.endTimestamp.toDate()) }}</span>
                </div>
                <div class="studioAddress text-truncate my-1">
                    <v-icon dense>mdi-map-marker</v-icon>
                    {{ studio.location.address }}
                </div>
                <div v-if="studio.averageRating" class="mt-5">
                    <span class="studioAverageRating">{{ studio.averageRating }}</span>
                    <StarSVG :fill="$vuetify.theme.themes.light.reviewGold" />
                    <span class="studioNumberReviews">({{ studio.numberReviews }})</span>
                </div>
                <div class="moreInfo primary--text" @click="toggleShowDescription(classObject)"> 
                    {{ classObject.showDescription ? "Less" : "More" }} Info
                    <DownwardCaretSVG />
                </div>
            </div>
            <div class="bookNowButton">
                <v-btn outlined @click="navigateToClassPage" color="primary">
                   Book for {{classObject.creditsRequired}} Credits
                </v-btn>
            </div>
        </v-row>
        <v-slide-y-transition>
            <div class="mt-3 px-3 studioDescription" v-if="classObject.showDescription">
                {{ classObject.description }}
            </div>
        </v-slide-y-transition>
    </div>
</template>
<script>
import StarSVG from "@/components/shared/StarSVG";
import DownwardCaretSVG from "@/components/shared/DownwardCaretSVG";

import { dateStringFromTimestamp, timeStringFromTimestamp } from "@/util/dateformat";

export default {
    components: {
        StarSVG,
        DownwardCaretSVG,
    },
    props: {
        classObject: Object, // classObject with additional studio object attached to it
    },
    computed: {
        studio() {
            return this.classObject.studio;
        },
        studioCategory() {
            if (this.studio.category) {
                return this.studio.category.join(", ");
            }
            return "";
        },
        truncatedClassDescription() {
            const LENGTH = 230;
            if (this.classObject.description.length > LENGTH) {
                return this.classObject.description.substring(0, LENGTH) + "...";
            }
            return this.classObject.description;
        },
        showMoreCaret() {
            if (this.isPopUpMode) {
                return true;
            }
            return this.$vuetify.breakpoint.width < 960;
        },
    },
    methods: {
        dateStringFromTimestamp,
        timeStringFromTimestamp,
        toggleShowDescription(classObject) {
            classObject.showDescription = !classObject.showDescription;
        },
        navigateToClassPage() {
            const studioRoute = this.studio.webAppRoute;
            const classId = this.classObject.id;
            this.$router.push(`/${studioRoute}/classes/${classId}`);
        },
    },
}
</script>
<style scoped>
.background-white {
    background-color: #FFF;

}
.studioCategory {
    font-size: .75rem;
    line-height: 1.33;
    font-weight: 700;
    text-transform: uppercase;
    color: #999;
}

.studioName {
    padding-top: 4px;
    color: #000; 
    font-size: 1rem;
    line-height: 1.5;
    font-weight: 500;
    cursor: pointer;
}

.studioAddress {
    line-height: 1;
    padding-top: 0;
    font-size: .875rem;
}

.studioAverageRating {
    font-size: .875rem;
    line-height: 1.4285714286;
    font-weight: 500;
    color: #000;
    margin-right: 4px;
}

.studioNumberReviews {
    color: #555;
    font-size: .875rem;
    line-height: 1.4285714286;
    letter-spacing: 0;
    margin-left: 4px;
}

.moreInfo {
    font-size: .875rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
}

.studioDescription {
    font-size: .875rem;
    line-height: 1.4285714286;
}

.studioImage {
    margin-right: 16px;
    width: 160px;

    @media screen and (max-width: 599px)   {
        width: 100px;
    }
}

.studioMiddleDescription {
    width: 200px;
}

.bookNowButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    flex-grow: 1;
    margin: 16px;
}

.instructorName {
    font-size: .875rem;
    line-height: 1.4285714286;
}

.classTime {
    line-height: 1.4285714286;
}

</style>

