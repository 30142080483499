<template>
  <v-col>
    <BackButton />

    <!-- Loading Skeleton -->
    <v-row v-if="loading">
      <v-col cols="12" md="6" v-for="i in 3" :key="i">
        <v-skeleton-loader class="mt-3" type="card" />
      </v-col>
    </v-row>

    <v-scroll-y-transition>
      <PackageList
        @sharePackage="setMyPackages"
        v-show="!loading"
        :packages="packages"
        :isBuyable="false"
        :recurringPackages="recurringPackages"
      />
    </v-scroll-y-transition>
  </v-col>
</template>

<script>
import PackageList from "@/components/packages/PackageList";
import BackButton from "@/components/shared/BackButton.vue";
import { mapGetters, mapActions } from "vuex";
import {
  AUTHENTICATION_MODULE_NAME,
  STUDIO_MODULE_NAME,
  ALERT_MODULE_NAME,
} from "@/store/moduleNames";
import { getMyMemberships } from "@/util/cloudFunctions/packagesFunctions";
export default {
  components: {
    PackageList,
    BackButton,
},
  computed: {
    ...mapGetters({
      isUserAuth: `${AUTHENTICATION_MODULE_NAME}/isUserAuth`,
      studio: `${STUDIO_MODULE_NAME}/getStudio`,
    }),
  },
  data() {
    return {
      loading: true,
      packages: [],
      recurringPackages: [],
    };
  },
  watch: {
    isUserAuth: {
      handler(value) {
        if (!value) {
          const payload = {
            type: "info",
            message: "Please login before proceeding",
          };
          this.setAlertStateAction(payload);
          const studioRoute = this.$route.params.studioRoute;
          this.$router.push(`/${studioRoute}`);
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions({
      setAlertStateAction: `${ALERT_MODULE_NAME}/setAlertStateAction`,
    }),
    async setMyPackages() {
      this.loading = true;
      const studioId = this.studio.id;
      const result = await getMyMemberships(studioId);

      if (result.success) {
        this.splitAndSetPackages(result.docs);
      }
      this.loading = false;
    },
    splitAndSetPackages(docs) {
      this.packages = docs.filter((doc) => !doc.isSubscription);
      this.recurringPackages = docs.filter((doc) => doc.isSubscription);
    },
  },
  created() {
    this.setMyPackages();
  },
};
</script>
