import { functions, httpsCallable } from '@/firebase';

async function addToCart(studioId, productId, quantity) {
    const addToCartFn = httpsCallable(functions, 'addToCart');
    return addToCartFn({ studioId, productId, quantity }).then(result => result.data).catch((error) => ({ success: false, error }));
}

async function clearCart(studioId) {
    const clearCartFn = httpsCallable(functions, 'clearCart');
    return clearCartFn({ studioId }).then(result => result.data).catch((error) => ({ success: false, error }));
}

async function getCartItems(studioId) {
    const getCartItemsFn = httpsCallable(functions, 'getCartItems');
    return getCartItemsFn({ studioId }).then(result => result.data).catch((error) => ({ success: false, error }));
}

async function getNumberOfCartItems(studioId) {
    const getNumberOfCartItemsFn = httpsCallable(functions, 'getNumberOfCartItems');
    return getNumberOfCartItemsFn({ studioId }).then(result => result.data).catch((error) => ({ success: false, error }));
}

async function updateCartItem(studioId, productId, quantity) {
    const updateCartItemFn = httpsCallable(functions, 'updateCartItem');
    return updateCartItemFn({ studioId, productId, quantity }).then(result => result.data).catch((error) => ({ success: false, error }));
}

export { addToCart, clearCart, getCartItems, getNumberOfCartItems, updateCartItem };
