export const gettersAuth = {
    isLoading(state) {
        return state.loading;
    },
    getUser(state) {
        return state.user;
    },
    getCredits(state) {
        return state.credits;
    },
    isCollectUserDetails(state) {
        return state.collectUserDetails;
    },
    isUserAuth(state) {
        return !!state.user;
    },
    isUserMobileNumberRegistered(state) {
        if (!state.user) {
            return false;
        }
        const user = state.user;
        const personalDetails = user.personalDetails;
        if (!personalDetails) {
            return false;
        }
        const mobileNumber = personalDetails.mobileNum;
        return !!mobileNumber;
    },
};
