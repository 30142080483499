<template>
    <svg viewBox="0 0 12.08 11.07" width="12.08px" height="11.07px" :fill="fill"><path d="M6.191 9.144a.3.3 0 0 0-.3 0l-3.26 1.882a.3.3 0 0 1-.44-.33l.855-3.509a.3.3 0 0 0-.1-.302L.109 4.533a.3.3 0 0 1 .167-.53l3.76-.305a.3.3 0 0 0 .25-.178L5.767.18a.3.3 0 0 1 .55 0l1.48 3.341a.3.3 0 0 0 .25.178l3.76.305a.3.3 0 0 1 .168.53L9.137 6.885a.3.3 0 0 0-.1.302l.855 3.509a.3.3 0 0 1-.442.33L6.191 9.144z"></path></svg>
</template>
<script>
export default {
    props: {
        fill: {
            type: String,
            default: "#555",
        }
    }
}
</script>
