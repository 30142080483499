// equatorial mean radius of Earth (in meters)
const R = 6378137
const asin = Math.asin
const cos = Math.cos
const sin = Math.sin
const sqrt = Math.sqrt
const PI = Math.PI


function squared (x) { return x * x }
function toRad (x) { return x * PI / 180.0 }
function hav (x) {
  return squared(sin(x / 2))
}

function haversine_distance(mk1, mk2) {
    const aLat = toRad(mk1.lat)
    const bLat = toRad(mk2.lat)
    const aLng = toRad(mk1.lng)
    const bLng = toRad(mk2.lng)
  
    const ht = hav(bLat - aLat) + cos(aLat) * cos(bLat) * hav(bLng - aLng)
    const distInMeters = 2 * R * asin(sqrt(ht))
    const distInKm = distInMeters / 1000;
    return distInKm;
}

export { haversine_distance }
