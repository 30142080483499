<template>
    <div>
        <Navbar v-if="!isWidget" />
        <ProgressLoader class="pt-3" v-if="isLoading && !isSetup" />
        <v-main v-else>
            <v-container class="global-container">
                <v-row class="fill-height">
                    <v-col>
                        <v-fade-transition>
                            <Alert v-show="hasAlert" :type="alertState.type" :message="alertState.message" class="py-5" />
                        </v-fade-transition>
                        <transition name="fade">
                            <router-view></router-view>
                        </transition>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </div>
</template>
<script>
import Navbar from '@/components/navbar/Navbar'
import ProgressLoader from "@/components/shared/ProgressLoader";
import Alert from "@/components/shared/Alert";
import { mapGetters, mapActions } from "vuex";
import { ALERT_MODULE_NAME, STUDIO_MODULE_NAME } from "@/store/moduleNames";
import { WIDGET_ROUTE } from "@/constants";


export default {
    components: {
        ProgressLoader,
        Navbar,
        Alert,
    },
    computed: {
        ...mapGetters({
            alertState: `${ALERT_MODULE_NAME}/getAlertState`,
            hasAlert: `${ALERT_MODULE_NAME}/hasAlert`,
            isLoading: `${STUDIO_MODULE_NAME}/isLoading`,
            isInvalid: `${STUDIO_MODULE_NAME}/isInvalid`,
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
        isWidget() {
            return this.$route.hash === WIDGET_ROUTE;
        },
    },
    watch: {
        $route(to, from) {
            // react to route changes...
            const oldWebAppRoute = from.params.studioRoute;
            const newWebAppRoute = to.params.studioRoute;
            if (oldWebAppRoute !== newWebAppRoute) {
                this.setCurrentStudio();
            }
        },
        isInvalid(value) {
            if (value) {
                this.$router.push('/404');
            }
        },
        isLoading(value) {
            if (!value) {
                this.isSetup = true;
            }
        },
    },
    methods: {
        ...mapActions({
            setStudioAction: `${STUDIO_MODULE_NAME}/setStudioAction`,
        }),
        setCurrentStudio() {
            const webAppRoute = this.$route.params.studioRoute;
            this.setStudioAction(webAppRoute);
        },
    },
    data() {
        return {
            isSetup: false,
        };
    },
    created() {
        this.setCurrentStudio();
    },
}
</script>