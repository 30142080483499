const moment = require('moment');

exports.dateStringFromTimestamp = dateStringFromTimestamp
exports.timeStringFromTimestamp = timeStringFromTimestamp
exports.dateTimeStringFromTimestamp = dateTimeStringFromTimestamp
exports.dateObjFromTimestamp = dateObjFromTimestamp
exports.getCurrentYear = getCurrentYear;

/**
 * @param {Timestamp} timestamp 
 * @returns date formatted to a human-readable date string
 */
function dateStringFromTimestamp(timestamp, format) {
    const formatter = format ?? "dddd, D MMM YYYY"
    return formatTimestamp(timestamp, formatter)
}

function getCurrentYear() {
    return moment().format("YYYY");
}

/**
 * @param {Timestamp} timestamp 
 * @returns date formatted to a human-readable time string
 */
function timeStringFromTimestamp(timestamp) {
    const formatter = "h:mm a"
    return formatTimestamp(timestamp, formatter)
}

/**
 * @param {Timestamp} timestamp 
 * @returns date formatted to a human-readable date and time string
 */
function dateTimeStringFromTimestamp(timestamp) {
    const dateFormat = dateStringFromTimestamp(timestamp)
    const timeFormat = timeStringFromTimestamp(timestamp)
    return `${dateFormat}, ${timeFormat}`
}

function formatTimestamp(timestamp, formatter) {
    let date;
    if (timestamp instanceof Date) {
        date = timestamp;
    } else {
        date = dateObjFromTimestamp(timestamp)
    }
    const momentDate = moment(date);
    return momentDate.format(formatter);
}

function dateObjFromTimestamp(timestamp) {
    return new Date(timestamp._seconds * 1000);
}
