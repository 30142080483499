import { functions, httpsCallable } from "@/firebase";

/**
 * Gets recent reviews.
 */
function getRecentReviews(studioId, lastDocId, count = 5) {
  const getRecentReviewsFn = httpsCallable(functions, "getRecentReviews");
  return getRecentReviewsFn({ studioId, lastDocId, count })
    .then((result) => {
      return result.data;
    })
    .catch(() => []);
}

export { getRecentReviews };
