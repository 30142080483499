<template>
    <ValidationObserver ref="observer">
        <div v-if="noEmailCase">
            <v-alert type="error">
                Your Facebook account does not contain an email. Please add your email to your Facebook account and reload this page to continue with account creation.<br>
            </v-alert>
            <v-col align="center">
                <a href="https://www.facebook.com/settings?tab=account&section=email&view" target="_blank">Update your Facebook Account Email</a>
            </v-col>
        </div>

        <v-form ref="form" v-else>

            <!-- Email Field -->
            <ValidationProvider
                v-if="!collectUserDetails"
                v-slot="{ errors }"
                name="Email"
                rules="required|max:64|email"
                :disabled="collectUserDetails"
            >
                <text-trimmer
                    aria-readonly="true"
                    label="Email"
                    v-model="email"
                    prepend-icon="mdi-email"
                    :error-messages="errors"
                ></text-trimmer>
            </ValidationProvider>

            <!-- Password Field -->
            <ValidationProvider
                v-if="!collectUserDetails"
                name="Password"
                rules="required|max:64"
                v-slot="{ errors }"
            >
                <v-text-field
                    label="Password"
                    v-model="password"
                    prepend-icon="mdi-lock"
                    :error-messages="errors"
                    :append-icon="passwordToggle ? 'mdi-eye-off' : 'mdi-eye'"
                    @click:append="() => (passwordToggle = !passwordToggle)"
                    :type="passwordToggle ? 'password' : 'text'"
                ></v-text-field>
            </ValidationProvider>

            <!-- Full Name -->
            <ValidationProvider
              v-slot="{ errors }"
              name="Full Name"
              :rules="{required:true, fullNameRegex:fullNameRegex, min:4}"
            >
                <text-trimmer
                    label="Full Name"
                    v-model="fullName"
                    prepend-icon="mdi-text"
                    :error-messages="errors"
                ></text-trimmer>
            </ValidationProvider>

            <!-- Username -->
            <ValidationProvider
                v-slot="{ errors }"
                name="Username"
                :rules="{required:true, usernameRegex:usernameRegex, min:4, max:17}"
            >
                <text-trimmer
                    label="Username"
                    hint="Username should be unique and can only contain alphanumeric characters, period(.) and underscore(_)"
                    persistent-hint
                    :error-messages="errors"
                    v-model="username"
                    prepend-icon="mdi-account"
                ></text-trimmer>
            </ValidationProvider>

            <!-- Date of Birth -->
            <ValidationProvider
              v-slot="{ errors }"
              name="Date of Birth"
              rules="required"
            >
              <date-picker
                label="Date of Birth"
                v-model="dob"
                :errors="errors"
                :max="new Date().toISOString().substr(0, 10)"
                :outlined="false"
                class="pt-3"
                icon="mdi-calendar"
              />
            </ValidationProvider>

             <!-- Country -->
            <ValidationProvider
                v-slot="{ errors }"
                name="Country"
                rules="required"
            >
                <v-select
                    label="Country"
                    :error-messages="errors"
                    v-model="country"
                    prepend-icon="mdi-earth"
                    :items="supportedCountries"
                    item-text="name"
                    item-value="code"
                />
            </ValidationProvider>

            <p class="error--text text-center py-3" v-if="error !== ''">{{ error }}</p>

            <v-row justify="center" class="my-3">
                <v-btn
                    class="primary auth-btn"
                    @click="submit"
                    :loading="loading"
                    depressed
                >
                    Sign Up
                </v-btn>
            </v-row>

            <!-- for facebook signup -->
            <v-divider v-if="!collectUserDetails" class="mt-10 mb-7"></v-divider>
            <FacebookLogin v-if="!collectUserDetails" />

        </v-form>
    </ValidationObserver>
</template>

<script>
import { auth, createUserWithEmailAndPassword, sendEmailVerification, deleteUser } from '@/firebase';
import addUserDocument from '@/util/cloudFunctions/authentication';
import getSupportedCountries from '@/util/cloudFunctions/countries';
import TextTrimmer from "@/components/shared/TextTrimmer.vue";
import DatePicker from "@/components/shared/DatePicker.vue";
import { fullNameRegex, usernameRegex } from "@/util/regex";
import getAuthErrorMessage from "@/util/authErrors";
import FacebookLogin from "@/components/auth/FacebookLogin"

import {
  extend,
  ValidationProvider,
  ValidationObserver,
  setInteractionMode,
} from "vee-validate";
import { required, email, max, min, regex } from "vee-validate/dist/rules";

extend("required", {
  ...required,
  message: "{_field_} cannot be empty",
});
extend("min", {
  ...min,
  message: "{_field_} cannot not be lesser than {length} characters",
});
extend("max", {
  ...max,
  message: "{_field_} cannot not be greater than {length} characters",
});
extend("email", {
  ...email,
  message: "Email must be valid",
});
extend("fullNameRegex", {
  ...regex,
  message: "{_field_} can only contain alpha characters and spaces",
});
extend("usernameRegex", {
  ...regex,
  message: "{_field_} should be unique and can only contain alphanumeric characters, period(.) and underscore(_)",
});
setInteractionMode("eager"); //eager form validation

import { mapActions } from "vuex";
import { AUTHENTICATION_MODULE_NAME } from "@/store/moduleNames";

// Analytics
import { SIGNUP, SIGNUP_ERROR, SIGNUP_FACEBOOK, SIGNUP_FACEBOOK_ERROR } from "@/analytics/events";
import { subStr } from "@/analytics/util";

export default {
    props: {
        collectUserDetails: {
            type: Boolean,
            default: false,
        },
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        TextTrimmer,
        DatePicker,
        FacebookLogin,
    },
    data() {
        return {
            email: "",
            password: "",
            fullName: "",
            username: "",
            dob: "",
            country: "SG",
            error: "",
            loading: false,
            passwordToggle: true,
            supportedCountries: [ { name: "Singapore", code: "SG" } ],
            fullNameRegex,
            usernameRegex,
            noEmailCase: false,
        }
    },
    methods: {
        ...mapActions({
            refreshUserDocument: `${AUTHENTICATION_MODULE_NAME}/refreshUserDocument`,
        }),
        async validate() {
            return await this.$refs.observer.validate();
        },
        async submit() {
            this.error = "";
            this.loading = true;

            const isValid = await this.validate();

            if (!isValid) {
                this.loading = false;
                return;
            }

            if (this.collectUserDetails) {
                const { uid, email } = auth.currentUser;
                const result = await addUserDocument(uid, email, this.fullName, this.username, this.dob, this.country);
                if (!result.success) {
                    this.$gtag.event(SIGNUP_FACEBOOK_ERROR, { errorMessage: subStr(result.error) });
                    this.error = result.error;
                    this.loading = false;
                    return;
                }
                sendEmailVerification(auth.currentUser);
                this.$gtag.event(SIGNUP_FACEBOOK);
                this.$emit('success');
                return;
            }
            
            createUserWithEmailAndPassword(auth, this.email, this.password)
                .then(async (response) => {
                    const result = await addUserDocument(response.user.uid, this.email, 
                        this.fullName, this.username, this.dob, this.country);

                    if (!result.success) {
                        await deleteUser(auth.currentUser);
                        this.$gtag.event(SIGNUP_ERROR, { errorMessage: subStr(result.error) });
                        this.error = result.error;
                        this.loading = false;
                        return;
                    }
                    this.refreshUserDocument();
                    sendEmailVerification(auth.currentUser);
                    this.$gtag.event(SIGNUP);
                    this.$emit('success');
                })
                .catch((error) => {
                    this.$gtag.event(SIGNUP_ERROR, { errorMessage: subStr(error.code) });
                    this.error = getAuthErrorMessage(error.code);
                    this.loading = false;
                });
        },
        async setSupportedCountries() {
            const result = await getSupportedCountries();
            if (result.success) {
                this.supportedCountries = result.countries;
            }
        }
    },
    created() {
        this.setSupportedCountries();
        if (this.collectUserDetails) {
            const email = auth.currentUser.email;
            // Account for case where Facebook login does not have email
            if (email) {
                this.fullName = auth.currentUser.displayName;
                this.username = email.split("@")[0];
            } else {
                this.noEmailCase = true;
            }
        }
    }
}
</script>
