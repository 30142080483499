import momentTimezone from "moment-timezone";

function convertTimestampToDate(timestamp) {
    return new Date(timestamp._seconds * 1000);
}


/**
 * Gets the day "Monday", "Tuesday", etc from the timestamp, in the timezone provided.
 * @param {Object} timestamp 
 * @param {String} timezone 
 * @returns 
 */
function getDayOfDate(timestamp, timezone) {
    const date = convertTimestampToDate(timestamp);
    return momentTimezone(date).tz(timezone).format("dddd");
}



/**
 * Checks if the timestamp is between the start and end time in the given timezone.
 * @param {Object} timestamp 
 * @param {String} startTime time in HH:mm format, so can be 06:00 and 23:00
 * @param {String} endTime time in HH:mm format, so can be 06:00 and 23:00
 * @param {String} timezone 
 * @returns 
 */
function isTimestampBetweenTime(timestamp, startTime, endTime, timezone) {
    const date = convertTimestampToDate(timestamp);
    const momentDate = momentTimezone(date).tz(timezone);
    const splitStartTime = startTime.split(":");
    const splitEndTime = endTime.split(":");
    const startDate = momentDate.clone().set({ h: splitStartTime[0], m: splitStartTime[1] });
    const endDate = momentDate.clone().set({ h: splitEndTime[0], m: splitEndTime[1] });
    return momentDate.isSameOrAfter(startDate) && momentDate.isSameOrBefore(endDate);
}

export { getDayOfDate, isTimestampBetweenTime };
