<template>
    <div>
        <v-col cols="12" lg="3">
            <h1>Shopping Cart</h1>
        </v-col>
        <div v-if="loading">
            <v-skeleton-loader class="my-3" height="100px" v-for="i in 3" :key="i" type="card"></v-skeleton-loader>
        </div>
        <v-fade-transition>
            <v-col v-if="!loading">
                <v-data-table
                    :headers="headers"
                    :items="items"
                    hide-default-footer
                >

                    <template v-slot:item.image="{ item }">
                        <v-img width="100px" :src="item.product.images[0]"></v-img>
                    </template>

                    <template v-slot:item.name="{ item }">
                        {{ item.product.name }}
                    </template>

                    <template v-slot:item.price="{ item }">
                        {{ item.product.price }}
                    </template>

                    <template v-slot:item.order="{ item }">
                        <vue-numeric-input
                            v-model="item.quantity"
                            @input="quantity => updateCart(item, quantity)"
                            class="numeric-input-btn-style"
                            :min="0"
                            align="center"
                        ></vue-numeric-input>
                    </template>

                    <template v-slot:item.actions="{ item }">
                        <v-icon @click="removeCartItem(item)">mdi-close</v-icon>
                    </template>

                </v-data-table>

                <div class="py-3 checkoutRow">
                    <h3>Total: {{ studio.displayCurrency }}{{ total }}</h3>
                    <v-spacer></v-spacer>
                    <CheckoutShoppingCart @success="onSuccess" :orders="items" />
                </div>

            </v-col>
        </v-fade-transition>
        

        
    </div>
</template>
<script>
import CheckoutShoppingCart from "@/components/merchandise/CheckoutShoppingCart";
import VueNumericInput from "vue-numeric-input";

import { getCartItems, updateCartItem } from "@/util/cloudFunctions/shoppingCart";
import { toTwoDp } from "@/util/dphelpers";

import { STUDIO_MODULE_NAME, ALERT_MODULE_NAME } from "@/store/moduleNames";
import { mapGetters, mapActions } from "vuex";

export default {
    components: {
        CheckoutShoppingCart,
        VueNumericInput,
    },
    created() {
        this.initData();
    },
    computed: {
        ...mapGetters({
            studio: `${STUDIO_MODULE_NAME}/getStudio`,
        }),
    },
    data() {
        return {
            headers: [
                { text: "Product", value: "image" },
                { text: "Product Name", value: "name" },
                { text: "Price", value: "price" },
                { text: "Quantity", value: "order" },
                { text: "", value: "actions" },
            ],
            items: [],
            products: {}, // key is productId, value is the product itself
            total: 0,
            loading: true,
        }
    },
    methods: {
        ...mapActions({
            setAlertStateAction: `${ALERT_MODULE_NAME}/setAlertStateAction`,
        }),
        async initData() {
            this.loading = true;
            await this.initCart();
            this.loading = false;
        },
        async initCart() {
            const result = await getCartItems(this.studio.id);
            if (!result.success) {
                return;
            }
            this.items = result.docs;
            this.setTotalPrice();
        },
        async updateCart(item, quantity) {
            const result = await updateCartItem(this.studio.id, item.productId, quantity);
            if (!result.success) {
                const payload = {
                    type: 'error',
                    message: `Failed to update cart - ${result.error}`
                }
                this.setAlertStateAction(payload);
            }
            this.initCart();
        },
        removeCartItem(item) {
            this.updateCart(item, 0);
        },
        setTotalPrice() {
            let total = 0;
            for (const order of this.items) {
                const { product, quantity } = order;
                const { price } = product;
                total += price * quantity;
            }
            this.total = toTwoDp(total);
        },
        onSuccess() {
            this.initCart();
            const payload = {
                type: 'success',
                message: 'Success! Check your email for your receipts'
            }
            this.setAlertStateAction(payload);
        },
    },
}
</script>
<style scoped>
.numeric-input-btn-style.vue-numeric-input .btn-increment {
  background: #eee1ff;
}
.numeric-input-btn-style.vue-numeric-input .btn-decrement {
  background: #eee1ff;
}

.checkoutRow {
    display: flex;
    flex-direction: row;
}
</style>

