<template>
  <v-card height="100%" class="d-flex flex-column">
    <v-card-text class="black--text">
      <v-row class="justify-space-between">
        <!-- Left Column of Package Item -->
        <v-col cols="7" sm="8">
          <!-- Package Title -->
          <v-row no-gutters>
            <p :style="packageItemNameStyle">
              {{ packageItem.name }}
            </p>
          </v-row>

          <p>
            {{ packageDescription }}
          </p>

          <p
            ref="expiry"
            class="text--secondary"
            v-if="!isBuyable && packageItem.hasStarted"
          >
            {{ expiresOn }}<br />
            {{ purchasedOn }}
          </p>
          <p
            ref="newPurchaseExpiry"
            class="text--secondary"
            v-if="!isBuyable && !packageItem.hasStarted"
          >
            {{ newPurchaseExpiry[0] }}
            <br />
            {{ newPurchaseExpiry[1] }}
          </p>

          <p
            class="primary--text mb-1 d-none d-sm-block text-truncate"
            v-if="isBuyable"
          >
            Eligible Classes
          </p>
          <p class="d-none d-sm-block classes-restrictions" v-if="isBuyable">
            {{ classesRestrictions }}
          </p>
        </v-col>

        <!-- Right Column of Package Item -->
        <v-col
          cols="5"
          sm="4"
          class="d-flex flex-column justify-space-between right-col"
        >
          <v-row class="justify-end">
            <v-col>
              <h3 class="text-center mt-3">
                {{ `${studio.displayCurrency}${packageItem.price}` }}
              </h3>
            </v-col>
          </v-row>
          <CheckoutWindow
            :packageItem="packageItem"
            buttonText="Buy Now"
            v-if="isBuyable"
            :isPurchasePackage="true"
          />
        </v-col>
      </v-row>
    </v-card-text>

    <v-spacer />

    <!-- View More Button -->
    <PackageInfo
      class="d-flex"
      block
      classProp="primary--text"
      buttonText="View More"
      :packageItem="packageItem"
      :isBuyable="isBuyable"
      @sharePackage="sharePackage"
    />
  </v-card>
</template>
<script>
import PackageInfo from "@/components/packages/PackageInfo";
import CheckoutWindow from "@/components/checkout/CheckoutWindow";
import { dateStringFromTimestamp } from "@/util/dateformat";
import { STUDIO_MODULE_NAME } from "@/store/moduleNames";
import { mapGetters } from "vuex";
import { WIDGET_ROUTE } from "@/constants";

export default {
  components: {
    PackageInfo,
    CheckoutWindow,
  },
  props: {
    packageItem: Object,
    isBuyable: Boolean,
  },
  computed: {
    ...mapGetters({
      studio: `${STUDIO_MODULE_NAME}/getStudio`,
    }),
    renewDurationType() {
      const packageItem = this.packageItem;
      const recurring = packageItem.renewDurationType;
      if (!recurring) {
        return "";
      }
      const renewalDurationTypeLen = recurring.length;
      const lastRenewalDurationLetter = recurring[renewalDurationTypeLen - 1];
      if (lastRenewalDurationLetter === "s") {
        return recurring.substring(0, renewalDurationTypeLen - 1);
      } else {
        return recurring;
      }
    },
    packageDescription() {
      const packageItem = this.packageItem;
      const recurring = packageItem.renewDuration;
      if (this.isBuyable) {
        const criteriaMessage =
          packageItem.expiryImmediateCriterion === "expiry-immediate"
            ? "purchase"
            : "first class";
        if (recurring) {
          const numCycles = packageItem.numCycles;
          const hasCycles = packageItem.numCycles !== undefined;
          const renewalDuration = `${packageItem.renewDuration} ${this.renewDurationType}`;
          const stringValue = hasCycles
            ? `Automatically renews every ${renewalDuration}(s) and expires after ${numCycles} ${this.renewDurationType}(s)`
            : `Automatically renews every ${renewalDuration}`;
          return `${packageItem.classNumbers} Classes - ${stringValue}`;
        } else {
          return `${packageItem.classNumbers} Classes - Valid for ${packageItem.duration} ${packageItem.durationType} from date of ${criteriaMessage}`;
        }
      }
      return `${packageItem.classesLeft} / ${packageItem.numberClasses} classes left`;
    },
    expiresOn() {
      const packageItem = this.packageItem;
      if (this.isBuyable) {
        return "";
      }
      return `Expires on ${dateStringFromTimestamp(packageItem.expiresOn)}`;
    },
    purchasedOn() {
      const packageItem = this.packageItem;
      if (this.isBuyable) {
        return "";
      }
      return `Purchased on ${dateStringFromTimestamp(
        packageItem.datePurchased
      )}`;
    },
    newPurchaseExpiry() {
      const packageItem = this.packageItem;

      if (this.isBuyable) {
        return "";
      }

      return [
        `Book first class by ${dateStringFromTimestamp(
          packageItem.expiresOn
        )}.`,
        `Package is valid ${packageItem.duration} ${packageItem.durationType} from date of first booking.`,
      ];
    },
    classesRestrictions() {
      const packageItem = this.packageItem;
      if (packageItem.hasClassRestriction) {
        return packageItem.classes.join(", ");
      }
      return "All Classes";
    },
    isWidget() {
      return this.$route.hash === WIDGET_ROUTE;
    },
    packageItemNameStyle() {
      let color = "#a259ff";
      let backgroundColor = "#a259ff2e";
      if (this.studio.primaryColor) {
        color = this.studio.primaryColor;
        backgroundColor = color + "2e";
      }
      return {
        padding: "8px 16px !important",
        marginBottom: 30,
        borderRadius: "4px",
        backgroundColor: backgroundColor,
        color: color,
        fontWeight: 500,
      };
    },
  },
  methods: {
    sharePackage() {
      this.$emit("sharePackage");
    },
  },
};
</script>
<style scoped>
.right-col {
  max-width: 160px !important;
}
</style>
