import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import { Ripple } from 'vuetify/lib/directives'

Vue.use(Vuetify, {
    directives: {
        Ripple
    }
});

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#8924ff',
                dilutedPrimary: '#8924ff2e',
                secondary: '#FFBD58',
                primaryMenu: '#FFFFFF', 
                background: "#F9F9F9",
                reviewGold: "#ff9429",
                reviewFour: "#a4d685",
                reviewThree: "#d9ca07",
                reviewTwo: "#fc8b56",
                reviewOne: "#cc3e31"
            },
        },
    }
});
