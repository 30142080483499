import { functions, httpsCallable } from "@/firebase";

export function sharePackages(
  packageId,
  numClassesShared,
  userSharedTo,
  userSharing
) {
  const sharePackage = httpsCallable(functions, "sharePackages");
  return sharePackage({
    packageId,
    numClassesShared,
    userSharedTo,
    userSharing,
  }).then((result) => result.data);
}
