<template>
  <v-container class="py-10 px-5">
    <PurchaseStatus />
    <BackButton />
    <ProgressLoader v-if="loading" />
    <div v-if="!loading && !classExists" class="class-error">
      <v-img
        :src="require('@/assets/search-error.gif')"
        alt="Class does not exist"
        max-width="400px"
        min-height="400px"
      />
      <div class="class-error-text">
        Class does not exist/has been deleted, please contact your studio.
      </div>
    </div>
    <v-col v-if="!loading && classExists">
      <v-row class="top-border">
        <ClassIntroduction :classObject="this.classItem" />
      </v-row>
      <v-row>
        <v-col cols="12" md="7" v-if="!hideSpots">
          <ChooseSpot
            :spots="classItem.spots"
            :height="classItem.height"
            :width="classItem.width"
            @input="updateSpotNumber"
            :key="chooseSpotKey"
          />
        </v-col>
        <v-col>
          <BookSpot
            :classObject="classItem"
            :isBookable="isBookable"
            :reason="reason"
            :normalMemberships="normalMemberships"
            :recurringMemberships="recurringMemberships"
            :myMemberships="myMemberships"
            @input="updateSelectedPackage"
            @click="submit"
            :key="bookSpotKey"
          />
          <div class="error-text">{{ validationError }}</div>
        </v-col>
      </v-row>
      <CheckoutWindow
        :hideSpots="hideSpots"
        ref="CheckoutWindow"
        :packageItem="selectedPackage ? selectedPackage : {}"
        :packageId="selectedPackage ? selectedPackage.id : ''"
        :classItem="classItem"
        :spotNumber="spotNumber"
        :isPurchasePackage="isPurchasePackage"
        :isBookClass="!isPurchasePackage"
        :hideButton="true"
        :key="checkoutWindowKey"
        @closeDialog="reload"
        @error="handleError"
      />
      <CheckoutWindow
        v-if="credits > 0"
        :hideButton="true"
        :hideSpots="hideSpots"
        ref="CheckoutWindowCredit"
        :key="checkoutCreditWindowKey"
        :packageItem="selectedPackage ? selectedPackage : {}"
        :packageId="selectedPackage ? selectedPackage.id : ''"
        :classItem="classItem"
        :spotNumber="spotNumber"
        :isPurchasePackage="isPurchasePackage"
        :isCreditBooking="true"
        @closeDialog="reload"
        @error="handleError"
      />
    </v-col>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import { AUTHENTICATION_MODULE_NAME } from "@/store/moduleNames";
import { STUDIO_MODULE_NAME } from "@/store/moduleNames";

import ClassIntroduction from "@/components/bookclass/ClassIntroduction.vue";
import BookSpot from "@/components/bookclass/BookSpot.vue";
import ChooseSpot from "@/components/bookclass/ChooseSpot.vue";
import ProgressLoader from "@/components/shared/ProgressLoader";
import CheckoutWindow from "@/components/checkout/CheckoutWindow.vue";
import PurchaseStatus from "@/components/stripe/PurchaseStatus";
import BackButton from "@/components/shared/BackButton.vue";

import { getClassByClassId } from "@/util/cloudFunctions/classesFunctions";
import {
  getNormalPackages,
  getRecurringPackages,
  getMyMemberships,
} from "@/util/cloudFunctions/packagesFunctions";
import { isPackageValidForClass } from "@/util/packages/checkPackagesValidity";

export default {
  components: {
    ClassIntroduction,
    BookSpot,
    ProgressLoader,
    ChooseSpot,
    CheckoutWindow,
    PurchaseStatus,
    BackButton,
  },
  data() {
    return {
      classItem: {},
      error: "",
      isBookable: false,
      reason: "",
      loading: true,
      spotNumber: null,
      selectedPackage: null,
      isPurchasePackage: false,
      validationError: "",
      chooseSpotKey: false,
      bookSpotKey: false,
      checkoutWindowKey: false,
      normalMemberships: [],
      checkoutCreditWindowKey: false,
      recurringMemberships: [],
      myMemberships: [],
      classExists: false,
    };
  },
  watch: {
    $route: {
      handler(to, from) {
        if (to !== from) {
          this.setRequiredDataWithLoading();
        }
      },
      immediate: true,
    },
    isUserAuth: {
      handler() {
        this.setRequiredData();
      },
    },
  },
  computed: {
    hideSpots() {
      return this.classItem.hideSpots === true;
    },
    ...mapGetters({
      user: `${AUTHENTICATION_MODULE_NAME}/getUser`,
      isUserAuth: `${AUTHENTICATION_MODULE_NAME}/isUserAuth`,
      studio: `${STUDIO_MODULE_NAME}/getStudio`,
      credits: `${AUTHENTICATION_MODULE_NAME}/getCredits`,
    }),
  },
  methods: {
    async setRequiredDataWithLoading() {
      this.loading = true;
      await this.setRequiredData();
      this.loading = false;
    },
    async setRequiredData() {
      await this.setClasses(); // this function needs to run before the other functions, so that
      const promises = [
        this.setNormalPackages(),
        this.setRecurringPackages(),
        this.setUserPackages(),
      ];
      await Promise.all(promises);
    },
    async setClasses() {
      const classId = this.$route.params.classId;
      const result = await getClassByClassId({ classId });
      if (result.success) {
        this.classItem = result.doc;

        // Create an array of object here to make it scalable in the future
        // and to avoid any side effects.
        this.classItem.urls = this.classItem.images.map((image) => {
          return { type: "image", url: image };
        });

        if (this.classItem.videoUrl) {
          this.classItem.urls.unshift({
            type: "video",
            url: this.classItem.videoUrl,
          });
        }

        this.isBookable = result.isBookable;
        this.reason = result.reason;
        this.classExists = true;
      } else {
        console.log(result, classId);
        this.error = result.error;
        this.classExists = false;
      }
    },
    filterUsablePackages(packages) {
      // This function helps to filter out packages which cannot be used to book this class
      return packages.filter((pkg) =>
        isPackageValidForClass(pkg, this.classItem, this.studio.timezone)
      );
    },
    async setNormalPackages() {
      const result = await getNormalPackages(this.studio.id);
      if (result.success) {
        this.normalMemberships = this.filterUsablePackages(result.memberships);
      }

      if (result.error) {
        this.error = result.error;
      }
    },
    async setRecurringPackages() {
      const result = await getRecurringPackages(this.studio.id);
      if (result.success) {
        this.recurringMemberships = this.filterUsablePackages(
          result.memberships
        );
      }

      if (result.error) {
        this.error = result.error;
      }
    },
    async setUserPackages() {
      const result = await getMyMemberships(this.studio.id);
      if (result.success) {
        this.myMemberships = this.filterUsablePackages(result.docs).filter(
          (doc) => doc.classesLeft > 0
        );
      }

      if (result.error) {
        this.error = result.error;
      }
    },
    updateSpotNumber(spotNumber) {
      this.spotNumber = spotNumber;
    },
    updateSelectedPackage(selectedPackage, isMyPackage) {
      this.selectedPackage = selectedPackage;
      this.isPurchasePackage = !isMyPackage;
    },
    validate(isCreditBooking) {
      if (this.spotNumber && isCreditBooking) {
        this.validationError = "";
        return true;
      }
      if (!this.hideSpots) {
        this.validationError = "Please choose a spot";
        return false;
      }
      return true;
    },
    submit(isCreditBooking) {
      const validation = this.validate(isCreditBooking);
      if (validation && !isCreditBooking) {
        this.$refs.CheckoutWindow.openDialog();
      } else if (validation && isCreditBooking) {
        this.$refs.CheckoutWindowCredit.openDialog();
      }
    },
    async reload() {
      this.toggleKeys();
      await this.setClasses();
    },
    toggleKeys() {
      this.bookSpotKey = !this.bookSpotKey;
      this.checkoutWindowKey = !this.checkoutWindowKey;
      this.checkoutCreditWindowKey = !this.checkoutCreditWindowKey;
    },
    handleError(error) {
      this.validationError = error;
    },
  },
};
</script>

<style scoped>
.top-border {
  border-bottom: 0.2px solid #000;
}

.book-spot-header {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  /* font-size: 45px; */
  line-height: 32px;
  /* identical to box height, or 71% */

  display: flex;
  align-items: center;
}

.error-text {
  display: flex;
  justify-content: center;
  align-content: flex-start;
  align-items: flex-start;
  color: red;
}

.class-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.class-error-text {
  font-family: Roboto;
  font-size: 24px;
  text-align: center;
}
</style>
