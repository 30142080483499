import { functions, httpsCallable } from '@/firebase';

function applyCorporateMembership(name, companyName, email, corporateEmail) {
    if (!name || !companyName || !email) {
        return {success: false, error: 'Missing information'};
    }
    const applyCorporateMembership = httpsCallable(functions, 'applyCorporateMembership');
    return applyCorporateMembership({name, companyName, email, corporateEmail}).then((res) => res.data);
}

export default applyCorporateMembership;