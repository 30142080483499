<template>
  <div>
    <v-row no-gutters class="pa-3">
      <div class="studioImage">
        <v-img
          :src="studio.location.images[0]"
          :aspect-ratio="3 / 2"
          @click="navigateToStudioPage(studio)"
          style="border-radius: 5px"
        ></v-img>
      </div>
      <div class="studioMiddleDescription">
        <div class="studioCategory text-truncate">
          {{ studioCategory }}
        </div>
        <div
          class="studioName text-truncate"
          @click="navigateToStudioPage(studio)"
        >
          {{ studio.studioName }}
        </div>
        <div class="studioAddress text-truncate">
          {{ studio.location.address }}
        </div>
        <div v-if="studio.averageRating">
          <span class="studioAverageRating">{{ studio.averageRating }}</span>
          <StarSVG :fill="$vuetify.theme.themes.light.reviewGold" />
          <span class="studioNumberReviews">({{ studio.numberReviews }})</span>
        </div>
        <div
          v-if="showMoreCaret"
          class="moreInfo primary--text"
          @click="toggleShowDescription(studio)"
        >
          {{ studio.showDescription ? "less" : "more" }} info
          <DownwardCaretSVG />
        </div>
      </div>
      <div class="d-none d-md-flex studioDescriptionInRow" v-if="!isPopUpMode">
        <v-divider vertical class="ml-12 mr-5"></v-divider>
        <div>
          {{ truncatedStudioDescription }}
        </div>
      </div>
    </v-row>
    <v-slide-y-transition>
      <div class="mt-3 px-3 studioDescription" v-if="studio.showDescription">
        {{ studio.description }}
      </div>
    </v-slide-y-transition>
  </div>
</template>
<script>
import StarSVG from "@/components/shared/StarSVG";
import DownwardCaretSVG from "@/components/shared/DownwardCaretSVG";

export default {
  components: {
    StarSVG,
    DownwardCaretSVG,
  },
  props: {
    studio: Object,
    isPopUpMode: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    studioCategory() {
      if (!this.studio.category) {
        return "";
      }
      const categories = this.studio.category.map((category) => {
        if (category === "martialArts") {
          return "Martial Arts";
        }
        return category;
      });
      return categories.join(", ");
    },
    truncatedStudioDescription() {
      const LENGTH = 230;
      if (this.studio.description.length > LENGTH) {
        return this.studio.description.substring(0, LENGTH) + "...";
      }
      return this.studio.description;
    },
    showMoreCaret() {
      if (this.isPopUpMode) {
        return true;
      }
      return this.$vuetify.breakpoint.width < 960;
    },
  },
  methods: {
    toggleShowDescription(studio) {
      studio.showDescription = !studio.showDescription;
    },
    navigateToStudioPage(studio) {
      const studioRoute = studio.webAppRoute;
      this.$router.push(`/${studioRoute}/studio`);
    },
  },
};
</script>
<style scoped>
.studioCategory {
  font-size: 0.75rem;
  line-height: 1.33;
  font-weight: 700;
  text-transform: uppercase;
  color: #999;
}

.studioName {
  padding-top: 4px;
  color: #000;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
  cursor: pointer;
}

.studioAddress {
  line-height: 1;
  padding-top: 0;
  font-size: 0.875rem;
}

.studioAverageRating {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  font-weight: 500;
  color: #000;
  margin-right: 4px;
}

.studioNumberReviews {
  color: #555;
  font-size: 0.875rem;
  line-height: 1.4285714286;
  letter-spacing: 0;
  margin-left: 4px;
}

.moreInfo {
  font-size: 0.875rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
}

.studioDescription {
  font-size: 0.875rem;
  line-height: 1.4285714286;
}

.studioImage {
  cursor: pointer;
  margin-right: 16px;
  width: 160px;

  @media screen and (max-width: 599px) {
    width: 100px;
  }
}

.studioMiddleDescription {
  width: 200px;
}

.studioDescriptionInRow {
  font-size: 0.875rem;
  line-height: 1.4285714286;
  width: 400px;
  height: 100px;
  overflow: hidden;
}
</style>
