var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer"},[(_vm.noEmailCase)?_c('div',[_c('v-alert',{attrs:{"type":"error"}},[_vm._v(" Your Facebook account does not contain an email. Please add your email to your Facebook account and reload this page to continue with account creation."),_c('br')]),_c('v-col',{attrs:{"align":"center"}},[_c('a',{attrs:{"href":"https://www.facebook.com/settings?tab=account&section=email&view","target":"_blank"}},[_vm._v("Update your Facebook Account Email")])])],1):_c('v-form',{ref:"form"},[(!_vm.collectUserDetails)?_c('ValidationProvider',{attrs:{"name":"Email","rules":"required|max:64|email","disabled":_vm.collectUserDetails},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-trimmer',{attrs:{"aria-readonly":"true","label":"Email","prepend-icon":"mdi-email","error-messages":errors},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})]}}],null,false,1993188965)}):_vm._e(),(!_vm.collectUserDetails)?_c('ValidationProvider',{attrs:{"name":"Password","rules":"required|max:64"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Password","prepend-icon":"mdi-lock","error-messages":errors,"append-icon":_vm.passwordToggle ? 'mdi-eye-off' : 'mdi-eye',"type":_vm.passwordToggle ? 'password' : 'text'},on:{"click:append":function () { return (_vm.passwordToggle = !_vm.passwordToggle); }},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})]}}],null,false,133232109)}):_vm._e(),_c('ValidationProvider',{attrs:{"name":"Full Name","rules":{required:true, fullNameRegex:_vm.fullNameRegex, min:4}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-trimmer',{attrs:{"label":"Full Name","prepend-icon":"mdi-text","error-messages":errors},model:{value:(_vm.fullName),callback:function ($$v) {_vm.fullName=$$v},expression:"fullName"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Username","rules":{required:true, usernameRegex:_vm.usernameRegex, min:4, max:17}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('text-trimmer',{attrs:{"label":"Username","hint":"Username should be unique and can only contain alphanumeric characters, period(.) and underscore(_)","persistent-hint":"","error-messages":errors,"prepend-icon":"mdi-account"},model:{value:(_vm.username),callback:function ($$v) {_vm.username=$$v},expression:"username"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Date of Birth","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{staticClass:"pt-3",attrs:{"label":"Date of Birth","errors":errors,"max":new Date().toISOString().substr(0, 10),"outlined":false,"icon":"mdi-calendar"},model:{value:(_vm.dob),callback:function ($$v) {_vm.dob=$$v},expression:"dob"}})]}}])}),_c('ValidationProvider',{attrs:{"name":"Country","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Country","error-messages":errors,"prepend-icon":"mdi-earth","items":_vm.supportedCountries,"item-text":"name","item-value":"code"},model:{value:(_vm.country),callback:function ($$v) {_vm.country=$$v},expression:"country"}})]}}])}),(_vm.error !== '')?_c('p',{staticClass:"error--text text-center py-3"},[_vm._v(_vm._s(_vm.error))]):_vm._e(),_c('v-row',{staticClass:"my-3",attrs:{"justify":"center"}},[_c('v-btn',{staticClass:"primary auth-btn",attrs:{"loading":_vm.loading,"depressed":""},on:{"click":_vm.submit}},[_vm._v(" Sign Up ")])],1),(!_vm.collectUserDetails)?_c('v-divider',{staticClass:"mt-10 mb-7"}):_vm._e(),(!_vm.collectUserDetails)?_c('FacebookLogin'):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }