<template>
  <v-col>
    <p>Packages</p>
    <v-row>
      <v-col class="text--secondary" v-if="packages.length === 0">
        No packages available.
      </v-col>
      <v-col
        cols="12"
        md="6"
        v-for="(packageItem, index) in packages"
        :key="index"
      >
        <PackageCard
          :packageItem="packageItem"
          :isBuyable="isBuyable"
          @sharePackage="sharePackage"
        />
      </v-col>
    </v-row>

    <p class="mt-5">
      Recurring Packages
      <v-tooltip v-model="tooltip" top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" @click="tooltip = !tooltip"
            >mdi-help-circle-outline</v-icon
          >
        </template>
        <span
          >Recurring packages are packages that will automatically charge at the
          end of the expiry date and provide you with a new package!</span
        >
      </v-tooltip>
    </p>

    <v-row class="mt-3">
      <v-col class="text--secondary" v-if="recurringPackages.length === 0">
        No recurring packages available.
      </v-col>
      <v-col
        v-else
        cols="12"
        md="6"
        v-for="(packageItem, index) in recurringPackages"
        :key="index"
      >
        <PackageCard :packageItem="packageItem" :isBuyable="isBuyable" />
      </v-col>
    </v-row>
  </v-col>
</template>
<script>
import PackageCard from "@/components/packages/PackageCard";
export default {
  components: {
    PackageCard,
  },
  props: {
    packages: Array,
    recurringPackages: Array,
    isBuyable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      tooltip: false,
    };
  },
  methods: {
    sharePackage() {
      this.$emit("sharePackage");
    },
  },
};
</script>
